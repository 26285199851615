import { Grid, ListItem, ListItemText, Typography } from '@mui/material';

export const ContactoField = ({ label, data, ...props }) => {
  return (
    <ListItem {...props}>
      <Grid container alignItems={'baseline'}>
        {label && (
          <Grid item xs={12} sm={4} md={3}>
            <ListItemText
              secondary={
                <Typography gutterBottom variant='body2' display='block' sx={{ fontWeight: '600' }}>
                  {label}
                </Typography>
              }
            />
          </Grid>
        )}
        {(props.children || data) && (
          <Grid item xs={12} sm={8} md={9}>
            {props.children && <>{props.children}</>}
            {!props.children && data && (
              <ListItemText primary={data} primaryTypographyProps={{ fontWeight: 'bold' }} />
            )}
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};
