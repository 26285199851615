import { useState, useEffect } from 'react';
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { FormatListNumbered } from '@mui/icons-material';
import { getUltimosPrestadoresOrden } from '../../services/Busquedas/Prestadores';

export const UltimosPrestadoresOrden = ({
  idAfiliado = null,
  limit = 10,
  onSelectEvent = null,
}) => {
  const [latestOption, setLatestOption] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    getLatestOptions();
  }, [idAfiliado]);

  const getLatestOptions = async () => {
    let query = `?limit=${limit}`;
    if (idAfiliado) {
      query = query + `&idAfiliado=${idAfiliado}`;
    }
    const result = await getUltimosPrestadoresOrden(query);
    if (result.length > 0) {
      setLatestOption(result);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHandleSelect = (prestador) => {
    setAnchorEl(null);
    if (onSelectEvent) {
      onSelectEvent(prestador);
    }
  };

  return (
    <>
      <Box
        sx={{
          height: { sm: '100%' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Tooltip title='Últimos prestadores utilizados'>
          <IconButton
            onClick={handleClick}
            size='small'
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              <FormatListNumbered />
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 15,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {latestOption.map((prestador) => (
          <MenuItem key={prestador.nombre} onClick={(e) => onHandleSelect(prestador)}>
            {prestador.nombre} ({prestador.matricula})
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
