import { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccordionCommon = ({ data }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {data.map((accordion) => {
        const { id, heading, secondaryHeading, content } = accordion;
        return (
          <Accordion expanded={expanded === id} key={id} onChange={handleChange(id)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
              sx={{ borderColor: '#005A55', borderRadius: 1 }}
            >
              <Typography sx={{ color: '#005A55', fontWeight: 'medium' }}>{heading}</Typography>
              <Typography>{secondaryHeading}</Typography>
            </AccordionSummary>
            <AccordionDetails>{content}</AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default AccordionCommon;
