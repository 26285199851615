import { useEffect } from 'react';
import { Typography } from '@mui/material';

const Title = ({ autoFocus = true, ...props }) => {
  useEffect(() => {
    if (autoFocus) {
      let ele = document.querySelector('#main h1');
      ele?.focus({ focusVisible: true });
    }
  }, []);

  return (
    <Typography
      tabIndex={0}
      variant='h3'
      component='h1'
      mb={{ xs: 2, sm: 3 }}
      fontSize={{ xs: '1.7rem', sm: '3rem' }}
      textAlign={{ xs: 'center', sm: 'left' }}
    >
      {props.text}
      {props.children}
    </Typography>
  );
};

export default Title;
