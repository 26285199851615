import ConsumoPrestacionalTable from './ConsumoPrestacionalTable';
import DerivacionesTable from './DerivacionesTable';
import TramitesSatTable from './TramitesSatTable';
import AccordionCommon from '../Common/AccordionCommon';
import MedicamentosTable from './MedicamentosTable';

const ConsumoPrestacionalResult = ({ consumoPrestacional }) => {
  const data = [
    {
      id: 'panel1',
      heading: ' Ordenes Ambulatorias',
      secondaryHeading: '',
      content: (
        <ConsumoPrestacionalTable
          consumos={consumoPrestacional.ordenesAmbulatorias}
          columnasExcluidas={['importe']}
        />
      ),
    },
    {
      id: 'panel2',
      heading: 'Ordenes Odontológicas',
      secondaryHeading: '',
      content: (
        <ConsumoPrestacionalTable
          consumos={consumoPrestacional.ordenesOdontologicas}
          columnasExcluidas={['importe']}
        />
      ),
    },
    {
      id: 'panel3',
      heading: 'Internaciones',
      secondaryHeading: '',
      content: (
        <ConsumoPrestacionalTable
          consumos={consumoPrestacional.internaciones}
          columnasExcluidas={['importe']}
        />
      ),
    },
    {
      id: 'panel4',
      heading: 'Medicamentos Autorizados',
      secondaryHeading: '',
      content: <MedicamentosTable medicamentos={consumoPrestacional.medicamentosAutorizados} />,
    },
    {
      id: 'panel5',
      heading: 'Medicamentos Ambulatorios',
      secondaryHeading: '',
      content: (
        <MedicamentosTable
          medicamentos={consumoPrestacional.medicamentosAmbulatorios}
          columnasExcluidas={['periodo']}
        />
      ),
    },
    {
      id: 'panel6',
      heading: 'Traslados',
      secondaryHeading: '',
      content: (
        <ConsumoPrestacionalTable
          consumos={consumoPrestacional.traslados}
          columnasExcluidas={['numero', 'importe', 'prestador']}
        />
      ),
    },
    {
      id: 'panel7',
      heading: 'Hotelería',
      secondaryHeading: '',
      content: (
        <ConsumoPrestacionalTable
          consumos={consumoPrestacional.hoteleria}
          columnasExcluidas={['importe', 'prestador']}
        />
      ),
    },
    {
      id: 'panel8',
      heading: 'Créditos',
      secondaryHeading: '',
      content: (
        <ConsumoPrestacionalTable
          consumos={consumoPrestacional.creditos}
          columnasExcluidas={['importe', 'prestador']}
        />
      ),
    },
    {
      id: 'panel10',
      heading: 'Expedientes',
      secondaryHeading: '',
      content: (
        <ConsumoPrestacionalTable
          consumos={consumoPrestacional.expedientes}
          columnasExcluidas={['importe', 'prestador']}
        />
      ),
    },
    {
      id: 'panel11',
      heading: 'Derivaciones',
      secondaryHeading: '',
      content: <DerivacionesTable derivaciones={consumoPrestacional.derivaciones} />,
    },
    {
      id: 'panel12',
      heading: 'Reintegros',
      secondaryHeading: '',
      content: (
        <ConsumoPrestacionalTable
          consumos={consumoPrestacional.reintegros}
          columnasExcluidas={['prestador']}
        />
      ),
    },
    {
      id: 'panel13',
      heading: 'Trámites SAT',
      secondaryHeading: '',
      content: <TramitesSatTable tramites={consumoPrestacional.tramitesSAT} />,
    },
  ];

  return <AccordionCommon data={data} />;
};

export default ConsumoPrestacionalResult;
