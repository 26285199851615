import {
  Box,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useCuestionario } from './hooks/useCuestionario';

const CuestionarioValidacionIdentidad = React.forwardRef((props, ref) => {
  const {
    cuestionarioData,
    onChangeOptions,
    onBuscarCuestionario,
    onSendMessageEvent,
    onCuestionarioCompleto,
    onResetCuestionarioData,
  } = useCuestionario(props.respuestas);

  useEffect(() => {
    try {
      if (props.respuestas == null || props.respuestas.length == 0) {
        onBuscarCuestionario(props.cuil);
      }
    } catch (err) {
      console.error(err);
    }
  }, [props.respuestas]);

  useEffect(() => {
    try {
      if (props.onResetCuestionario) {
        onResetCuestionarioData(props.cuil);
        props.onEnableSiguiente(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [props.onResetCuestionario]);

  useEffect(() => {
    // TODO ver si en este if validamos que haya seleccionado al menos una respuesta
    if (props.onSiguiente) {
      props.onResultRespuesta(cuestionarioData);
    }
  }, [props.onSiguiente]);

  const handleChangeSelectedOption = (codigoOpcion, idPregunta, checked) => {
    onChangeOptions(codigoOpcion, idPregunta, checked);
    props.onEnableSiguiente(onCuestionarioCompleto());
  };

  return (
    <div>
      <Typography align='center' variant='body2' color='textSecondary'>
        Para poder continuar deberá responder las siguientes preguntas
      </Typography>
      <Box sx={{ margin: 1 }}>
        {cuestionarioData?.map((i, k) => (
          <List>
            <ListItem key={`pregunta-${i.preguntaVO.idPregunta}`} sx={{ py: 0 }}>
              <ListItemText primary={i.preguntaVO.descripcion} />
            </ListItem>
            {i.opciones?.map((y, j) => (
              <ListItem key={`opcion-${i.preguntaVO.idPregunta + y.codigo}`} sx={{ py: 0 }}>
                {i.preguntaVO.codigoTipoPregunta === 'MULTIPLE' ? (
                  <FormControlLabel
                    label={y.descripcion}
                    checked={y.selected}
                    control={
                      <Checkbox
                        onChange={(e) => {
                          handleChangeSelectedOption(
                            y.codigo,
                            i.preguntaVO.idPregunta,
                            e.target.checked,
                          );
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                  />
                ) : (
                  <FormControlLabel
                    label={y.descripcion}
                    checked={y.selected}
                    control={
                      <Radio
                        onChange={(e) => {
                          handleChangeSelectedOption(
                            y.codigo,
                            i.preguntaVO.idPregunta,
                            e.target.checked,
                          );
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                  />
                )}
              </ListItem>
            ))}
          </List>
        ))}
      </Box>
    </div>
  );
});

export default CuestionarioValidacionIdentidad;
