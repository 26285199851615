import BuscarAportes from '../Aportes/BuscarAportes';

const Aportes = () => {
  return (
    <>
      <BuscarAportes />
    </>
  );
};

export default Aportes;
