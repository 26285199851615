import { axiosPost } from '../ServiceBase.js';

export const generarCuestionario = async (data) => {
  try {
    const cuestionario = await axiosPost('/v1/cuenta/generar-cuestionario', data);
    return cuestionario;
  } catch (err) {
    return Promise.reject(err);
  }
};
