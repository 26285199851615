import { useEffect, useState } from 'react';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import {
  Alert,
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  ToggleButton,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useWidth } from '../../@iosper/utils/UseWidth';

const DataCollapseDrawer = (props) => {
  const { consumo, columnNumeroVisible, columnImporteVisible, columnPrestadorVisible } = props;
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <Typography
            variant='body2'
            align='left'
            gutterBottom={false}
            sx={{ color: '#000', textTransform: 'none' }}
          >
            {columnNumeroVisible ? consumo.numero + ' - ' + consumo.fecha : consumo.fecha}
          </Typography>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <List>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Descripción' secondary={consumo.descripcionPrestacion} />
            </ListItem>
            {columnPrestadorVisible && (
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary='Prestador' secondary={consumo.descripcionPrestador} />
              </ListItem>
            )}
            {columnImporteVisible && (
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary='Importe' secondary={consumo.importe} />
              </ListItem>
            )}
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const ConsumoPrestacionalTable = ({ consumos, columnasExcluidas }) => {
  const screenWidth = useWidth();
  const [columnNumeroVisible, setColumnNumeroVisible] = useState(true);
  const [columnImporteVisible, setColumnImporteVisible] = useState(true);
  const [columnPrestadorVisible, setColumnPrestadorVisible] = useState(true);

  useEffect(() => {
    setColumnNumeroVisible(
      columnasExcluidas?.find((element) => element === 'numero') !== undefined ? false : true,
    );
    setColumnPrestadorVisible(
      columnasExcluidas?.find((element) => element === 'prestador') !== undefined ? false : true,
    );
    setColumnImporteVisible(
      columnasExcluidas?.find((element) => element === 'importe') !== undefined ? false : true,
    );
  }, [columnasExcluidas]);

  /// -------------------- DATA GRID CONSUMOS --------------------------
  const mapConsumoToRow = (consumo, index) => ({
    id: index,
    numero: consumo.numeroSolicitud,
    fecha: consumo.fechaSolicitud,
    descripcionPrestacion:
      consumo.cantidad && consumo.codigoPrestacion
        ? consumo.cantidad + ' x ' + consumo.codigoPrestacion + '- ' + consumo.nombrePrestacion
        : consumo.nombrePrestacion,
    descripcionPrestador: '(' + consumo.codigoPrestador + ') ' + consumo.nombrePrestador,
    importe: '$ ' + consumo.importe,
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: columnNumeroVisible ? 'Número - Fecha' : 'Fecha',
      flex: 1,
      renderCell: (a) => (
        <DataCollapseDrawer
          consumo={a.row}
          columnNumeroVisible={columnNumeroVisible}
          columnImporteVisible={columnImporteVisible}
          columnPrestadorVisible={columnPrestadorVisible}
        />
      ),
    },
    { field: 'numero', headerName: 'Número', flex: 1, width: 120 },
    { field: 'fecha', headerName: 'Fecha', flex: 1 },
    { field: 'descripcionPrestacion', headerName: 'Descripción', flex: 1 },
    { field: 'descripcionPrestador', headerName: 'Prestador', flex: 1, hideable: true },
    { field: 'importe', headerName: 'Importe', flex: 1, hideable: true },
  ];

  return consumos?.length ? (
    <div style={{ width: '100%' }}>
      <DataGrid
        sx={{
          border: 0,
          '& .MuiDataGrid-cell': {
            py: '8px',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#F2F5F5',
          },
        }}
        autoHeight={true}
        loading={!consumos.length}
        rows={consumos.map((a, index) => mapConsumoToRow(a, index))}
        columns={columns}
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 200}
        columnVisibilityModel={{
          desplegable: screenWidth === 'xs',
          numero: screenWidth !== 'xs' && columnNumeroVisible,
          fecha: screenWidth !== 'xs',
          descripcionPrestacion: screenWidth !== 'xs',
          descripcionPrestador: screenWidth !== 'xs' && columnPrestadorVisible,
          importe: screenWidth !== 'xs' && columnImporteVisible,
        }}
        pagination={screenWidth === 'xs' ? null : true}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        disableColumnMenu
      />
    </div>
  ) : (
    <Alert severity='info'>No se han encontrado resultados</Alert>
  );
};

export default ConsumoPrestacionalTable;
