import { axiosGet } from '../ServiceBase.js';

export const getProvincias = async () => {
  try {
    const provincias = JSON.parse(sessionStorage.getItem('provincias'));
    if (provincias == null) {
      const provincias = await axiosGet('/v1/busquedas/provincias');
      sessionStorage.setItem('provincias', JSON.stringify(provincias));
      return provincias;
    } else {
      return provincias;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
