import React, { useEffect, useState } from 'react';
import {
  TextField,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { HighlightOff, LockReset } from '@mui/icons-material';
import { agregarCorreoRecuperarPin } from '../../../services/Afiliado/Afiliado';
import { LoadingButton } from '@mui/lab';
import CountDown from '../../Common/CountDown';

const ConfirmarContactoRecuperarPinView = React.forwardRef((props, ref) => {
  const [contentView, setContent] = useState(false);
  const [caducado, setCaducado] = useState(false);
  const [codigoIngresado, setCodigoIngresado] = useState(false);
  const [datosCuenta, setDatosCuenta] = useState({});
  const [loadingRecuperarPin, setLoadingRecuperarPin] = useState(false);

  useEffect(() => {
    const generarCode = async () => {
      try {
        setContent(true);
        setDatosCuenta(props?.datosCuenta);
      } catch (e) {
        console.error(e);
      }
    };
    generarCode();
    return function cleanup() {
      setDatosCuenta({});
    };
  }, [props?.datosCuenta]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const onCaducado = () => {
    setCaducado(true);
  };

  const handleClose = () => {
    //setLoadingGenerarOrden(false);
    //setOrdenGenerada(false);
    setCaducado(false);
    reset();
    props.setOpen(false);
  };

  const handleGenerate = async (event) => {
    //event.preventDefault();
    setLoadingRecuperarPin(true);
    try {
      const res = await agregarCorreoRecuperarPin({
        cuil: datosCuenta.cuil,
        code: codigoIngresado,
      });
      setCodigoIngresado(res);
      setLoadingRecuperarPin(false);
      // setContactoConfirmado(true)
      handleClose();
      props.setRedirectSignin(true);
    } catch (e) {
      setLoadingRecuperarPin(false);
    }
  };

  return contentView ? (
    <div>
      <Dialog
        maxWidth='lg'
        open={props.open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center' }} id='alert-dialog-title'>
          Recuperar Contraseña
        </DialogTitle>
        <DialogContent sx={{ pr: 2, pl: 3, pb: 0, pt: 0 }}>
          <Grid container spacing={3} direction='column' justifyContent='left' alignItems='left'>
            <Grid item xs={12} zeroMinWidth>
              <Typography align='left' variant='h6'>
                Ingresá el código de confirmación que enviamos a:
              </Typography>
              {datosCuenta.correo && (
                <List>
                  <ListItem key={`email-account-${datosCuenta.correo}`}>
                    <ListItemIcon>&bull;</ListItemIcon>
                    <ListItemText primary={datosCuenta.correo} />
                  </ListItem>
                </List>
              )}
            </Grid>
            <Grid item xs={12} zeroMinWidth>
              <Typography align='left' variant='body2'>
                ¿No recibiste ningún correo?
                <br />
                Acordate de revisar en el correo no deseado.
              </Typography>
            </Grid>
            <Grid item xs={12} zeroMinWidth>
              <TextField
                label='¿Cúal es el código?'
                variant='standard'
                fullWidth
                name='codigo'
                focused
                error={caducado}
                helperText={caducado && 'El código ha caducado'}
                {...register('codigo', {
                  required: {
                    value: true,
                    message: 'Debe ingresar un código',
                  },
                })}
                onChange={(e) => setCodigoIngresado(e.target.value)}
              />
              {errors.codigo && <p style={{ color: 'red' }}>{errors.codigo.message}</p>}
              <Grid item xs={12}>
                <Stack direction='row' spacing={1} justifyContent='left' alignItems='center'>
                  {!caducado && <Typography variant='caption'> El código caducará en</Typography>}
                  {!caducado && <CountDown id='count' minutes={5} onCaducado={onCaducado} />}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            justifyContent={{ xs: 'center', sm: 'flex-end' }}
            alignItems={{ xs: 'strech' }}
          >
            <Button
              variant='contained'
              color='neutral'
              startIcon={<HighlightOff />}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <LoadingButton
              variant='contained'
              color='primary'
              type='submit'
              startIcon={<LockReset />}
              autoFocus={true}
              loading={loadingRecuperarPin}
              onClick={handleSubmit(handleGenerate)}
            >
              Confirmar
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <Box></Box>
  );
});

export default ConfirmarContactoRecuperarPinView;
