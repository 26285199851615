import { axiosPost, axiosGet } from '../ServiceBase.js';

export const getGrupoFamiliar = async () => {
  return axiosGet('/v1/afiliados/grupo-familiar');
};

export const seguirAfiliadoLogin = async () => {
  return axiosGet('/v1/afiliados/seguir-afiliado');
};

export const getAfiliado = async () => {
  return axiosGet('/v1/afiliados/afiliado');
};

export const findAfiliado = async (idAfiliado) => {
  const afiliado = await axiosPost('/v1/afiliados/find-afiliado', { idAfiliado: idAfiliado });
  return afiliado;
};

export const findTitularGrupo = async () => {
  const afiliado = await axiosGet('/v1/afiliados/find-titular-grupo');
  return afiliado;
};

export const getNotificaciones = async (idAfiliado) => {
  const afiliado = await axiosGet('/v1/usuarios/afiliados/notificaciones-pendientes', {
    idAfiliado: idAfiliado,
  });
  return afiliado;
};
