import { axiosGet } from '../ServiceBase.js';

export const getTiposEstablecimiento = async () => {
  try {
    const tiposEstablecimiento = JSON.parse(sessionStorage.getItem('tiposEstablecimiento'));
    if (tiposEstablecimiento == null) {
      const tiposEstablecimiento = await axiosGet('/v1/busquedas/tipos-establecimiento');
      sessionStorage.setItem('tiposEstablecimiento', JSON.stringify(tiposEstablecimiento));
      return tiposEstablecimiento;
    } else {
      return tiposEstablecimiento;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
