import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Box } from '@mui/material';
import { useUser } from '../components/Auth/User';

/* Screens */
import Home from '../components/Screens/Home';
import Orden from '../components/Screens/Orden';
import Receta from '../components/Screens/Receta';

//import Prestador from '../components/Screens/Prestador';
import GrupoFamiliar from '../components/Screens/GrupoFamiliar';

/* Components */
import SignIn from '../components/Auth/SignIn';
import SignOut from '../components/Auth/SignOut';
import ValidarContacto from '../components/Contacto/ValidarContacto';
import EmitirOrden from '../components/Orden/EmitirOrden';
import ReimprimirOrden from '../components/Orden/ReimprimirOrden';
import BuscarPrestador from '../components/Prestador/BuscarPrestador';
import BuscarEstablecimiento from '../components/Prestador/BuscarEstablecimiento';
import BuscarFarmacias from '../components/Screens/Farmacias';
import ImprimirCuponCapita from '../components/Capitas/ImprimirCuponCapita';
import RecuperarPin from '../components/Contacto/RecuperarPin/RecuperarPin';
import OlvidePin from '../components/Contacto/RecuperarPin/OlvidePin';
import CambiarPassword from '../components/Auth/CambiarPassword';
import Perfil from '../components/Screens/Perfil';
import TerminosCondiciones from '../components/Screens/TerminosCondiciones';
import VerAfiliado from '../components/Afiliados/VerAfiliado';
import CrearCuenta from '../components/Afiliados/Cuenta/CrearCuenta';
import ConfirmarContactoView from '../components/Contacto/ConfirmarContactoView';
import ReadQr from '../components/Orden/GenerarOrdenDigital';
import Mantenimiento from '../layouts/Messages/Page/Mantenimiento';
import ConfirmarContactoCrearCuentaView from '../components/Afiliados/Cuenta/ConfirmarContactoCrearCuenta';
import DocumentoPreview from '../components/Screens/DocumentoPreview';
import Prestadores from '../components/Screens/Prestadores';
import Ayuda from '../components/Screens/Ayuda';
import AddTransitoria from '../components/Derivaciones/AddTransitoria';
import Reintegros from '../components/Screens/Reintegros';
import Aportes from '../components/Screens/Aportes';
import Delegaciones from '../components/Screens/Delegaciones';
import Derivaciones from '../components/Screens/Derivaciones';
import ConsumoPrestacional from '../components/Screens/ConsumoPrestacional';
import VerAutorizaciones from '../components/Autorizaciones/VerAutorizaciones';
import BuscarRecetas from '../components/Receta/BuscarRecetas';
import BuscarRecetasCronicas from '../components/Receta/BuscarRecetasCronicas';
import ConsultarCupo from '../components/Orden/ConsultarCupo';
import ConsultarOrdenes from '../components/Orden/ConsultarOrdenes';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import VerRecetaCronica from '../components/Receta/VerRecetaCronica';

const PrivateRoute = ({ children, ...props }) => {
  const { user } = useUser();
  // const history = useHistory();

  // React.useEffect(() => {
  //   if (!user) {
  //     history.push('/signin', { from: history.location });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  return (
    <Route
      {...props}
      render={({ location }) => {
        // return children;
        return user ? (
          children
        ) : (
          <Redirect to={{ pathname: '/signin', state: { from: location } }} />
        );
      }}
    />
  );
};

const Pages = (props) => (
  <Box>
    <Switch>
      {/* Error/Services pages */}

      {/* Public routes */}
      <Route path='/signin'>
        {' '}
        <SignIn />{' '}
      </Route>
      <Route path='/recuperar-pin'>
        <RecuperarPin />
      </Route>
      <Route path='/olvide-pin'>
        <OlvidePin />
      </Route>
      <Route exact path='/crear-cuenta'>
        <CrearCuenta />
      </Route>
      <Route path='/validar-identidad/agregar-correo/recuperar-pin'>
        <CrearCuenta recuperarPin={true} />
      </Route>
      <Route path='/confirmar-contacto-crear-cuenta/'>
        {' '}
        <ConfirmarContactoCrearCuentaView />
      </Route>
      <Route path='/mantenimiento'>
        <Mantenimiento />
      </Route>

      {/* Private routes */}
      <PrivateRoute exact path='/'>
        <Home />
      </PrivateRoute>
      <PrivateRoute exact path='/perfil'>
        <Perfil />
      </PrivateRoute>
      <PrivateRoute exact path='/terminos-condiciones'>
        <TerminosCondiciones />
      </PrivateRoute>
      <PrivateRoute path='/signout'>
        <SignOut />
      </PrivateRoute>
      <PrivateRoute path='/validar-contacto'>
        <ValidarContacto />
      </PrivateRoute>
      <PrivateRoute path='/orden'>
        <Orden />
      </PrivateRoute>
      <PrivateRoute exact path='/emitir-orden'>
        <EmitirOrden />
      </PrivateRoute>
      <PrivateRoute path='/emitir-orden/:idAfiliado'>
        {' '}
        <EmitirOrden />
      </PrivateRoute>
      <PrivateRoute path='/reimprimir-orden'>
        <ReimprimirOrden />
      </PrivateRoute>
      <PrivateRoute path='/grupo-familiar'>
        <GrupoFamiliar />
      </PrivateRoute>
      <PrivateRoute path='/cupon-pago-capita'>
        <ImprimirCuponCapita />
      </PrivateRoute>
      <PrivateRoute path='/cambiar-pin'>
        <CambiarPassword />
      </PrivateRoute>
      <PrivateRoute path='/ver-afiliado/:idAfiliado'>
        {' '}
        <VerAfiliado />
      </PrivateRoute>
      <PrivateRoute path='/validar-identidad/:validarIdentidad'>
        {' '}
        <CrearCuenta />
      </PrivateRoute>
      <PrivateRoute path='/verificar-contacto/:id/:contacto'>
        {' '}
        <ConfirmarContactoView />
      </PrivateRoute>
      <PrivateRoute path='/read-orden'>
        <ReadQr />
      </PrivateRoute>
      <PrivateRoute path='/previsualizar'>
        <DocumentoPreview />
      </PrivateRoute>
      <PrivateRoute path='/prestadores'>
        <Prestadores />
      </PrivateRoute>
      <PrivateRoute path='/buscar-prestadores'>
        <BuscarPrestador />
      </PrivateRoute>
      <PrivateRoute path='/buscar-establecimientos'>
        <BuscarEstablecimiento />
      </PrivateRoute>
      <PrivateRoute path='/buscar-farmacias'>
        <BuscarFarmacias />
      </PrivateRoute>
      <PrivateRoute path='/reintegros'>
        <Reintegros />
      </PrivateRoute>
      <PrivateRoute path='/derivaciones'>
        <Derivaciones />
      </PrivateRoute>
      <PrivateRoute path='/add-derivacion'>
        <AddTransitoria />
      </PrivateRoute>
      <PrivateRoute path='/aportes'>
        <Aportes />
      </PrivateRoute>
      <PrivateRoute path='/consumo-prestacional'>
        <ConsumoPrestacional />
      </PrivateRoute>
      <PrivateRoute path='/delegaciones'>
        <Delegaciones />
      </PrivateRoute>
      <PrivateRoute path='/autorizaciones'>
        <VerAutorizaciones />
      </PrivateRoute>
      <PrivateRoute path='/receta'>
        <Receta />
      </PrivateRoute>
      <PrivateRoute path='/consultar-recetas'>
        <BuscarRecetas />
      </PrivateRoute>
      <PrivateRoute path='/consultar-recetas-cronicas'>
        <BuscarRecetasCronicas />
      </PrivateRoute>
      <PrivateRoute path='/ver-receta-cronica/:numeroReceta'>
        {' '}
        <VerRecetaCronica />
      </PrivateRoute>
      <PrivateRoute path='/consultar-cupo'>
        <ConsultarCupo />
      </PrivateRoute>
      <PrivateRoute path='/consultar-ordenes'>
        <ConsultarOrdenes />
      </PrivateRoute>
      <PrivateRoute path='/ayuda'>
        <Ayuda />
      </PrivateRoute>
    </Switch>
  </Box>
);

export default Pages;
