import {
  ArrowForwardIos as ArrowForwardIosIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  HighlightOffOutlined as HighlightOffOutlinedIcon,
} from '@mui/icons-material';
import {
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export const ContactoVerificado = ({ contacto, status, message, link }) => {
  if (!contacto) {
    return;
  }

  return (
    <ListItem
      disablePadding
      secondaryAction={
        status === 'error' && (
          <Tooltip id='button-link' title='Verificar'>
            <IconButton component={Link} to={link}>
              <ArrowForwardIosIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        )
      }
    >
      <Grid container>
        <Grid item xs={12} md={6} lg={6}>
          <ListItemText primary={contacto} primaryTypographyProps={{ fontWeight: 'bold' }} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <ListItemText
            primary={
              <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
                {status === 'error' && (
                  <HighlightOffOutlinedIcon color={'error'} fontSize='small' />
                )}
                {status === 'success' && (
                  <CheckCircleOutlineIcon color={'success'} fontSize='small' />
                )}
                <Typography
                  variant='caption'
                  display='block'
                  gutterBottom
                  sx={{ color: status === 'error' ? '#ef5350' : '#4caf50' }}
                >
                  {contacto && message}
                </Typography>
              </Stack>
            }
          />
        </Grid>
      </Grid>
    </ListItem>
  );
};
