export const currencyFormatter = () => {
  return new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2,
  });
};

export const dateFormatter = () => {
  return new Intl.DateTimeFormat('es-AR', {
    dateStyle: 'short',
    timeStyle: 'short',
  });
};

/**
 * Recibe un string date yyyy-mm-dd y devuelve dd de MM de yyyy
 *
 * @param String
 * @return String
 */
export const dateBirthFormatter = (date = '', type = 'yyyy-mm-dd') => {
  if (!date || date?.trim()?.length < 1) {
    return null;
  }
  const [day, month, year] = date?.split('/') || [];
  if (type === 'dd/mm/yyyy' && (!day || !month || !year)) {
    return null;
  }
  switch (type) {
    case 'yyyy-mm-dd':
      return new Intl.DateTimeFormat('es-AR', {
        dateStyle: 'long',
      }).format(new Date(`${date} 00:00:00`));
    case 'dd/mm/yyyy':
      return new Intl.DateTimeFormat('es-AR', {
        dateStyle: 'long',
      }).format(new Date(`${year}-${month}-${day} 00:00:00`));
    default:
      return date;
  }
};

/**
 * Recibe un string date yyyy-mm-dd y devuelve un string con el otro formato que recibe como parametro
 * ej., 01/02/2023 -> 2023-02-01
 *
 * @param String
 * @return String
 */
export const dateToFormatter = (date, type = 'dd/mm/yyyy', toType = 'yyyy-mm-dd') => {
  if (!date) {
    return null;
  }
  switch (type) {
    case 'dd/mm/yyyy': {
      switch (toType) {
        case 'yyyy-mm-dd':
          return (
            date[6] +
            date[7] +
            date[8] +
            date[9] +
            '-' +
            date[3] +
            date[4] +
            '-' +
            date[0] +
            date[1]
          );
      }
    }
    default:
      return date;
  }
};

/**
 * Función que transforma una cadena en base64 a Blob.
 * para PDF, imágenes, etc.
 * @param String
 * @param String
 * @param int
 * @return Blob
 */
export const base64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

/**
 * Convierte un valor de bytes para devolver en MB
 *
 * @param {Number} bytes
 * @returns
 */
export const bytesToMb = (bytes) => {
  return (bytes / (1024 * 1024)).toFixed(2);
};

/**
 * Convierte un valor de bytes para devolver en KB
 *
 * @param {Number} bytes
 * @returns
 */
export const bytesToKb = (bytes) => {
  return (bytes / 1024).toFixed(2);
};
