import React, { useEffect, useState } from 'react';
import {
  useMediaQuery,
  IconButton,
  Box,
  Stepper,
  Step,
  StepLabel,
  Card,
  Grid,
  Typography,
  CardContent,
  FormControl,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Divider,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { HighlightOff, Save, PhotoCamera, Delete } from '@mui/icons-material';
import LoadingButton from '../../@iosper/components/LoadingButton';
import {
  getComprobanteAutorizacion,
  saveArchivoAutorizacion,
  saveAutorizacion,
} from '../../services/Afiliado/Autorizaciones';
import CustomizedSnackbars from '../Notifications/SnackBar';
import AfiliadoSelect from '../Afiliados/AfiliadoSelect';
import AutorizacionPreview from './AutorizacionPreview';
import logo from '../../assets/images/logo-pdf.png';
import { useTheme } from '@emotion/react';
import ButtonBack from '../Breadcrumb/ButtonBack';
import FileUpload from '../Common/FileUpload';

const ARCHIVOS_REQUERIDOS_MAX_LENGTH = 4;
const ARCHIVOS_REQUERIDOS_MAX_SIZE = 4;

const ARCHIVOS_OPCIONALES_MAX_LENGTH = 4;
const ARCHIVOS_OPCIONALES_MAX_SIZE = 4;

const AddAutorizacion = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const esMobile = matches ? false : true;

  const [loading, setLoading] = useState(false);
  const [keyComponentAfiliado, setKeyComponentAfiliado] = useState(0);
  const [selectedAfiliado, setSelectedAfiliado] = useState(null);
  const [idAfiliado, setIdAfiliado] = useState(null);

  const [datosAfiliado, setDatosAfiliado] = useState(false);
  const [datosPractica, setDatosPractica] = useState(false);
  const [firmaMedico, setFirmaMedico] = useState(false);
  const [fechaPrescripcion, setFechaPrescripcion] = useState(false);

  const steps = ['Paso 1', 'Paso 2', 'Paso 3'];

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [openDialogReporte, setOpenDialogReporte] = useState(false);
  const [urlReporte, setUrlReporte] = useState('');
  const [datosAutorizacionGenerada, setDatosAutorizacionGenerada] = useState('');

  const [archivosRequeridosTemp, setArchivosRequeridosTemp] = useState([]);
  const [archivosOpcionalesTemp, setArchivosOpcionalesTemp] = useState([]);
  const [archivosRequeridosButtonDisabled, setArchivosRequeridosButtonDisabled] = useState(false);
  const [archivosOpcionalesButtonDisabled, setArchivosOpcionalesButtonDisabled] = useState(false);
  const [cantidadArchivosRequeridosTemp, setCantidadArchivosRequeridosTemp] = useState(0);
  const [cantidadArchivosOpcionalesTemp, setCantidadArchivosOpcionalesTemp] = useState(0);
  const [tamanioArchivosRequeridosTemp, setTamanioArchivosRequeridosTemp] = useState(0);
  const [tamanioArchivosOpcionalesTemp, setTamanioArchivosOpcionalesTemp] = useState(0);
  const [disabledSiguiente, setDisabledSiguiente] = useState(true);
  const [contador, setContador] = useState(1);
  const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
  const uidContext = Math.random().toString(36).substr(2, 10);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const handlerLimpiar = () => {
    setKeyComponentAfiliado(1 + Math.random() * (1000 - 1));
    reset();
    setLoading(false);
  };

  const handlerCancelar = () => {
    handlerLimpiar();
    props?.onCancelar();
  };

  const onSelectAfiliado = (afiliado) => {
    setSelectedAfiliado(afiliado);
    setIdAfiliado(afiliado?.afiliado?.idAfiliado);
  };

  const validateAfiliado = () => {
    return selectedAfiliado === null ? false : true;
  };

  useEffect(() => {
    handlerDisabled();
  }, [
    selectedAfiliado,
    datosAfiliado,
    datosPractica,
    firmaMedico,
    fechaPrescripcion,
    activeStep,
    archivosRequeridosTemp,
  ]);

  const handleChangeDatosAfiliado = (e) => {
    setDatosAfiliado(e.target.checked);
  };

  const handleChangeDatosPractica = (e) => {
    setDatosPractica(e.target.checked);
  };

  const handleChangeFirmaMedico = (e) => {
    setFirmaMedico(e.target.checked);
  };

  const handleChangeFechaPrescripcion = (e) => {
    setFechaPrescripcion(e.target.checked);
  };

  const handleBack = () => {
    if (activeStep === 1) setError('');
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNextStep = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === 1) setError('');

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();

    if (activeStep !== steps.length - 1) {
      handleNextStep(); //si no es el ultimo paso, salto...
      return;
    }

    setLoading(true);
    let autorizacion = {
      idAfiliado: selectedAfiliado.afiliado.idAfiliado,
      documentacionRequerida: archivosRequeridosTemp,
      documentacionOpcional: archivosOpcionalesTemp,
      uidContext: uidContext,
    };

    try {
      const datosAutorizacion = await saveAutorizacion(autorizacion);
      const pdf = await getComprobanteAutorizacion({
        id: datosAutorizacion.solicitudCobertura.idSolicitudCobertura,
        enviarMail: true,
      });

      handlerAutorizacionGenerada(datosAutorizacion, pdf);
      //setSuccess("La autorizacion se ha guardado con éxito!.")
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handlerAutorizacionGenerada = (datosAutorizacion, urlPdf) => {
    setUrlReporte(urlPdf);
    setOpenDialogReporte(true);
    setDatosAutorizacionGenerada(datosAutorizacion);
    handlerLimpiar();
    props.onSaved();
  };

  const bytesToMb = (value) => {
    return value / 1048576;
  };

  const onInputFileRequeridoChange = (event) => {
    if (archivosRequeridosTemp.length >= ARCHIVOS_REQUERIDOS_MAX_LENGTH) {
      setError('Llegaste al límite de cantidad de archivos que podes cargar.');
    } else {
      const files = Array.from(event.target.files);

      if (files.length > 0) {
        const file = files[0];
        //const preview = URL.createObjectURL(event.target.files[0]);
        //setArchivo(URL.createObjectURL(event.target.files[0]));
        //setArchivos([...archivos, preview]);
        //setArchivosRequeridosTemp([...archivosRequeridosTemp, datosAutorizacion]);
        if (file && !isValidFileType(file)) {
          setError('Por favor selecciona un archivo (pdf, jpg, png) válido.');
          return;
        }

        let sizeFile = bytesToMb(file.size);

        sizeFile = sizeFile + tamanioArchivosRequeridosTemp;

        if (sizeFile >= ARCHIVOS_REQUERIDOS_MAX_SIZE) {
          setError('Superaste el limite de tamaño de archivos que podes cargar.');
        } else {
          convertToBase64(file, true);
        }
      }
    }
  };

  const isValidFileType = (file) => {
    return allowedTypes.includes(file.type);
  };

  const onInputFileOpcionalChange = (event) => {
    if (archivosOpcionalesTemp.length >= ARCHIVOS_OPCIONALES_MAX_LENGTH) {
      setError('Llegaste al límite de cantidad de archivos que podes cargar.');
    } else {
      const files = Array.from(event.target.files);

      if (files.length > 0) {
        const file = files[0];

        if (file && !isValidFileType(file)) {
          setError('Por favor selecciona un archivo (pdf, jpg, png) válido.');
          return;
        }

        let sizeFile = bytesToMb(file.size);

        sizeFile = sizeFile + tamanioArchivosOpcionalesTemp;

        if (sizeFile >= ARCHIVOS_OPCIONALES_MAX_SIZE) {
          setError('Superaste el límite de tamaño de archivos que podes cargar.');
        } else {
          convertToBase64(file, false);
        }
      }
    }
  };

  const convertToBase64 = (file, requerido) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const fileExtension = file.type.split('/')[1];
      let sizeMB = bytesToMb(file.size);

      let a = {
        fileName: file.name,
        linkPreview: logo,
        extension: '.' + fileExtension,
        dimension: file.size,
        contentBase64: reader.result.split(',')[1],
        moduleContainerPathName: uidContext,
        requerido: requerido,
        id: contador,
      };

      let existeArchivo = false;
      if (requerido) {
        existeArchivo = archivosRequeridosTemp.find(
          (item) => item.fileName === a.fileName && item.dimension === a.dimension,
        );
      } else {
        existeArchivo = archivosOpcionalesTemp.find(
          (item) => item.fileName === a.fileName && item.dimension === a.dimension,
        );
      }

      if (existeArchivo) {
        setError('El archivo que está intentando subir ya existe.');
        return;
      }

      setContador(contador + 1);

      if (requerido) {
        setArchivosRequeridosTemp([...archivosRequeridosTemp, a]);
        const cantidadArchivosRequeridos = cantidadArchivosRequeridosTemp + 1;
        setCantidadArchivosRequeridosTemp(cantidadArchivosRequeridos);
        setArchivosRequeridosButtonDisabled(
          cantidadArchivosRequeridos >= ARCHIVOS_REQUERIDOS_MAX_LENGTH,
        );

        setTamanioArchivosRequeridosTemp(tamanioArchivosRequeridosTemp + sizeMB);
      } else {
        setArchivosOpcionalesTemp([...archivosOpcionalesTemp, a]);
        const cantidadArchivosOpcionales = cantidadArchivosOpcionalesTemp + 1;
        setCantidadArchivosOpcionalesTemp(cantidadArchivosOpcionales);
        setArchivosOpcionalesButtonDisabled(
          cantidadArchivosOpcionales >= ARCHIVOS_OPCIONALES_MAX_LENGTH,
        );
        setTamanioArchivosOpcionalesTemp(tamanioArchivosOpcionalesTemp + sizeMB);
      }
    };
  };

  const handleClose = () => {
    props?.onCancelar();
  };

  useEffect(() => {
    setArchivosRequeridosButtonDisabled(
      cantidadArchivosRequeridosTemp >= ARCHIVOS_REQUERIDOS_MAX_LENGTH,
    );
  }, [cantidadArchivosRequeridosTemp]);

  useEffect(() => {
    setArchivosOpcionalesButtonDisabled(
      cantidadArchivosOpcionalesTemp >= ARCHIVOS_OPCIONALES_MAX_LENGTH,
    );
  }, [cantidadArchivosOpcionalesTemp]);

  const onDeleteArchivoRequerido = (event) => {
    let archivoEliminado = archivosRequeridosTemp.filter(
      (i) => parseInt(i?.id, 10) === parseInt(event?.currentTarget?.value, 10),
    );

    let newListArchivosReq = archivosRequeridosTemp.filter(
      (i) => parseInt(i?.id, 10) !== parseInt(event?.currentTarget?.value, 10),
    );

    setCantidadArchivosRequeridosTemp(cantidadArchivosRequeridosTemp - 1);

    setTamanioArchivosRequeridosTemp(
      tamanioArchivosRequeridosTemp - bytesToMb(archivoEliminado[0].dimension),
    );

    setArchivosRequeridosTemp(newListArchivosReq);
  };

  const onDeleteArchivoOpcional = (event) => {
    let archivoOpcionalEliminado = archivosOpcionalesTemp.filter(
      (i) => parseInt(i?.id, 10) === parseInt(event?.currentTarget?.value, 10),
    );

    let newListArchivosOpc = archivosOpcionalesTemp.filter(
      (i) => parseInt(i?.id, 10) !== parseInt(event?.currentTarget?.value, 10),
    );

    setCantidadArchivosOpcionalesTemp(cantidadArchivosOpcionalesTemp - 1);

    setTamanioArchivosOpcionalesTemp(
      tamanioArchivosOpcionalesTemp - bytesToMb(archivoOpcionalEliminado[0].dimension),
    );

    setArchivosOpcionalesTemp(newListArchivosOpc);
  };

  const handleBorrarMensaje = () => {
    setError('');
    setSuccess('');
  };

  const handlerDisabled = () => {
    switch (activeStep) {
      case 0:
        setDisabledSiguiente(
          selectedAfiliado == null ||
            !datosAfiliado ||
            !datosPractica ||
            !firmaMedico ||
            !fechaPrescripcion,
        );
        break;
      case 1:
        setDisabledSiguiente(archivosRequeridosTemp.length === 0);
        break;
      default:
        setDisabledSiguiente(true);
        break;
    }
  };

  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return (
          <>
            <FormControl fullWidth={true} variant='outlined' margin='none'>
              <AfiliadoSelect
                key={keyComponentAfiliado}
                size='small'
                ref={register('afiliadoRegister', {
                  validate: validateAfiliado,
                })}
                onSelectAfiliado={onSelectAfiliado}
                onDefaultAfiliado={idAfiliado}
              />
              {errors.afiliadoRegister && (
                <p style={{ color: 'red' }}>Debe seleccionar el afiliado</p>
              )}
            </FormControl>

            <Box mb={4}></Box>
            <Divider />
            <Box mb={1}></Box>

            <FormControl fullWidth={true} variant='outlined' margin='none'>
              <Typography variant='body' color='textSecondary' component='p' spacing={1}>
                <b>Verificá los siguientes datos de la Prescripción Médica ORIGINAL (no copia):</b>
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox onChange={handleChangeDatosAfiliado} checked={datosAfiliado} />
                  }
                  label={
                    <Typography component='p' sx={{ fontSize: '14px' }}>
                      <b>Nombre, apellido y número de documento</b> del paciente de forma clara.
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox onChange={handleChangeDatosPractica} checked={datosPractica} />
                  }
                  label={
                    <Typography component='p' sx={{ fontSize: '14px' }}>
                      Descripción de la <b>práctica (codificada) y diagnóstico</b> presuntivo.
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={<Checkbox onChange={handleChangeFirmaMedico} checked={firmaMedico} />}
                  label={
                    <Typography component='p' sx={{ fontSize: '14px' }}>
                      <b>Firma y sello</b> del médico.
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeFechaPrescripcion}
                      checked={fechaPrescripcion}
                    />
                  }
                  label={
                    <Typography component='p' sx={{ fontSize: '14px' }}>
                      <b>Fecha de prescripción</b>, que no supere la actual, ni 60 días de
                      vencimiento.
                    </Typography>
                  }
                />
              </FormGroup>
            </FormControl>
          </>
        );

      case 1:
        return (
          <>
            <Typography variant='body2' color='textSecondary' component='p' spacing={1}>
              <b>Para poder continuar adjuntá el Pedido Medico RP</b>
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p' spacing={1}>
              Cantidad de archivos: <strong> {cantidadArchivosRequeridosTemp} / 4</strong>
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p' spacing={1}>
              Tamaño de Archivos:{' '}
              <strong>{tamanioArchivosRequeridosTemp.toFixed(2)}MB / 4MB</strong>
            </Typography>
            <Box>
              <ImageList
                sx={{ height: 250 }}
                color='neutral'
                cols={esMobile ? 2 : 5}
                rowHeight={100}
              >
                {archivosRequeridosTemp?.length > 0 &&
                  archivosRequeridosTemp.map((i, k) => (
                    <ImageListItem key={`${i.fileName}-${k}`} cols={1} sx={{ height: 100 }}>
                      {i.extension === '.pdf' ? (
                        <img src={i.linkPreview} alt={i.fileName} style={{ height: '100px' }} />
                      ) : (
                        <img
                          src={`data:image;base64,${i.contentBase64}`}
                          alt={i.fileName}
                          style={{ height: '100px' }}
                        />
                      )}
                      <ImageListItemBar
                        title={i.fileName}
                        key={`${i.fileName}-${k}`}
                        actionIcon={
                          <IconButton
                            value={i.id}
                            aria-label={`info about ${i.fileName}`}
                            onClick={onDeleteArchivoRequerido}
                          >
                            <Delete color='error' />
                          </IconButton>
                        }
                      />
                    </ImageListItem>
                  ))}
              </ImageList>
            </Box>

            <FormControl fullWidth={true} variant='outlined' margin='normal' align='center'>
              <FileUpload
                accept={allowedTypes.join(',')}
                compressFile
                onChangeUpload={onInputFileRequeridoChange}
                color='neutral'
                startIcon={<PhotoCamera />}
                size='medium'
                variant='contained'
                aria-label='Subir archivo'
                disabled={archivosRequeridosButtonDisabled}
              >
                Subir archivo
              </FileUpload>
            </FormControl>
          </>
        );
      case 2:
        return (
          <>
            <Typography variant='body2' color='textSecondary' component='p' spacing={1}>
              <b>Es opcional que adjuntes estudios previos</b>
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p' spacing={1}>
              Cantidad de archivos: <strong>{cantidadArchivosOpcionalesTemp} / 4</strong>
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p' spacing={1}>
              Tamaño de Archivos:{' '}
              <strong>{tamanioArchivosOpcionalesTemp.toFixed(2)}MB / 4MB</strong>
            </Typography>
            <Box>
              <ImageList
                sx={{ height: 250 }}
                color='neutral'
                cols={esMobile ? 2 : 5}
                rowHeight={100}
              >
                {archivosOpcionalesTemp?.length > 0 &&
                  archivosOpcionalesTemp.map((i, k) => (
                    <ImageListItem key={`${i.fileName}-${k}`} cols={1}>
                      {i.extension === '.pdf' ? (
                        <img src={i.linkPreview} alt={i.fileName} style={{ height: '100px' }} />
                      ) : (
                        <img
                          src={`data:image;base64,${i.contentBase64}`}
                          alt={i.fileName}
                          style={{ height: '100px' }}
                        />
                      )}
                      <ImageListItemBar
                        title={i.fileName}
                        key={`${i.fileName}-${k}`}
                        actionIcon={
                          <IconButton
                            value={i.id}
                            aria-label={`info about ${i.fileName}`}
                            onClick={onDeleteArchivoOpcional}
                          >
                            <Delete color='error' />
                          </IconButton>
                        }
                      />
                    </ImageListItem>
                  ))}
              </ImageList>
            </Box>

            <FormControl fullWidth={true} variant='outlined' margin='normal' align='center'>
              <FileUpload
                accept={allowedTypes.join(',')}
                compressFile
                onChangeUpload={onInputFileOpcionalChange}
                color='neutral'
                startIcon={<PhotoCamera />}
                size='medium'
                variant='contained'
                aria-label='Subir archivo'
                disabled={archivosOpcionalesButtonDisabled}
              >
                Subir archivo
              </FileUpload>
            </FormControl>
          </>
        );
      default:
        return <div>Not Found</div>;
    }
  }

  return !openDialogReporte ? (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ButtonBack
          marginBotton='1'
          variant='text'
          size='small'
          onClick={handlerCancelar}
        ></ButtonBack>
        <Card sx={{ width: '100%', p: 1 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Nueva Solicitud de Autorización
              </Typography>
              <br />
              <Stepper activeStep={activeStep} spacing={1}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <>
                <br />
                {_renderStepContent(activeStep)}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Anterior
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {/*isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Saltar
                </Button>
              )*/}
                  {/*<Button onClick={handleReset}>Cancelar</Button>*/}
                  {!success &&
                    (activeStep === steps.length - 1 ? (
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 0 }}>
                        <LoadingButton
                          startIcon={<Save />}
                          variant='contained'
                          color='primary'
                          type='submit'
                          loading={loading ? 'show' : 'hide'}
                          content='Guardar'
                        />
                      </Box>
                    ) : (
                      <Button
                        variant='contained'
                        size='medium'
                        type='submit'
                        color='primary'
                        disabled={disabledSiguiente}
                      >
                        Siguiente
                      </Button>
                    ))}

                  {success && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 0 }}>
                      <Button
                        size='small'
                        startIcon={<HighlightOff />}
                        variant='contained'
                        color='primary'
                        onClick={(e) => handleClose(e)}
                      >
                        Cerrar
                      </Button>
                    </Box>
                  )}
                </Box>
              </>
            </CardContent>
          </form>
        </Card>
        <CustomizedSnackbars
          open={success}
          autoHideDuration={6000}
          onDeleteMessage={handleBorrarMensaje}
          severity='success'
          message={success}
        />
        <CustomizedSnackbars
          open={error}
          autoHideDuration={6000}
          onDeleteMessage={handleBorrarMensaje}
          severity='error'
          message={error}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <AutorizacionPreview
          open={openDialogReporte}
          setOpen={setOpenDialogReporte}
          urlReporte={urlReporte}
          datosAutorizacion={datosAutorizacionGenerada}
          nombreReporte={'Solicitud_cobertura_iosper_'}
          handlerCerrarPreview={handlerCancelar}
        ></AutorizacionPreview>
      </Grid>
    </Grid>
  );
};

export default AddAutorizacion;
