import Grid from '@mui/material/Grid';
import cupoImg from '../../assets/images/menu/cupo.svg';
import ordenBusquedaImg from '../../assets/images/menu/orden-busqueda.svg';
import { useTheme } from '@emotion/react';
import CardButton from '../../@iosper/components/Card/CardButton';
import { useMediaQuery } from '@mui/material';
import Title from '../../@iosper/components/Page/Title';
import { isMobile } from '../../@iosper/utils/DeviceDetector';

const Orden = () => {
  // Para ver si entró desde mobile o web, si es mobile no muestra la opción de Orden Electrónica
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const showOrdenDigital = isMobile() || !matches;

  return (
    <>
      <Title>Recetas Electrónicas</Title>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <CardButton
            title={'Consultar Plan Ambulatorio'}
            description={'Consultá recetas del plan ambulatorio.'}
            to={'/consultar-recetas'}
            image={cupoImg}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <CardButton
            title={'Planes Especiales y Crónicos'}
            description={'Consultá recetas de planes especiales y crónicos.'}
            to={'/consultar-recetas-cronicas'}
            image={ordenBusquedaImg}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Orden;
