import { axiosGet, axiosPost, axiosPostFile } from '../ServiceBase.js';

/**
 * Validar orden api call
 * @returns
 */
export const getValidarOrden = async (data) => {
  return axiosPost('/v1/orden/validar-orden', data);
};

/**
 * Verificar costo orden api call
 * @returns
 */
export const getVerificarCostoOrden = async (data) => {
  return axiosPost('/v1/orden/verificar-costo-orden', data);
};

/**
 * Generar orden api call
 * @returns
 */
export const getGenerarOrden = async (codigo) => {
  return axiosPost('/v1/orden/generar-orden', { codigoTransaccion: codigo });
};

/**
 * Imprimir orden api call
 * @returns
 */
export const getImprimirOrden = async (data) => {
  return axiosPostFile('/v1/orden/imprimir-orden', {
    numeroOrden: data.numeroOrden,
    codigoTransaccion: data.codigoTransaccion,
  });
};

/**
 * valida la reimpresion en base al id afiliado
 * @param {*} data
 * @returns
 */
export const validarReimpresionOrden = async (data) => {
  return axiosPost('/v1/orden/validar-reimpresion', data);
};

/**
 * Reimprimir orden api call
 * @returns
 */
export const getReimprimirOrden = async (data) => {
  return axiosPostFile('/v1/orden/reimpresion-orden', { idOrden: data.numeroOrden });
};

/**
 * Reimprimir orden api call
 * @returns
 */
export const enviarOrden = async (data) => {
  return axiosPostFile('/v1/orden/enviar-orden', {
    numeroOrden: data.numeroOrden,
    correo: data.correo,
  });
};

export const consultarCuposOrdenMedica = async (data) => {
  return axiosPost('/v1/orden/consultar-cupo-orden', data);
};

export const consultarOrdenes = async (idAfiliado, fechaDesde, fechaHasta) => {
  let params = '';

  if (idAfiliado) {
    params = `?idAfiliado=${idAfiliado}`;
  }

  if (fechaDesde) {
    params += params === '' ? `?fechaDesde=${fechaDesde}` : `&fechaDesde=${fechaDesde}`;
  }

  if (fechaHasta) {
    params += params === '' ? `?fechaHasta=${fechaHasta}` : `&fechaHasta=${fechaHasta}`;
  }

  return await axiosGet(`/v1/ordenes/afiliados${params || ''}`);
};
