import React, { useEffect, useState } from 'react';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import {
  Alert,
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  ToggleButton,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useWidth } from '../../@iosper/utils/UseWidth';

const DataCollapseDrawer = (props) => {
  const { medicamento, columnPeriodoVisible } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <Typography
            variant='body2'
            align='left'
            gutterBottom={false}
            sx={{ color: '#000', textTransform: 'none' }}
          >
            {medicamento.descripcion + ' ' + medicamento.fecha}
          </Typography>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <List>
            {columnPeriodoVisible && (
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary='Periodo' secondary={medicamento.periodo} />
              </ListItem>
            )}
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Número' secondary={medicamento.numero} />
            </ListItem>
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const MedicamentosTable = ({ medicamentos, columnasExcluidas }) => {
  const screenWidth = useWidth();
  const [columnPeriodoVisible, setColumnPeriodoVisible] = useState(true);

  useEffect(() => {
    setColumnPeriodoVisible(
      columnasExcluidas?.find((element) => element === 'periodo') !== undefined ? false : true,
    );
  }, [columnasExcluidas]);

  /// -------------------- DATA GRID CONSUMOS --------------------------
  const mapConsumoToRow = (medicamento, index) => ({
    id: index,
    numero: medicamento.numeroSolicitud,
    periodo: medicamento.periodo,
    fecha: medicamento.fechaSolicitud,
    descripcion: medicamento.cantidad
      ? medicamento.cantidad + ' x ' + medicamento.nombrePrestacion
      : medicamento.nombrePrestacion,
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: 'Descripción - Fecha',
      flex: 1,
      renderCell: (a) => (
        <DataCollapseDrawer medicamento={a.row} columnPeriodoVisible={columnPeriodoVisible} />
      ),
    },
    { field: 'descripcion', headerName: 'Descripción', flex: 1 },
    { field: 'fecha', headerName: 'Fecha', flex: 1 },
    { field: 'periodo', headerName: 'Periodo', flex: 1 },
    { field: 'numero', headerName: 'Número', flex: 1, width: 120 },
  ];

  return medicamentos?.length ? (
    <div style={{ width: '100%' }}>
      <DataGrid
        sx={{
          border: 0,
          '& .MuiDataGrid-cell': {
            py: '8px',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#F2F5F5',
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        autoHeight={true}
        loading={!medicamentos.length}
        rows={medicamentos.map((a, index) => mapConsumoToRow(a, index))}
        columns={columns}
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 200}
        columnVisibilityModel={{
          desplegable: screenWidth === 'xs',
          numero: screenWidth !== 'xs',
          periodo: screenWidth !== 'xs' && columnPeriodoVisible,
          fecha: screenWidth !== 'xs',
          descripcion: screenWidth !== 'xs',
        }}
        pagination={screenWidth === 'xs' ? null : true}
        disableColumnMenu
      />
    </div>
  ) : (
    <Alert severity='info'>No se han encontrado resultados</Alert>
  );
};

export default MedicamentosTable;
