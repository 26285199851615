import { axiosPost } from '../ServiceBase.js';

export const findRecetasCronicas = async (data) => {
  const recetas = await axiosPost('/v1/busquedas/receta-cronicas/buscar', data);
  return recetas;
};

export const findRecetaCronica = async (numeroReceta) => {
  const receta = await axiosPost('/v1/recetas/find-receta-cronica', { numeroReceta: numeroReceta });
  return receta;
};
