import { useEffect, useState } from 'react';
import imageCompression from 'browser-image-compression';
import { bytesToKb } from '../../../@iosper/utils/Format';

const FILE_TYPE_IMAGE = 'image';
const FILE_TYPE_PDF = 'pdf';

const imageConfigCompression = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1024,
  useWebWorker: true,
};

export const useFileCompression = ({ compressFile = false }) => {
  const [inputFile, setInputFile] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isLoadingCompression, setIsLoadingCompression] = useState(false);
  const [outputFile, setOutputFile] = useState(null);
  const [progressCompression, setProgressCompression] = useState(null);

  useEffect(() => {
    if (inputFile) {
      onHandleFileCompression();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFile]);

  /**
   * Detectamos el cambio de estado al subir un archivo
   * y seteamos el objeto con los datos del archivo original a procesar
   *
   * @param {target} target
   */
  const onHandleFileUpload = ({ target }) => {
    const file = target.files[0];
    if (file) {
      setIsFileUploaded(true);
      setOutputFile(null);
      setInputFile(file);
    }
  };

  /**
   * Retorna el tipo de archivo que se subió
   *
   * @returns string|null
   */
  const getFileType = () => {
    if (!isFileUploaded || !inputFile?.type) {
      return null;
    }

    const type = inputFile.type.toLowerCase();

    if (type.startsWith('image/')) {
      return FILE_TYPE_IMAGE;
    } else if (type.startsWith('application/pdf')) {
      return FILE_TYPE_PDF;
    }

    return null;
  };

  /**
   * Ejecuta la compresión del archivo según el tipo de archivo permitido
   */
  const onHandleFileCompression = () => {
    if (!compressFile) {
      setOutputFile(inputFile);
      return;
    }

    const tipoArchivo = getFileType();
    switch (tipoArchivo) {
      case FILE_TYPE_IMAGE:
        onHandleImageCompression();
        break;
      default:
        setOutputFile(inputFile);
        return;
    }
  };

  /**
   * Manejo de la compresión de imagenes
   *
   * @param {Number} maxAcceptedCompressionSize in KB
   * @returns
   */
  const onHandleImageCompression = (maxAcceptedCompressionSize = 600) => {
    if (isFileUploaded && inputFile) {
      if (bytesToKb(inputFile.size) <= maxAcceptedCompressionSize) {
        setOutputFile(inputFile);
        return;
      }

      const options = {
        ...imageConfigCompression,
        onProgress: (p) => onProgress(p),
      };

      setIsLoadingCompression(true);
      imageCompression(inputFile, options)
        .then((outputImageFile) => {
          setOutputFile(outputImageFile);
          setIsLoadingCompression(false);
        })
        .catch(function (error) {
          setIsLoadingCompression(false);
          console.log(error.message); // output: I just want to stop
        });
    }
  };

  /**
   * Progreso en la subida
   *
   * @param {*} p
   */
  function onProgress(p) {
    setProgressCompression(p);
  }

  return {
    inputFile: inputFile,
    isLoading: isLoadingCompression,
    onHandleFileUpload: onHandleFileUpload,
    outputFile: outputFile,
    progressCompression: progressCompression,
  };
};
