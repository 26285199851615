import { Box, Typography } from '@mui/material';

const TerminosCondiciones = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='h6' component='div' align='center' paragraph={true}>
        Términos y Condiciones de Uso de mi.iosper
      </Typography>

      <Typography variant='h6 ' component='div' align='left' paragraph={true}>
        A continuación, se describen los Términos y Condiciones Generales
      </Typography>

      <Typography variant='caption ' component='div' align='justify' paragraph={true}>
        A continuación, se describen los Términos y Condiciones Generales (en adelante las
        &quot;Condiciones Generales&quot;), se entiende que el AFILIADO las acepta sin reservas al
        acceder a la utilización de los servicios y contenidos suministrados en la aplicación
        mi.iosper, que el Instituto de Obra Social de la Provincia de Entre Ríos (en adelante
        &quot;IOSPER&quot;) pone a su disposición en Internet.
      </Typography>

      <Typography variant='h6' component='div' align='justify' paragraph={true}>
        CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN
        CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR MI.IOSPER Y/O LOS
        SERVICIOS.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Capacidad
      </Typography>
      <Typography variant='caption' align='justify' paragraph={true}>
        No podrán ser usuarios de mi.iosper las personas que no tengan capacidad legal para
        contratar.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Titular de los derechos de mi.iosper
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        IOSPER a través de mi.iosper, facilita a los AFILIADOS el acceso y la utilización de
        diversos servicios tales como Expendio de órdenes de consulta, Impresión de cupones de pago,
        entre otros. Estos servicios son meramente enunciativos ya que los mismos pueden ser
        ampliados, como asimismo dados de baja, o ser reemplazados por otros a criterio de IOSPER
        sin necesidad de aviso previo.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Procedimiento de Registro del Afiliado
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        Para obtener su “Número de Afiliado/CUIL” y la “Contraseña/PIN” correspondiente, el AFILIADO
        deberá dirigirse a la delegación más cercana para obtenerlos. Mediante este registro se le
        cargará un correo electrónico el cual podrá ser usado en caso de Olvido de los mismos sin
        necesidad de volver a la delegación.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        Se recomienda que la “Contraseña/PIN” generada sea cambiada una vez ingrese mi.iosper.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        A los fines de una mejor seguridad le recomendamos que utilice contraseñas robustas, se
        entiende por tales a las que tienen por lo menos 8 Dígitos, letras minúsculas, mayúsculas,
        números y símbolos.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        IOSPER NUNCA le solicitará que informe o confirme sus claves o datos a través de un correo
        electrónico ni ningún otro sistema de mensajería por Internet.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        Si Usted detecta cualquier actividad inusual o que le resulte sospechosa por favor
        comuníquese con soporte.sistemas@iosper.gov.ar
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Titular de los derechos de mi.iosper
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        El AFILIADO accederá a la utilización de los servicios ofrecidos por mi.iosper mediante el
        ingreso de su “Número de Afiliado/CUIL” y la “Contraseña/PIN”. El AFILIADO se obliga a
        mantener la confidencialidad de su “Número de Afiliado/CUIL” y la” Contraseña/PIN”, y es
        responsable de todas las actividades desarrolladas con ellas en mi.iosper, cada uno de los
        accesos realizados mediante la misma lo serán por cuenta y riesgo del AFILIADO pues el
        acceso a mi.iosper está restringido al ingreso y uso de su contraseña, de su conocimiento
        exclusivo. El AFILIADO se compromete a notificar a IOSPER en forma inmediata y por medio
        idóneo y fehaciente, cualquier uso no autorizado de su Cuenta.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        El AFILIADO será responsable por el acceso a mi.iosper y entiende y presta conformidad a que
        ingresada su contraseña tendrá acceso a información confidencial, sus datos personales,
        pudiendo efectuar operaciones que le serán atribuidas.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        IOSPER se reserva el derecho de rechazar cualquier solicitud de registración o de cancelar
        una registración previamente aceptada, sin que esté obligado a comunicar o exponer las
        razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Cookies
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        Con la intención de poder ofrecer un mejor servicio, en algunos casos será necesario tener
        habilitadas las cookies para poder utilizar todos los servicios que brinda mi.iosper.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Obligaciones del AFILIADO
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        El AFILIADO se compromete a utilizar mi.iosper de conformidad con la ley, estas Condiciones
        Generales, las leyes, la moral y las buenas costumbres generalmente aceptadas y el orden
        público.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        El AFILIADO se obliga a abstenerse de utilizar el mi.iosper con fines o efectos ilícitos,
        contrarios a lo establecido en estas Condiciones Generales, lesivos a derechos e intereses
        de IOSPER o de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar o
        deteriorar el mi.iosper o impedir la normal utilización o disfrute de mi.iosper por parte de
        los Usuarios.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        Por el hecho de registrarse, el AFILIADO se compromete a entregar información veraz,
        actualizada y completa, la cual será considerada como declaración jurada.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Conducta - Está prohibido
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        a) ingresar a mi.iosper utilizando un nombre falso, erróneo o inexistente, ya sea como
        persona humana o jurídica;
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        b) entregar su Número de Afiliado y la Contraseña - PIN a otra persona para que actúe en su
        nombre;
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        c) almacenar, transmitir o divulgar cualquier tipo de información, datos o materiales que
        violen leyes nacionales, provinciales, regionales o internacionales; o que lesionen de
        cualquier manera derechos de terceros.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        d) efectuar acciones que limiten o impidan a cualquier individuo, grupo, entidad el uso de
        los contenidos, o servicios ofrecidos a través de mi.iosper;
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        e) intentar la violación de los sistemas de autenticación, verificación de identidad y
        seguridad del servidor, redes o cuentas de usuarios.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        f) intentar interrupciones en las comunicaciones de Internet, tales como alterar información
        de ruteo, sobrecargar deliberadamente un servicio.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        g) efectuar cualquier tipo de monitoreo que implique la intercepción de información no
        destinados al AFILIADO;
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        h) enviar o transmitir archivos que contengan virus u otras características destructivas.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        i) acceder, si el AFILIADO ha sido dado de baja en el Padrón de AFILIADOS de IOSPER.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        j) la precedente enumeración es meramente enunciativa, en general está prohibida cualquier
        acción contraria a la ley, la ética y las buenas costumbres.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Bloqueo
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        Si IOSPER detecta violación a estas políticas o alguna conducta informáticamente sospechosa
        en la actividad del AFILIADO dentro del mi.iosper podrá bloquear su acceso hasta tanto se
        aclare la situación como medida preventiva de seguridad.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Monitoreo
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        El AFILIADO acepta que sus actividades dentro de mi.iosper sean monitoreadas a los fines de
        mantener la seguridad informática necesaria de los sistemas de IOSPER y poder realizar
        estadísticas para mejorar los servicios.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Exclusión de Responsabilidad
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        El AFILIADO se obliga a mantener indemne a IOSPER, contra todo daño y/o perjuicio,
        cualquiera fuese su naturaleza, inclusive los gastos que por honorarios de abogados que
        pudieran surgir con motivo u ocasión de cualquier acción o demanda iniciada por un tercero
        como consecuencia del incumplimiento del AFILIADO de cualquiera de las cláusulas del
        presente contrato, o de la violación por el mismo de cualquier ley o derecho de un tercero
        y/o que se puedan devengar como consecuencia de la utilización de mi.iosper en ocasión de
        incumplimiento del usuario de cualquiera de las cláusulas de este contrato.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        El AFILIADO es consciente y acepta voluntariamente que el uso del mi.iosper, de la
        Información y de los Contenidos lo es bajo su única y exclusiva responsabilidad.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Funcionamiento de mi.iosper y Servicios
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        IOSPER contrata su acceso a Internet con un tercero proveedor del mismo. El AFILIADO acepta
        y reconoce que el mi.iosper puede no siempre estar disponible debido a dificultades
        técnicas, eléctricas o fallas de Internet, del proveedor, o por cualquier otro motivo ajeno
        a IOSPER. En consecuencia, este contrato no garantiza la disponibilidad y continuidad del
        funcionamiento del mi.iosper. El afiliado NO podrá imputarle responsabilidad alguna ni
        exigir pago por lucro cesante ni daño emergente, en virtud de perjuicios resultantes de
        dificultades técnicas o fallas en los sistemas o en Internet. IOSPER no será responsable por
        los daños y perjuicios de cualquier naturaleza que sean originados en forma directa,
        indirecta, por la interrupción, suspensión, finalización, falta de disponibilidad o de
        continuidad del funcionamiento del mi.iosper, por la defraudación de la utilidad que los
        usuarios hubieren podido atribuir al mi.iosper.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        IOSPER no se responsabiliza por cualquier daño, perjuicio o pérdida en el equipo del
        AFILIADO originado por fallas en el sistema, eléctrica, servidor o en Internet, tampoco será
        responsable por cualquier virus que pudiera infectar el equipo del AFILIADO como
        consecuencia del acceso, uso o examen de mi.iosper web o a raíz de cualquier transferencia
        de datos, archivos, imágenes, textos, o audio contenidos en el mismo.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        El AFILIADO acepta y entiende que el uso y/o interpretación de la información brindada en
        este mi.iosper y las decisiones que se tomen en razón de las mismas, son realizadas
        enteramente bajo su propio riesgo. En consecuencia, queda expresamente aclarado que las
        decisiones a que el AFILIADO como usuario arribe son producto de sus facultades
        discrecionales.
      </Typography>

      <Typography variant='h6' component='div' align='justify' paragraph={true}>
        CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN
        CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL MI.IOSPER Y/O LOS
        SERVICIOS.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Condiciones de Utilización del MI.IOSPER
      </Typography>

      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        IOSPER se reserva el derecho, en cualquier momento y sin necesidad de darle notificación
        previa, de denegar, retirar el acceso al mi.iosper, o dar por concluido su calidad de
        usuario del mi.iosper por violación de las condiciones generales aquí descriptas.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Confidencialidad
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        Para la utilización de los servicios los AFILIADOS deberán facilitar determinados datos de
        carácter personal. Su información personal se procesa y almacena en servidores o medios
        magnéticos que mantienen altos estándares de seguridad y protección tanto física como
        tecnológica. Para mayor información sobre la privacidad de los Datos Personales y casos en
        los que será revelada la información personal, se pueden consultar nuestras Políticas de
        Privacidad.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Comunicación
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        Toda notificación u otra comunicación que deba efectuarse bajo el presente, deberá
        realizarse por escrito: al AFILIADO: al domicilio informado por el usuario o a la cuenta de
        correo electrónico declarada. A IOSPER: a la cuenta de correo electrónico
        soporte.sistemas@iosper.gov.ar o al domicilio sito en calle Andrés Pazos 243 de la ciudad de
        Paraná.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Propiedad Intelectual
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        Todos los contenidos de este mi.iosper, incluyendo, sin carácter limitativo, los textos,
        gráficos, logos, iconos, imágenes, archivos de audio y video, software y todas y cada una de
        las características que se encuentran en el mi.iosper son propiedad exclusiva de IOSPER y/o
        de sus proveedores de Contenidos, y los mismos están protegidos por las leyes de propiedad
        intelectual. Su uso indebido, así como su reproducción serán objeto de las acciones
        judiciales que correspondan.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        La utilización de los servicios brindados por IOSPER no podrá, en ningún supuesto, ser
        interpretada como una autorización y/o concesión de licencia de los derechos intelectuales
        de que goza.
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        Se recomienda releer periódicamente estas Condiciones de Uso porque pueden ser modificadas
        unilateralmente por IOSPER, quien se reserva el derecho de realizar modificaciones para
        adecuar esta política de uso a la legislación vigente o a las necesidades y finalidades del
        servicio.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Jurisdicción y Derecho Aplicable
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        Para cualquier disputa que surja de la interpretación del presente, las partes se someten en
        principio a la Justicia Ordinaria de los Tribunales de la ciudad de Paraná renunciando al
        fuero federal. El Derecho aplicable es el de la República Argentina.
      </Typography>

      <Typography variant='h6' paragraph={true}>
        Modificaciones de los Términos y Condiciones de Uso de mi.iosper
      </Typography>
      <Typography variant='caption' align='justify' component='div' paragraph={true}>
        Estos Términos y Condiciones de Uso de mi.iosper pueden modificarse periódicamente, esta es
        la versión 1.0 y se ha puesto en vigencia a partir del día 09/06/2022
      </Typography>
    </Box>
  );
};

export default TerminosCondiciones;
