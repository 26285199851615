import React from 'react';
import { Box, LinearProgress, linearProgressClasses, styled } from '@mui/material';
import { useInterceptorStore } from '../../services/Axios/store/hooks';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#b12823',
  },
}));

const LoaderEngine = () => {
  const [state] = useInterceptorStore();
  const [showLoader, setShowLoader] = React.useState(false);

  React.useEffect(() => {
    setShowLoader(state.interceptorRunning);
  }, [state.interceptorRunning]);

  return (
    <Box sx={{ width: '100%', color: '#b12823' }}>
      <BorderLinearProgress sx={{ visibility: showLoader ? 'visible' : 'hidden' }} />
    </Box>
  );
};

export default function Loader() {
  return <LoaderEngine />;
}
