import './App.css';
import './services/Axios/Config';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './components/Auth/User';
import { AfiliadosProvider } from './components/Afiliados/AfiliadosProvider';
import { InfoAfiliadoProvider } from './components/Afiliados/InfoAfiliadoProvider';
import Layouts from './layouts';
import InterceptorProvider from './services/Axios/store/InterceptorProvider';
import { SnackbarProvider } from 'notistack';
import VersionControlProvider from './@iosper/components/VersionControl/store/VersionControlProvider';
import { Helmet } from 'react-helmet';

const App = () => {
  return (
    <>
      <Helmet>
        {process.env.REACT_APP_STAGE !== 'production' && (
          <meta name='robots' content='noindex, nofollow' />
        )}
      </Helmet>
      <UserProvider>
        <InterceptorProvider>
          <VersionControlProvider>
            <SnackbarProvider>
              <AfiliadosProvider>
                <InfoAfiliadoProvider>
                  {/* <Box> */}
                  <BrowserRouter>
                    <CssBaseline />
                    <Layouts />
                  </BrowserRouter>
                  {/* </Box> */}
                </InfoAfiliadoProvider>
              </AfiliadosProvider>
            </SnackbarProvider>
          </VersionControlProvider>
        </InterceptorProvider>
      </UserProvider>
    </>
  );
};
export default App;
