import { TextField } from '@mui/material';

const NumberTextInput = ({
  fieldName,
  label,
  autoComplete,
  register,
  errors,
  variant,
  required,
  value,
}) => {
  return (
    <div>
      <TextField
        variant={variant}
        fullWidth
        name={fieldName}
        autoComplete={autoComplete}
        label={label}
        type='number'
        defaultValue={value}
        id={fieldName}
        {...register(fieldName, {
          required: {
            value: required,
            message: 'Debe ingresar ' + label,
          },
        })}
      />
      {errors[fieldName] && <p style={{ color: 'red' }}>{errors[fieldName].message}</p>}
    </div>
  );
};
NumberTextInput.defaultProps = {
  required: true,
  variant: 'standard',
  autoComplete: 'on',
};
export default NumberTextInput;
