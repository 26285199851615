import { useAfiliados } from '../Afiliados/AfiliadosProvider';
import AfiliadoCard from '../Afiliados/AfiliadoCard';
import { Card, CardHeader, Grid, Typography } from '@mui/material';
import ButtonBack from '../Breadcrumb/ButtonBack';

const GrupoFamiliar = () => {
  const { afiliados } = useAfiliados();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonBack />
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ width: '100%' }}>
            <CardHeader
              sx={{ padding: '8px 16px 4px' }}
              title={
                <Typography gutterBottom variant='h5' component='h2'>
                  Grupo Familiar
                </Typography>
              }
            />
            <Typography variant='string' component={'div'} sx={{ padding: '8px 25px 4px' }}>
              Acá podrás consultar los datos personales y de contacto de tu grupo familiar y además
              podrás actualizarlos.
            </Typography>
          </Card>
        </Grid>

        {afiliados?.map((item, key) => (
          <Grid key={key} item xl={3} lg={4} sm={6} xs={12}>
            <AfiliadoCard afiliado={item} position={key} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default GrupoFamiliar;
