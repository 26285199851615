import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo-small.png';
import { useUser } from '../../components/Auth/User';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import {
  AppBar,
  Avatar,
  Box,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material';
import {
  BottomMenuList,
  SideMenuList,
  TopMenuList,
  SideMenuListNoCapitado,
  SideMenuListCapitado,
  BottomMenuListAyuda,
} from '../../routes/Menu';
import { Link } from 'react-router-dom';
import TopMenu from './components/TopMenu';
import VerifiedIcon from '@mui/icons-material/Verified';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import { useInfoAfiliado } from '../../components/Afiliados/InfoAfiliadoProvider';
import Loader from '../Loader/Loader';
import ButtonDownloadPWA from '../../@iosper/components/PWA/ButtonDownloadPWA';
import { useVersionControlStore } from '../../@iosper/components/VersionControl/store/hooks';
import { ButtonDownloadPWAiOS } from '../../@iosper/components/PWA/ButtonDownloadPWAiOS';
import { InfoRounded } from '@mui/icons-material';
import { EtiquetaAmbiente } from './components/EtiquetaAmbiente';
import { NotificacionList } from './components/NotificacionList';

const drawerWidth = 260;

const DrawerButtonMenu = ({ open, icon, iconColor, text, textColor, ...props }) => {
  if (props.children) {
    return props.children;
  }

  return (
    <ListItem sx={{ minHeight: 48 }}>
      <ListItemIcon title={text ? text : ''} sx={{ color: iconColor }}>
        {icon || ''}
      </ListItemIcon>
      <ListItemText>
        <Typography variant='button' gutterBottom color={textColor} fontWeight='600'>
          {text || ''}
        </Typography>
      </ListItemText>
    </ListItem>
  );
};

const Menu = (props) => {
  const { window } = props;
  const { user } = useUser();
  const { infoAfiliado } = useInfoAfiliado();
  const { state } = useVersionControlStore();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [esCapitado, setCapitado] = useState(false);

  const colorVersion = () => {
    return state.isUptodate ? '#00615C' : '#B12823';
  };

  useEffect(() => {
    if (infoAfiliado) {
      // Si trae la fecha de venicmiento es un afiliado capitado
      setCapitado(infoAfiliado != null && infoAfiliado.tipoAfiliado.fechaVencimientoCapita != null);
    } else {
      setCapitado(false);
    }
  }, [infoAfiliado]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!user) {
    return (
      <Box sx={{ display: 'flex' }}>
        <AppBar
          position='fixed'
          sx={{
            ml: { md: `${drawerWidth}px` },
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Loader id='headerLoader' />
        </AppBar>
        <Box
          component='main'
          sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          {props.children}
        </Box>
      </Box>
    );
  }

  const drawMenuList = (list) => {
    return list.map((item) => (
      <MenuItem
        key={item.key}
        component={Link}
        to={item.to}
        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={(e) => setMobileOpen(false)}
      >
        <ListItemIcon sx={{ color: '#B12823' }}>{item.icon()}</ListItemIcon>
        {item.label}
      </MenuItem>
    ));
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />

      <MenuList id='nav' aria-label='Menú lateral de navegación del sitio Mi-iosper.' tabIndex='0'>
        {drawMenuList(SideMenuList)}
        {esCapitado ? drawMenuList(SideMenuListCapitado) : drawMenuList(SideMenuListNoCapitado)}
        {drawMenuList(BottomMenuListAyuda)}
        <Divider />
        {drawMenuList(BottomMenuList)}
      </MenuList>
      <Box
        sx={{ width: '100%', display: 'flex', alignItems: 'flex-end', marginTop: { md: '20px' } }}
      >
        <List sx={{ width: '100%' }}>
          <ButtonDownloadPWA>
            <DrawerButtonMenu
              icon={<InstallDesktopIcon />}
              text='Instalar Aplicación'
              iconColor='#005A55'
              textColor='#005A55'
            />
          </ButtonDownloadPWA>
          <ButtonDownloadPWAiOS>
            <DrawerButtonMenu
              icon={<InfoRounded />}
              text='¿Cómo instalar mi.iosper?'
              iconColor='#005A55'
              textColor='#005A55'
            />
          </ButtonDownloadPWAiOS>
          <DrawerButtonMenu
            icon={<VerifiedIcon />}
            text={`Version ${process.env.REACT_APP_MI_IOSPER_VERSION} ${
              process.env.REACT_APP_MI_IOSPER_VERSION_BETA_TEXT || ''
            }`}
            iconColor={colorVersion}
            textColor={colorVersion}
          />
        </List>
      </Box>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      {/* <CssBaseline /> */}
      <AppBar
        position='fixed'
        sx={{
          ml: { md: `${drawerWidth}px` },
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Link
            to='/'
            style={{
              textDecoration: 'none',
              color: 'white',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Avatar alt='' src={logo} />
            <Typography variant='h6' noWrap component='div'>
              Mi IOSPER
              <Typography mx={1} variant='overline' gutterBottom={false}>
                {process.env.REACT_APP_MI_IOSPER_VERSION_BETA_TEXT || ''}
              </Typography>
            </Typography>
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          <EtiquetaAmbiente />
          <NotificacionList />
          <Box sx={{ mr: 1 }} />
          <TopMenu list={TopMenuList} />
        </Toolbar>
        <Loader id='headerLoader' />
      </AppBar>
      <Box component='nav' sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Container
        id='main'
        component='main'
        sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` } }}
        maxWidth='lg'
      >
        <Toolbar />
        {props.children}
      </Container>
    </Box>
  );
};

export default Menu;
