import axios from 'axios';

export const getVersionActual = async (data, url = '/v1/app/version') => {
  return axios
    .get(url + '/' + data.idAplicacion, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
