import React from 'react';
import ModalBase from '../../Controls/ModalBase';
import { Box, Button, Grid, Typography } from '@mui/material';

const PinReseteado = React.forwardRef((props, ref) => {
  const { open, setOpen, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  const form = (
    <div>
      <Grid container direction='column' justifyContent='center' alignItems='center' spacing={1}>
        <Grid item>
          <Typography align='center' variant='body2' color='textSecondary'>
            Su contraseña ha sido reestablecida con éxito
          </Typography>
          <br />
        </Grid>
      </Grid>
      <Grid container direction='column' justifyContent='flex-end' alignItems='flex-end'>
        <Grid item>
          <Button size='small' variant='contained' color='primary' onClick={(e) => handleClose(e)}>
            Aceptar
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ModalBase
        open={open}
        setOpen={setOpen}
        content={form}
        title='Recuperar mi Contraseña - PIN'
        onClose={handleClose}
      />
    </Box>
  );
});

export default PinReseteado;
