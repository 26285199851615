import React, { useState } from 'react';
import {
  ToggleButton,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Card,
  Alert,
  CardContent,
  Grid,
  TextField,
  Typography,
  FormControl,
  Stack,
  CardActions,
  Link,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { findProfesionales } from '../../services/Busquedas/Prestadores';
import LoadingButton from '../../@iosper/components/LoadingButton';
import { useForm } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import {
  HighlightOff,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LocalPhone,
  FmdGood,
} from '@mui/icons-material';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { useWidth } from '../../@iosper/utils/UseWidth';
import EspecialidadAutocomplete from './EspecialidadAutocomplete';
import LocalidadAutocomplete from '../Common/LocalidadAutocomplete';
import ButtonBack from '../Breadcrumb/ButtonBack';

const BuscarPrestador = () => {
  const [loading, setLoading] = useState(false);
  const [alertResultFind, setAlertResultFind] = useState('');
  const [error, setError] = useState('');
  const [prestadores, setPrestadores] = useState([]);
  const [visibleFind, setVisibleFind] = useState(true);
  const screenWidth = useWidth();
  const [selectedEspecialidad, setSelectedEspecialidad] = useState(null);
  const [selectedLocalidad, setSelectedLocalidad] = useState(null);
  const [keyComponentEspecialidad, setKeyComponentEspecialidad] = useState(0);
  const [keyComponentLocalidad, setKeyComponentLocalidad] = useState(0);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const find = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    setAlertResultFind('');
    setError('');

    if (
      data.matricula === '' &&
      data.nombreApellido === '' &&
      selectedEspecialidad === null &&
      selectedLocalidad === null
    ) {
      setError('Debes ingresar por lo menos un criterio para realizar la búsqueda');
      setLoading(false);
    } else {
      const result = await findProfesionales({
        matricula: data.matricula,
        nombreApellido: data.nombreApellido,
        soloActivos: true,
        idLocalidad: selectedLocalidad?.localidadVO.idLocalidad,
        idEspecialidad: selectedEspecialidad?.especialidadVO.idEspecialidad,
      });
      if (result && result.length > 0) {
        setPrestadores(result);
        setVisibleFind(false);
      } else {
        setAlertResultFind('No se han encontrado prestadores');
      }
      setLoading(false);
    }
  };

  const clean = () => {
    setKeyComponentEspecialidad(1 + Math.random() * (1000 - 1));
    setKeyComponentLocalidad(1 + Math.random() * (1000 - 1));
    setSelectedEspecialidad(null);
    setSelectedLocalidad(null);
    reset();
  };

  const handleBuscarOtro = () => {
    setPrestadores([]);
    setVisibleFind(true);
    clean();
  };

  const DataCollapseDrawer = (props) => {
    const { prestador } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <Box sx={{ width: '100%' }}>
        <ToggleButton
          aria-label='expand row'
          value='check'
          onChange={() => {
            setOpen(!open);
          }}
          sx={{ border: 0, p: 0 }}
          fullWidth={true}
        >
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={2}
            flexWrap='nowrap'
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            <Typography
              variant='body1'
              align='left'
              gutterBottom={false}
              sx={{ color: '#000', textTransform: 'none' }}
            >
              {`${prestador.nombre || ''}`}
            </Typography>
          </Grid>
        </ToggleButton>

        <Collapse in={open} timeout='auto' unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <List>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Matrícula - ${prestador?.matricula || ''}`} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary='Especialidad' secondary={`${prestador?.descripcionTipo}`} />
              </ListItem>
              {prestador?.domicilio && (
                <ListItem
                  component='a'
                  href={`https://www.google.com/maps/search/${encodeURIComponent(
                    prestador?.domicilio + ', ' + prestador?.localidad,
                  )}`}
                  sx={{ py: 0 }}
                >
                  <ListItemIcon sx={{ color: '#B12823' }}>
                    <FmdGood />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ margin: -3 }}
                    primary={
                      <Typography
                        variant='body2'
                        sx={{ color: '#005a55', textDecoration: 'underline' }}
                      >
                        {`${prestador?.domicilio || ''}, ${prestador?.localidad || ''}`}
                      </Typography>
                    }
                  />
                </ListItem>
              )}
              {prestador?.telefonos.length > 0 && (
                <div>
                  {prestador?.telefonos?.map((i, k) => (
                    <ListItem component='a' href={`tel:${i || ''}`} sx={{ py: 1 }}>
                      <ListItemIcon sx={{ color: '#B12823' }}>
                        <LocalPhone />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ margin: -3 }}
                        primary={
                          <Typography
                            variant='body2'
                            sx={{ color: '#005a55', textDecoration: 'underline' }}
                          >
                            {`${i || ''}`}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </div>
              )}
            </List>
          </Box>
        </Collapse>
      </Box>
    );
  };

  /// -------------------- DATA GRID PRESTADORES --------------------------
  const mapPrestadorToRow = (p) => ({
    id: p.idPrestador,
    idPrestador: p.idPrestador,
    idProfesional: p.idProfesionalPrestador,
    codigoEstado: p.codigoEstado,
    colorFondo: p.colorFondo,
    colorLetra: p.colorLetra,
    matricula: p.matricula,
    descripcionTipo: p.descripcionTipo,
    nombre: p.nombre,
    domicilio: p.domicilio,
    localidad: p.localidad,
    telefonos: p.telefonos,
    telefono: p.telefonos.join(', '),
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: 'Prestadores',
      flex: 1,
      renderCell: (a) => <DataCollapseDrawer prestador={a.row} />,
    },
    { field: 'matricula', headerName: 'Matrícula', width: 120 },
    { field: 'descripcionTipo', headerName: 'Especialidad', flex: 0.1 },
    { field: 'nombre', headerName: 'Nombre', flex: 0.1 },
    { field: 'telefono', headerName: 'Teléfono', flex: 0.1 },
    { field: 'domicilio', headerName: 'Domicilio', flex: 0.1 },
    { field: 'localidad', headerName: 'Localidad', flex: 0.1 },
  ];

  const handlerSelectEspecialidad = (especialidad) => {
    setSelectedEspecialidad(especialidad);
  };

  const handlerSelectLocalidad = (localidad) => {
    setSelectedLocalidad(localidad);
  };

  const handleBorrarMensaje = () => {
    setError('');
    setAlertResultFind('');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ButtonBack marginBotton='1' variant='text' size='small'></ButtonBack>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Buscar Profesional
            </Typography>
            <Typography variant='string' component={'div'}>
              Acá podrás consultar los profesionales prestadores, ingresando alguno de los
              siguientes criterios de búsqueda.
            </Typography>
          </CardContent>

          {visibleFind && (
            <form>
              <CardContent sx={{ py: 0 }}>
                <FormControl fullWidth={true} variant='outlined'>
                  <TextField
                    variant='standard'
                    type='number'
                    {...register('matricula', {
                      maxLength: {
                        value: 5,
                        message: 'El campo matrícula es demasiado largo',
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'El campo matrícula debe contener sólo números',
                      },
                    })}
                    fullWidth
                    margin='normal'
                    name='matricula'
                    label='Matrícula'
                  />
                  {errors['matricula'] && (
                    <p style={{ color: 'red' }}>{errors['matricula'].message}</p>
                  )}
                </FormControl>
                <FormControl fullWidth={true} variant='outlined'>
                  <TextField
                    variant='standard'
                    type='text'
                    fullWidth
                    margin='normal'
                    name='nombreApellido'
                    label='Nombre'
                    {...register('nombreApellido', {
                      maxLength: {
                        value: 200,
                        message: 'El campo nombre es demasiado largo',
                      },
                      pattern: {
                        value: /^(([a-zñA-ZÑ. ]{1,60}))$/,
                        message: 'El campo nombre debe contener solo letras',
                      },
                    })}
                  />
                  {errors['nombreApellido'] && (
                    <p style={{ color: 'red' }}>{errors['nombreApellido'].message}</p>
                  )}
                </FormControl>

                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <EspecialidadAutocomplete
                    key={keyComponentEspecialidad}
                    onSelectEspecialidad={handlerSelectEspecialidad}
                  />
                </FormControl>

                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <LocalidadAutocomplete
                    key={keyComponentLocalidad}
                    onSelectLocalidad={handlerSelectLocalidad}
                  />
                </FormControl>
              </CardContent>

              <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2 }}
                  justifyContent={{ xs: 'center', sm: 'flex-end' }}
                  alignItems={{ xs: 'strech' }}
                >
                  <Button
                    variant='contained'
                    size='medium'
                    startIcon={<HighlightOff />}
                    color='neutral'
                    onClick={clean}
                  >
                    Limpiar
                  </Button>
                  <LoadingButton
                    fullWidth={true}
                    type='submit'
                    variant='contained'
                    color='primary'
                    loading={loading ? 'show' : 'hide'}
                    content={'Buscar'}
                    startIcon={<SearchIcon />}
                    onClick={handleSubmit(find)}
                  />
                </Stack>
              </CardActions>
            </form>
          )}

          {!visibleFind && (
            <Grid container direction='column' justifyContent='space-between' spacing={1}>
              <Grid item>
                <div style={{ height: '50vh', width: '100%' }}>
                  <DataGrid
                    sx={{
                      border: 0,
                      '& .MuiDataGrid-cell': {
                        py: '8px',
                      },
                    }}
                    loading={!prestadores.length}
                    rows={prestadores.map((a) => mapPrestadorToRow(a))}
                    columns={columns}
                    getRowHeight={() => 'auto'}
                    getEstimatedRowHeight={() => 200}
                    columnVisibilityModel={{
                      desplegable: screenWidth === 'xs',
                      matricula: screenWidth !== 'xs',
                      telefono: screenWidth !== 'xs',
                      descripcionTipo: screenWidth !== 'xs',
                      nombre: screenWidth !== 'xs',
                      domicilio: screenWidth !== 'xs',
                      localidad: screenWidth !== 'xs',
                    }}
                    pagination
                    autoPageSize
                    disableColumnMenu
                  />
                </div>
              </Grid>
              <Grid item>
                <Grid container justifyContent='flex-end' spacing={1}>
                  <Grid item>
                    {' '}
                    <Button
                      variant='contained'
                      color='neutral'
                      startIcon={<SearchIcon />}
                      onClick={() => handleBuscarOtro()}
                    >
                      Buscar otro
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
      <CustomizedSnackbars
        open={alertResultFind}
        severity='info'
        message={alertResultFind}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default BuscarPrestador;
