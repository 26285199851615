import { useEffect, useState } from 'react';
import { Close as CloseIcon, Notifications as NotificationsIcon } from '@mui/icons-material';
import {
  Badge,
  Box,
  Container,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNotificaciones } from '../hooks/useNotificaciones';
import { NotificacionItem } from './NotificacionItem';
import { deshabilitarNotificacion } from '../../../services/Afiliado/Afiliado';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';

export const NotificacionList = () => {
  var { notificacionesList } = useNotificaciones();
  const [anchorEl, setAnchorEl] = useState(null);
  const [update, setUpdate] = useState(false);
  const history = useHistory();
  const [notificacionesActualizadas, setNotificacionesActualizadas] = useState([]);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (notificacionesActualizadas?.length > 0 || update) {
      setNotificacionesActualizadas(notificacionesActualizadas);
      setUpdate(true);
    } else {
      setNotificacionesActualizadas(notificacionesList);
    }
  }, [notificacionesList, notificacionesActualizadas]);

  // Function to delete an item from the menu
  const deleteItem = (itemToDelete) => {
    const updatedMenuItems = notificacionesActualizadas.filter((item) => item !== itemToDelete);
    setNotificacionesActualizadas(updatedMenuItems);
  };

  const onMenuItemClick = async (event, index) => {
    setAnchorEl(null);
    const res = await deshabilitarNotificacion(index);
    if (res) deleteItem(index);

    if (index.codigoTipoNotificacion === 'RecetaMedicaIfx') {
      //console.log('redirect para ver Providencia');
      history.push({
        pathname: '/ver-receta-cronica/' + JSON.stringify(index.idSolicitud),
        state: { notificacion: index } || {}, // your data array of objects
      });
    } else {
      history.push({
        pathname: '/autorizaciones',
        state: {}, // your data array of objects
      });
    }

    //Dejo código por si en algún momento, según el tipo de notificación, redirigimos a la visualización (por el momento redirigimos a la vista de autorizaciones)
    /*if(index.tipoNotificacion === 'PROVIDENCIA'){
      //console.log('redirect para ver Providencia');
      history.push({
        pathname: '/ver-providencia/' + JSON.stringify(index.numeroNotificacion) ,
        state: { notificacion: index } || {}, // your data array of objects
      });
    } else {
      //console.log('redirect para ver Nota Afiliado');
      history.push({
        pathname: '/ver-nota-afiliado/' + JSON.stringify(index.numeroNotificacion),
        state: { notificacion: index } || {}, // your data array of objects
      });      
    }*/
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title='Notificaciones'>
        <IconButton
          size='large'
          edge='end'
          aria-label='Menú de notificaciones.'
          onClick={handleClick}
          sx={{ ml: { xs: 0, md: 2 } }}
          aria-controls={open ? 'notificaciones-menu-navbar' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          color='inherit'
        >
          <Badge color='error' badgeContent={notificacionesActualizadas?.length}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        id='notificaciones-menu-navbar'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            right: 18,
            '& .MuiAvatar-root': {
              width: '100%',
              maxWidth: 200,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Container tabIndex={-1} maxWidth='xl'>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant='h5'>Notificaciones</Typography>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <IconButton tabIndex={-1} aria-label='close' onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>

        <Divider />

        <MenuList
          autoFocus={false}
          autoFocusItem={true}
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: { xs: 'calc(100vh - 132px)', md: 'calc(100vh - 200px)' },
            marginBottom: '8px',
            '& ul': { padding: 0 },
          }}
        >
          {notificacionesActualizadas?.length < 1 && (
            <Box m={2}>
              <Typography variant='body2' color='text.secondary' style={{ textWrap: 'wrap' }}>
                No se encontraron notificaciones.
              </Typography>
            </Box>
          )}
          {notificacionesActualizadas?.map((item, k) => {
            return (
              <MenuItem
                key={item.numeroNotificacion}
                onClick={(event) => {
                  onMenuItemClick(event, item);
                }}
              >
                <NotificacionItem {...item} />
              </MenuItem>
            );
          })}
        </MenuList>
      </Popover>
    </>
  );
};
