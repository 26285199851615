import { axiosGet } from '../ServiceBase.js';

export const getEspecialidades = async () => {
  try {
    const especialidades = JSON.parse(sessionStorage.getItem('especialidades'));
    if (especialidades == null) {
      const especialidades = await axiosGet('/v1/busquedas/especialidades');
      sessionStorage.setItem('especialidades', JSON.stringify(especialidades));
      return especialidades;
    } else {
      return especialidades;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
