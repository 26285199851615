import React from 'react';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import {
  Alert,
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  ToggleButton,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useWidth } from '../../@iosper/utils/UseWidth';

const DataCollapseDrawer = (props) => {
  const { derivacion } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <Typography
            variant='body2'
            align='left'
            gutterBottom={false}
            sx={{ color: '#000', textTransform: 'none' }}
          >
            {derivacion.numero + ' ' + derivacion.fecha}
          </Typography>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <List>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Prestador' secondary={derivacion.prestador} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Diagnóstico' secondary={derivacion.diagnostico} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Tratamiento' secondary={derivacion.tratamiento} />
            </ListItem>
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const DerivacionesTable = ({ derivaciones }) => {
  const screenWidth = useWidth();

  /// -------------------- DATA GRID CONSUMOS --------------------------
  const mapConsumoToRow = (derivacion, index) => ({
    id: index,
    numero: derivacion.numeroDerivacion,
    fecha: derivacion.fechaDerivacion,
    prestador: derivacion.nombrePrestador,
    diagnostico: derivacion.diagnostico,
    tratamiento: derivacion.tratamiento,
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: 'Número derivación',
      flex: 1,
      renderCell: (a) => <DataCollapseDrawer derivacion={a.row} />,
    },
    { field: 'numero', headerName: 'Número derivación', flex: 1, width: 120 },
    { field: 'fecha', headerName: 'Fecha', flex: 1 },
    { field: 'prestador', headerName: 'Prestador', flex: 1 },
    { field: 'diagnostico', headerName: 'Diagnóstico', flex: 1 },
    { field: 'tratamiento', headerName: 'Tratamiento', flex: 1 },
  ];

  return derivaciones?.length ? (
    <div style={{ width: '100%' }}>
      <DataGrid
        sx={{
          border: 1,
          '& .MuiDataGrid-cell': {
            py: '8px',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#F2F5F5',
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        autoHeight={true}
        loading={!derivaciones.length}
        rows={derivaciones.map((a, index) => mapConsumoToRow(a, index))}
        columns={columns}
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 200}
        columnVisibilityModel={{
          desplegable: screenWidth === 'xs',
          numero: screenWidth !== 'xs',
          fecha: screenWidth !== 'xs',
          prestador: screenWidth !== 'xs',
          diagnostico: screenWidth !== 'xs',
          tratamiento: screenWidth !== 'xs',
        }}
        pagination={screenWidth === 'xs' ? null : true}
        disableColumnMenu
      />
    </div>
  ) : (
    <Alert severity='info'>No se han encontrado resultados</Alert>
  );
};

export default DerivacionesTable;
