import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { Box, Button, FormControl, Grid, Stack, TextField } from '@mui/material';
import { Delete, HighlightOff } from '@mui/icons-material';
import LoadingButton from '../../@iosper/components/LoadingButton';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { anularAutorizacion } from '../../services/Afiliado/Autorizaciones';
import ModalBase from '../Controls/ModalBase';

const AnularAutorizacionPopUp = (props) => {
  const [datos, setDatos] = useState({
    id: props.autorizacion?.id,
    observacion: null,
  });

  const [success, setSuccess] = useState('');
  const [loadingAnular, setLoadingAnular] = useState(false);

  useEffect(() => {}, []);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const onAnularAutorizacion = async (data, event) => {
    event.preventDefault();
    setLoadingAnular(true);
    try {
      setLoadingAnular(true);
      const res = await anularAutorizacion(datos);
      if (res) {
        setSuccess('La autorización ha sido anulada correctamente');
        setLoadingAnular(false);
        props.setAutorizacionAnulada(true);
      }
    } catch (e) {
      setLoadingAnular(false);
    }
  };

  const handleClose = () => {
    setLoadingAnular(false);
    props.setAutorizacionAnulada(false);
    reset();
    setDatos({
      id: props.autorizacion.id,
      observacion: null,
    });
  };

  const handleBorrarMensaje = () => {
    setSuccess('');
  };

  const DrawerForm = (
    <form onSubmit={handleSubmit(onAnularAutorizacion)}>
      <FormControl fullWidth={true} variant='outlined' margin='normal'>
        <TextField
          multiline
          maxRows={4}
          {...register('observacion', {
            required: {
              value: true,
              message: 'El campo es requerido',
            },
            maxLength: {
              value: 500,
              message: 'El campo es demasiado largo',
            },
          })}
          variant='standard'
          fullWidth
          name='observacion'
          label='Ingresá la observación'
          id='observacion'
          value={datos.observacion}
          onChange={(newValue) => {
            setDatos({ ...datos, observacion: newValue.target.value });
          }}
        />
        {errors.observacion && <p style={{ color: 'red' }}>{errors.observacion.message}</p>}
      </FormControl>

      <Box sx={{ width: '100%', padding: '10px' }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2 }}
          justifyContent={{ xs: 'center', sm: 'flex-end' }}
        >
          <Button
            variant='contained'
            startIcon={<HighlightOff />}
            color='neutral'
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <LoadingButton
            fullWidth={true}
            variant='contained'
            size='medium'
            color='primary'
            startIcon={<Delete />}
            type='submit'
            loading={loadingAnular ? 'show' : 'hide'}
            content={'Anular'}
          />
        </Stack>
      </Box>
    </form>
  );

  const popUpAnular = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography gutterBottom variant='h6'>
          ¿Estás seguro que deseas anular la autorización?
        </Typography>
        {DrawerForm}
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <ModalBase
        fullWidth={false}
        open={true}
        content={popUpAnular}
        title='Anular Autorizacion'
        setAutorizacionAnulada={props.setAutorizacionAnulada}
        //onClose={handleClose}
      />
      <CustomizedSnackbars
        open={success}
        autoHideDuration={8000}
        severity='success'
        message={success}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Box>
  );
};

export default AnularAutorizacionPopUp;
