import React from 'react';
import ModalBase from '../../Controls/ModalBase';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import LoadingButton from '../../../@iosper/components/LoadingButton';

const SinCorreo = React.forwardRef((props, ref) => {
  const { open, setOpen, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  const form = (
    <div>
      <Grid container direction='column' justifyContent='center' alignItems='center' spacing={1}>
        <Grid item>
          <Typography align='center' variant='body2' color='textSecondary'>
            No hay ningún correo electrónico asociado a su cuenta
          </Typography>
          <br />
          <Typography align='center' variant='body2' color='textSecondary'>
            Para poder continuar deberás agregar uno.
          </Typography>
          <br />
        </Grid>
      </Grid>
      <Grid container direction='column' justifyContent='flex-end' alignItems='flex-end'>
        <Grid item>
          <LoadingButton
            component={Link}
            to={'/validar-identidad/agregar-correo/recuperar-pin'}
            fullWidth={true}
            color='primary'
            variant='contained'
            content={'Agregar'}
            size='small'
          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ModalBase
        open={open}
        setOpen={setOpen}
        content={form}
        title='Recuperar mi Contraseña - PIN'
        onClose={handleClose}
      />
    </Box>
  );
});

export default SinCorreo;
