import { axiosPost, axiosPostFile } from '../ServiceBase.js';

/**
 * Busca los datos del cupon de capita para mostrar en la vista previa
 * @returns
 */
export const getCuponCapita = async (data) => {
  return axiosPost('/v1/afiliados/cupon-capita', data);
};

/**
 * Imprimir cupon api call
 * @returns
 */
export const getImprimirCapita = (data) => {
  return axiosPostFile('/v1/afiliados/cupon-capita-pdf', {
    numeroDocumento: data.numeroDocumento,
    tipoDocumento: data.tipoDocumento,
    recibo: false,
    fechaDeImpresion: null,
  });
};
