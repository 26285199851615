import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const CardButton = ({ title, description, to, image }) => {
  return (
    <Card>
      <CardActionArea
        sx={{ borderRadius: '10px' }}
        component={Link}
        to={to || '/'}
        aria-label={title}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <CardMedia
            component='img'
            sx={{ m: '16px', height: '85px', width: '85px', objectFit: 'contain' }}
            image={image}
            alt={`imagen de ${title}`}
          />
          <CardContent>
            {title && (
              <Typography gutterBottom variant='h6' component='h2' sx={{ fontWeight: 'bold' }}>
                {title}
              </Typography>
            )}
            {description && (
              <Typography variant='body2' color='textSecondary' component='p'>
                {description}
              </Typography>
            )}
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default CardButton;
