import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export const ConfirmacionAccionModal = (props) => {
  const { title, message, onClose, open, ...other } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog maxWidth='sm' fullWidth open={open} {...other}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {message && <DialogContent dividers>{message}</DialogContent>}
      <DialogActions>
        <Button variant='contained' color='neutral' size='medium' autoFocus onClick={handleCancel}>
          Cancelar
        </Button>
        <Button variant='contained' color='primary' size='medium' onClick={handleOk}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
