import { useEffect, useState } from 'react';
import { agregarDomicilio, tipoNumeracion } from '../../../../services/Common/Domicilio';

const WARNING = 'warning';
const ERROR = 'error';

const getItemsDomicilio = (items = []) => {
  const result = {};
  items.map((i) => {
    result[tipoNumeracion[i.codigoTipoNumeracion].name] = i;
  });

  return result;
};

export const useDomicilio = (infoAfiliado = null) => {
  const [message, setMessage] = useState({ status: '', message: '' });
  const [data, setData] = useState();

  useEffect(() => {
    if (infoAfiliado) {
      setData({
        afiliado: infoAfiliado?.afiliado,
        domicilio: infoAfiliado?.domicilioPersonaFisica,
        provincia: infoAfiliado?.provincia,
        departamento: infoAfiliado?.departamento,
        localidad: infoAfiliado?.localidad,
        calle: infoAfiliado?.calle,
        itemsDomicilioPersonaFisica: getItemsDomicilio(infoAfiliado?.itemsDomicilioPersonaFisica),
      });
    }
  }, []);

  const handleClearMessage = () => {
    setMessage({ status: '', message: '' });
  };

  const save = async (values) => {
    try {
      const body = {
        afiliado: values?.afiliado,
        calle: values?.calle,
        domicilioPersonaFisica:
          values?.calle?.idCalle === values?.domicilio?.idCalle ? values?.domicilio : null,
        itemsDomicilioPersonaFisica: values?.itemsDomicilioPersonaFisica,
      };

      const response = await agregarDomicilio(body);

      if (response?.status === 'error') {
        setMessage({ status: WARNING, message: response.message });
        return;
      }

      const result = {
        ...data,
        domicilio: response?.domicilio?.domicilioPersonaFisica,
        provincia: response?.domicilio?.provincia,
        departamento: response?.domicilio?.departamento,
        localidad: response?.domicilio?.localidad,
        calle: response?.domicilio?.calle,
        itemsDomicilioPersonaFisica: response?.domicilio?.itemsDomicilioPersonaFisica,
        // itemsDomicilioPersonaFisica: getItemsDomicilio(response?.domicilio?.itemsDomicilioPersonaFisica)
      };

      setData(result);
      return result;
    } catch (error) {
      if (error?.data?.status === 'error') {
        setMessage({ status: ERROR, message: error.data.message });
      }
    }
  };

  return {
    data,
    save,
    message,
    clearMessage: handleClearMessage,
  };
};
