import { axiosGet, axiosPost } from '../ServiceBase.js';

export const getDepartamentos = async () => {
  try {
    const departamentos = JSON.parse(sessionStorage.getItem('departamentos'));
    if (departamentos == null) {
      const departamentos = await axiosGet('/v1/busquedas/departamentos');
      sessionStorage.setItem('departamentos', JSON.stringify(departamentos));
      return departamentos;
    } else {
      return departamentos;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findByProvincia = async (idProvincia) => {
  try {
    const departamentos = JSON.parse(
      sessionStorage.getItem('departamentosByProvincia' + idProvincia),
    );
    if (departamentos == null) {
      const departamentos = await axiosPost('/v1/busquedas/find-departamentos-by-provincia', {
        idProvincia: idProvincia,
      });
      sessionStorage.setItem(
        'departamentosByProvincia' + idProvincia,
        JSON.stringify(departamentos),
      );
      return departamentos;
    } else {
      return departamentos;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
