import { axiosPost } from '../ServiceBase.js';

export const constantes = {
  TIPO_NUMERACION_TORRE: '1',
  TIPO_NUMERACION_PISO: '2',
  TIPO_NUMERACION_MANZANA: '3',
  TIPO_NUMERACION_DEPTO: '4',
  TIPO_NUMERACION_CASA: '5',
  TIPO_NUMERACION_NUMERO: '6',
  TIPO_NUMERACION_CUERPO: '8',
  TIPO_NUMERACION_TIRA: '9',
  TIPO_NUMERACION_KM: '10',
};

export const tipoNumeracion = {
  [constantes.TIPO_NUMERACION_TORRE]: { code: constantes.TIPO_NUMERACION_TORRE, name: 'torre' },
  [constantes.TIPO_NUMERACION_PISO]: { code: constantes.TIPO_NUMERACION_PISO, name: 'piso' },
  [constantes.TIPO_NUMERACION_MANZANA]: {
    code: constantes.TIPO_NUMERACION_MANZANA,
    name: 'manzana',
  },
  [constantes.TIPO_NUMERACION_DEPTO]: { code: constantes.TIPO_NUMERACION_DEPTO, name: 'depto' },
  [constantes.TIPO_NUMERACION_CASA]: { code: constantes.TIPO_NUMERACION_CASA, name: 'casa' },
  [constantes.TIPO_NUMERACION_NUMERO]: { code: constantes.TIPO_NUMERACION_NUMERO, name: 'numero' },
  [constantes.TIPO_NUMERACION_CUERPO]: { code: constantes.TIPO_NUMERACION_CUERPO, name: 'cuerpo' },
  [constantes.TIPO_NUMERACION_TIRA]: { code: constantes.TIPO_NUMERACION_TIRA, name: 'tira' },
  [constantes.TIPO_NUMERACION_KM]: { code: constantes.TIPO_NUMERACION_KM, name: 'km' },
};

export const agregarDomicilio = async (domi) => {
  return axiosPost('/v1/domicilio/guardar-domicilio', { domicilio: domi });
};
