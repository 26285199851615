import { axiosGet, axiosPost } from '../ServiceBase.js';

export const getCalles = async () => {
  try {
    const calles = JSON.parse(sessionStorage.getItem('calles'));
    if (calles == null) {
      const calles = await axiosGet('/v1/busquedas/calles');
      return calles;
    } else {
      return calles;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findByLocalidad = async (idLocalidad) => {
  try {
    const calles = JSON.parse(sessionStorage.getItem('callesByLocalidad'));
    if (calles == null) {
      const calles = await axiosPost('/v1/busquedas/find-calles-by-localidad', {
        idLocalidad: idLocalidad,
      });
      sessionStorage.setItem('calles', JSON.stringify(calles));
      return calles;
    } else {
      return calles;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
