import { forwardRef, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { findByDepartamento } from '../../services/Busquedas/Localidad';

const useLocalidad = ({ departamentoId }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const getLocalidadesByDepartamento = async (id) => {
    setLoading(true);
    const localidades = await findByDepartamento(id);
    setOptions(localidades);
    setLoading(false);
  };

  useEffect(() => {
    setOptions([]);
    if (departamentoId) {
      getLocalidadesByDepartamento(departamentoId);
    }
  }, [departamentoId]);

  return {
    options,
    loading,
  };
};

const LocalidadSimpleAutocomplete = forwardRef(
  ({ value, departamentoId, onChangeOption, error }, ref) => {
    const { loading, options } = useLocalidad({ departamentoId });

    const handleChange = (newValue) => {
      if (onChangeOption) {
        onChangeOption(newValue);
      }
    };

    return (
      <Autocomplete
        id='localidad-autocomplete'
        value={value || null}
        isOptionEqualToValue={(option, value) => option?.idLocalidad === value?.idLocalidad}
        getOptionLabel={(option) => `${option.descripcion}`}
        onChange={(_, newValue) => handleChange(newValue)}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Localidad'
            variant='standard'
            error={!!error}
            helperText={error ? error.message : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color='inherit' size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  },
);

export default LocalidadSimpleAutocomplete;
