import React, { useState } from 'react';
import ModalBase from '../../Controls/ModalBase';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';

const ContactoConfirmadoRedirectSignin = React.forwardRef((props, ref) => {
  const { open, setOpen } = props;
  const [onIniciarSesion, setOnIniciarSesion] = useState(false);

  const handleClose = () => {
    setOnIniciarSesion(true);
    setOpen(false);
  };

  const form = (
    <div>
      <Grid container direction='column' justifyContent='center' alignItems='center' spacing={1}>
        <Grid item>
          <Typography align='center' variant='body2' color='textSecondary'>
            Su cuenta ha sido creada con éxito
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction='column' justifyContent='flex-end' alignItems='flex-end'>
        {' '}
        <br />
        <Grid item>
          <Button
            size='small'
            variant='contained'
            color='primary'
            sx={{ p: 1 }}
            onClick={handleClose}
          >
            Iniciar Sesión
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return onIniciarSesion ? (
    <Redirect
      to={{
        pathname: '/',
        state: { from: '/signin' },
      }}
    />
  ) : (
    <Box sx={{ flexGrow: 1 }}>
      <ModalBase
        open={open}
        setOpen={setOpen}
        content={form}
        title='Cuenta mi.iosper'
        onClose={handleClose}
      />
    </Box>
  );
});

export default ContactoConfirmadoRedirectSignin;
