import { TextField } from '@mui/material';

const EmailTextInput = ({
  fieldName,
  label,
  register,
  errors,
  mensajeError,
  variant,
  required,
  maxLength,
  minLength,
}) => {
  return (
    <div>
      <TextField
        variant={variant}
        fullWidth
        name={fieldName}
        label={label}
        type='email'
        id={fieldName}
        {...register(fieldName, {
          required: {
            value: required,
            message: mensajeError,
          },
          maxLength: {
            value: maxLength,
            message: label + ' debe tener como máximo ' + maxLength + ' caracteres.',
          },
          minLength: {
            value: minLength,
            message: label + ' debe tener como mínimo ' + minLength + ' caracteres.',
          },
          pattern: {
            value:
              /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'El formato no es correcto.',
          },
        })}
      />
      {errors[fieldName] && <p style={{ color: 'red' }}>{errors[fieldName].message}</p>}
    </div>
  );
};

EmailTextInput.defaultProps = {
  maxLength: 60,
  minLength: 6,
  variant: 'standard',
};

export default EmailTextInput;
