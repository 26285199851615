import React from 'react';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import {
  Alert,
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  ToggleButton,
  Typography,
} from '@mui/material';
import { useWidth } from '../../@iosper/utils/UseWidth';
import StyledDataGridSolicitudCobertura from '../Common/StyledDataGridSolicitudCobertura';

const DataCollapseDrawer = (props) => {
  const { tramite } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <Typography variant='body2' align='left' gutterBottom={false}>
            {tramite.numero + ' ' + tramite.estadoActual}
          </Typography>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <List>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Fecha Iniciada' secondary={tramite.fechaIniciada} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Descripción' secondary={tramite.descripcionTipoCobertura} />
            </ListItem>
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const TramitesSatTable = ({ tramites }) => {
  const screenWidth = useWidth();

  /// -------------------- DATA GRID CONSUMOS --------------------------
  const mapConsumoToRow = (tramite, index) => ({
    id: index,
    numero: tramite.idSolicitudCobertura,
    fechaIniciada: tramite.fechaIniciada.substr(0, 10),
    estadoActual: tramite.descripcionEstadoActual,
    descripcionTipoCobertura: tramite.descripcionTipoCobertura,
    codigoEstado: tramite.codigoEstado,
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: 'Número - Estado Actual',
      flex: 1,
      renderCell: (a) => <DataCollapseDrawer tramite={a.row} />,
    },
    { field: 'numero', headerName: 'Número', flex: 1, width: 120 },
    { field: 'fechaIniciada', headerName: 'Fecha Iniciada', flex: 1 },
    { field: 'estadoActual', headerName: 'Estado Actual', flex: 1 },
    { field: 'descripcionTipoCobertura', headerName: 'Descripción', flex: 1 },
  ];

  return tramites?.length ? (
    <div style={{ width: '100%' }}>
      <StyledDataGridSolicitudCobertura
        getRowClassName={(params) => `super-app-theme--${params.row.codigoEstado}`}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        autoHeight={true}
        loading={!tramites.length}
        rows={tramites.map((a, index) => mapConsumoToRow(a, index))}
        columns={columns}
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 200}
        columnVisibilityModel={{
          desplegable: screenWidth === 'xs',
          numero: screenWidth !== 'xs',
          fechaIniciada: screenWidth !== 'xs',
          estadoActual: screenWidth !== 'xs',
          descripcionTipoCobertura: screenWidth !== 'xs',
        }}
        pagination={screenWidth === 'xs' ? null : true}
        disableColumnMenu
      />
    </div>
  ) : (
    <Alert severity='info'>No se han encontrado resultados</Alert>
  );
};

export default TramitesSatTable;
