import { axiosGet } from '../ServiceBase.js';

export const getTiposContactos = async () => {
  try {
    const tiposContactos = JSON.parse(sessionStorage.getItem('tiposContactos'));

    if (tiposContactos == null) {
      const tipos = await axiosGet('/v1/busquedas/tipos-contactos');
      sessionStorage.setItem('tiposContactos', JSON.stringify(tipos));
      return tipos;
    } else {
      return tiposContactos;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
};
