import React, { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  Typography,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  ListItemButton,
  Box,
  ToggleButton,
  Collapse,
  List,
  CardContent,
  useMediaQuery,
  Button,
  Stack,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  OpenInNew,
  Edit,
  GetApp,
  AddCircle,
  MoreVert,
} from '@mui/icons-material';
import { findTransitorias } from '../../services/Busquedas/Derivaciones';
import { useForm } from 'react-hook-form';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useWidth } from '../../@iosper/utils/UseWidth';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { getCartaDerivacionTransitoria } from '../../services/Afiliado/Derivaciones';
import AddTransitoria from './AddTransitoria';
import { isMobile } from '../../@iosper/utils/DeviceDetector';

const DataCollapseDrawer = (props) => {
  const { derivacion } = props;
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openAction = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDescargar = async (filename, idsDerivaciones) => {
    try {
      const urlReporte = await getCartaDerivacionTransitoria({ ids: idsDerivaciones });

      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('href', urlReporte);
      link.setAttribute('download', 'Carta Derivacion Transitoria ' + filename + '.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0, p: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <List sx={{ py: 0 }}>
            <ListItem sx={{ py: 0 }}>
              <ListItemText
                primary={` ${derivacion.provincia || ''}`}
                secondary={`${derivacion.fechaDesde || ''}-${derivacion.fechaHasta || ''}`}
              />
            </ListItem>
          </List>
          <List sx={{ p: 0 }}>
            <ListItem sx={{ justifyContent: 'flex-end', p: 0 }}>
              <ListItemButton
                aria-label='more'
                id='long-button'
                aria-controls={openAction ? 'long-menu' : undefined}
                aria-expanded={openAction ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
              >
                <MoreVert />
              </ListItemButton>
              <Menu
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={openAction}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: 58 * 4.5,
                    width: '200px',
                  },
                }}
              >
                <MenuItem
                  key={'descargar'}
                  onClick={() => {
                    handleDescargar(derivacion.provincia, derivacion.ids);
                  }}
                >
                  <GridActionsCellItem
                    icon={<GetApp />}
                    title='Carta derivación'
                    label='Carta derivación'
                  />
                  <Typography variant='body2'>Carta derivación</Typography>
                </MenuItem>
                <MenuItem
                  key={'editar'}
                  onClick={() => {
                    props.onEdit(derivacion);
                  }}
                >
                  <GridActionsCellItem
                    sx={{ py: 0 }}
                    icon={<Edit />}
                    title='Editar'
                    label='Editar'
                  />
                  <Typography variant='body2'>Editar</Typography>
                </MenuItem>
              </Menu>
            </ListItem>
          </List>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box>
          <List sx={{ py: 0 }}>
            <ListItem sx={{ py: 0 }}>
              <ListItemText
                sx={{ py: 0 }}
                primary={`${derivacion.obraSocial || ''}`}
                secondary={`${derivacion.derivados || ''}`}
              />
            </ListItem>
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const VerTransitorias = () => {
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const esMobile = isMobile() || !matches;

  const screenWidth = useWidth();

  const [derivaciones, setDerivaciones] = useState(null);
  const [onAdd, setAdd] = useState(false);
  const [onEdit, setEdit] = useState(null);

  const handlePreview = async (idsDerivaciones) => {
    try {
      const urlReporte = await getCartaDerivacionTransitoria({ ids: idsDerivaciones });
      history.push({
        pathname: '/previsualizar',
        state: { url: urlReporte } || {}, // your data array of objects
      });
    } catch (e) {
      console.error(e);
    }
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (derivaciones === null) {
      getInfo();
    }
  }, [derivaciones]);

  const getInfo = async () => {
    const response = await findTransitorias();
    response.map(async (d) => {
      const derivaciones = d.afiliados.reduce((prev, current) => {
        return prev.concat(current.idHistorialDerivacionAfiliado);
      }, []);

      d.ids = derivaciones;
      return d;
    });
    setDerivaciones(response);
  };

  const mapListDerivados = (a) => {
    return ' ' + a.idHistorialDerivacionAfiliado + ' - ' + a.apellido + ' ' + a.nombre;
  };

  const mapDerivacionToRow = (p, index) => ({
    id: index,
    fechaDesde: p.fechaDesde,
    fechaHasta: p.fechaHasta,
    provincia: p.provincia,
    obraSocial: '(' + p.sigla + ') ' + p.obraSocial,
    idObraSocial: p.idObraSocial,
    derivadosLabel: p.afiliados.map((a) => mapListDerivados(a)),
    datas: p.afiliados,
    ids: p.ids,
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: 'Derivaciones Vigentes:',
      flex: 1,
      renderCell: (a) => (
        <DataCollapseDrawer
          derivacion={a.row}
          onEdit={(e) => {
            setEdit(e);
          }}
        />
      ),
    },
    { field: 'provincia', headerName: 'Provincia', flex: 0.05 },
    { field: 'fechaDesde', headerName: 'Desde', flex: 0.05 },
    { field: 'fechaHasta', headerName: 'Hasta', flex: 0.05 },
    { field: 'obraSocial', headerName: 'Obra Social', flex: 0.15 },
    { field: 'derivadosLabel', headerName: 'Derivados', flex: 0.2 },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<OpenInNew />}
          title='Carta derivación'
          label='Carta derivación'
          onClick={() => {
            handlePreview(params.row.ids);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<Edit />}
          title='Editar'
          label='Editar'
          onClick={() => {
            setEdit(params.row);
          }}
          showInMenu
        />,
      ],
    },
  ];

  const dataGridDerivaciones = derivaciones && (
    <Grid container direction='column' justifyContent='space-between' spacing={1}>
      <Grid item>
        <div style={{ height: '50vh', width: '100%' }}>
          <DataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-cell': {
                py: '8px',
              },
            }}
            columns={columns}
            rows={derivaciones.map((a, index) => mapDerivacionToRow(a, index))} //
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}
            components={{
              NoRowsOverlay: () => (
                <Stack height='100%' alignItems='center' justifyContent='center'>
                  Sin derivaciones transitorias.
                </Stack>
              ),
            }}
            columnVisibilityModel={{
              desplegable: screenWidth === 'xs',
              id: screenWidth !== 'xs',
              fechaDesde: screenWidth !== 'xs',
              fechaHasta: screenWidth !== 'xs',
              provincia: screenWidth !== 'xs',
              obraSocial: screenWidth !== 'xs',
              sigla: screenWidth !== 'xs',
              derivadosLabel: screenWidth !== 'xs',
              actions: screenWidth !== 'xs',
            }}
            pagination
            autoPageSize
            disableColumnMenu
          />
        </div>
      </Grid>
      {esMobile && (
        <Grid
          item
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Alert severity='info'>
            <b style={{ color: '#1d1e1eb0' }}>Usando el + podés generar una nueva derivación.</b>
          </Alert>
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <AddCircle
              color='primary'
              aria-label='add'
              sx={{ fontSize: 60 }}
              onClick={() => {
                setAdd(true);
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );

  return onAdd ? (
    <AddTransitoria
      onCancelar={() => {
        setAdd(false);
      }}
      onSaved={() => {
        setDerivaciones(null);
      }}
    ></AddTransitoria>
  ) : onEdit !== null ? (
    <AddTransitoria
      afiliados={onEdit.datas}
      fechaDesde={onEdit.fechaDesde}
      fechaHasta={onEdit.fechaHasta}
      idObraSocial={onEdit.idObraSocial}
      onCancelar={() => {
        setEdit(null);
      }}
      onSaved={() => {
        setDerivaciones(null);
      }}
    ></AddTransitoria>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          {!esMobile && (
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Derivaciones Transitorias Vigentes
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p' align='justify'>
                Antes de viajar, <b>generá tu Carta de Derivación en Tránsito</b>, para acceder a la
                cobertura médica en caso de una urgencia en la provincia que visitas
              </Typography>

              {dataGridDerivaciones}

              <Grid item>
                <Grid container justifyContent='flex-end' spacing={1}>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setAdd(true);
                      }}
                    >
                      Agregar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          )}
          {esMobile && (
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Derivaciones Transitorias
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p' align='justify'>
                Antes de viajar, <b>generá tu Carta de Derivación en Tránsito</b>, para acceder a la
                cobertura médica en caso de una urgencia en la provincia que visitas
              </Typography>
              {dataGridDerivaciones}
            </CardContent>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default VerTransitorias;
