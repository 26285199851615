import BuscarConsumoPrestacional from '../ConsumoPrestacional/BuscarConsumoPrestacional';

const ConsumoPrestacional = () => {
  return (
    <>
      <BuscarConsumoPrestacional />
    </>
  );
};

export default ConsumoPrestacional;
