import React from 'react';
import { Typography, Button, Grid, Box } from '@mui/material';
import ModalBase from '../Controls/ModalBase';

const MensajeDeConfirmacionModal = React.forwardRef((props, ref) => {
  const { open, setOpen, onClose, titleModal, messagePrimary, messageSecundary } = props;

  const handleClose = () => {
    onClose();
  };

  const form = (
    <div>
      <Grid container direction='column' justifyContent='center' alignItems='center' spacing={1}>
        <Grid item>
          <Typography align='center' variant='body2' color='textSecondary'>
            {messagePrimary}
          </Typography>
          <br />
          <Typography align='center' variant='body2' color='textSecondary'>
            {messageSecundary}
          </Typography>
          <br />
        </Grid>
      </Grid>
      <Grid container direction='column' justifyContent='flex-end' alignItems='flex-end'>
        <Grid item>
          <Button size='small' variant='contained' color='primary' onClick={(e) => handleClose(e)}>
            Aceptar
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ModalBase
        open={open}
        setOpen={setOpen}
        content={form}
        title={titleModal}
        onClose={handleClose}
      />
    </Box>
  );
});

MensajeDeConfirmacionModal.defaultProps = {
  titleModal: 'Debe definir un titlo para el modal',
  messagePrimary: 'Debe setear la propiedad messagePrimary',
  messageSecundary: '',
};
export default MensajeDeConfirmacionModal;
