import { forwardRef, useEffect, useState } from 'react';
import { getTiposContactos } from '../../services/Busquedas/TiposContactos';
import TextField from '@mui/material/TextField';
import { Autocomplete, CircularProgress } from '@mui/material';

const useTipoContacto = ({ valueTipoContactoId = null }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [controlledValue, setControlledValue] = useState(null);

  const fetchGetTiposContactos = async () => {
    setLoading(true);
    const tiposContacto = await getTiposContactos();
    setOptions(tiposContacto);

    if (valueTipoContactoId) {
      setControlledValue(tiposContacto.find((i) => i.idTipoContacto == valueTipoContactoId));
    }

    setLoading(false);
  };

  useEffect(() => {
    setOptions([]);
    fetchGetTiposContactos();
  }, []);

  useEffect(() => {
    setControlledValue(null);
    if (valueTipoContactoId && options?.length > 0) {
      setControlledValue(options.find((i) => i.idTipoContacto == valueTipoContactoId));
    }
  }, [valueTipoContactoId]);

  return {
    options,
    loading,
    controlledValue,
  };
};

const TipoContactoAutocomplete = forwardRef(
  ({ value = null, valueTipoContactoId = null, onChangeOption, error }, ref) => {
    const { loading, options, controlledValue } = useTipoContacto({ valueTipoContactoId });

    const handleChange = (newValue) => {
      if (onChangeOption) {
        onChangeOption(newValue);
      }
    };

    return (
      <Autocomplete
        value={value || controlledValue || null}
        isOptionEqualToValue={(option, val) => option.idTipoContacto === val.idTipoContacto}
        getOptionLabel={(option) => `${option.descripcion}`}
        loading={loading}
        options={options}
        onChange={(_, newValue) => handleChange(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Tipo de contacto'
            variant='standard'
            error={!!error}
            helperText={error ? error.message : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color='inherit' size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  },
);

export default TipoContactoAutocomplete;
