import { axiosPost, axiosPostFile } from '../ServiceBase.js';

/**
 * save derivaciones transitorias
 * @returns
 */
export const saveTransitorias = async (derivaciones) => {
  const afiliado = await axiosPost('/v1/derivaciones/save-transitorias', derivaciones);
  return afiliado;
};

/**
 * Imprimir la carta de derivacion transitoria
 * @returns
 */
export const getCartaDerivacionTransitoria = async (data) => {
  return axiosPostFile('/v1/derivaciones/imprimir-carta-derivacion', { ids: data.ids });
};
