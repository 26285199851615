import { axiosGet, axiosPost } from '../ServiceBase.js';

export const getLocalidades = async () => {
  try {
    const localidades = JSON.parse(sessionStorage.getItem('localidades'));
    if (localidades == null) {
      const localidades = await axiosGet('/v1/busquedas/localidades');
      sessionStorage.setItem('localidades', JSON.stringify(localidades));
      return localidades;
    } else {
      return localidades;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findByDepartamento = async (idDepartamento) => {
  try {
    const localidades = JSON.parse(
      sessionStorage.getItem('localidadesByDepartamento' + idDepartamento),
    );
    if (localidades == null) {
      const localidades = await axiosPost('/v1/busquedas/find-localidades-by-departamento', {
        idDepartamento: idDepartamento,
      });
      sessionStorage.setItem(
        'localidadesByDepartamento' + idDepartamento,
        JSON.stringify(localidades),
      );
      return localidades;
    } else {
      return localidades;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
