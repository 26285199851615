import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
//import AfiliadoCard from '../Afiliados/AfiliadoCard';
import AfiliadoSelect from '../Afiliados/AfiliadoSelect';
import { Grid, Button, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import HighlightOff from '@mui/icons-material/HighlightOff';
import Search from '@mui/icons-material/Search';
import { useForm } from 'react-hook-form';
import OrdenParaReimprimir from './OrdenParaReimprimir';
import { validarReimpresionOrden } from '../../services/Seo/Orden';
import LoadingButton from '../../@iosper/components/LoadingButton';
import ButtonBack from '../Breadcrumb/ButtonBack';
import OrdenPreview from './OrdenPreview';

const ReimprimirOrden = () => {
  const [selectedAfiliado, setSelectedAfiliado] = useState(null);
  const [keyComponentAfiliado, setKeyComponentAfiliado] = useState(0);
  // Dialog Orden
  const [open, setOpen] = useState(false);
  const [datosOrdenReceived, setDatosOrdenReceived] = useState({});
  const [loadingOrden, setLoadingOrden] = useState(false);

  // Dialog Orden Preview
  const [openDialogReporte, setOpenDialogReporte] = useState(false);
  const [urlReporte, setUrlReporte] = useState('');
  const [datosOrdenGenerada, setDatosOrdenGenerada] = useState('');

  const handleSelectAfiliado = async (afiliado) => {
    setSelectedAfiliado(afiliado);
  };

  const validateAfiliado = () => {
    return selectedAfiliado == null ? false : true;
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const handlerLimpiar = () => {
    setKeyComponentAfiliado(1 + Math.random() * (1000 - 1));
    reset();
    setSelectedAfiliado(null);
  };

  const handlerReimprimir = (datosOrden, urlPdf) => {
    setUrlReporte(urlPdf);
    setDatosOrdenGenerada(datosOrden);
    setOpenDialogReporte(true);
  };

  const handlerCerrarPreview = () => {
    handlerLimpiar();
  };

  const onSubmit = async (data, event) => {
    setLoadingOrden(true);
    event.preventDefault();

    try {
      const res = await validarReimpresionOrden(getAfiliadoData());
      setDatosOrdenReceived(res);
      setOpen(!open);
      setLoadingOrden(false);
    } catch (e) {
      setLoadingOrden(false);
    }
  };

  const getAfiliadoData = () => {
    let data = {
      idAfiliado: selectedAfiliado.afiliado.idAfiliado,
    };
    return data;
  };

  return (
    <Grid container spacing={1}>
      <ButtonBack />
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Reimprimir Orden Afiliado
            </Typography>

            <Typography variant='string' component={'div'}>
              Acá podrás volver a imprimir la última orden impresa que generaste.
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <AfiliadoSelect
                  key={keyComponentAfiliado}
                  ref={register('afiliadoRegister', {
                    validate: validateAfiliado,
                  })}
                  onSelectAfiliado={handleSelectAfiliado}
                />
                {errors.afiliadoRegister && (
                  <p style={{ color: 'red' }}>Debe seleccionar el afiliado</p>
                )}
              </FormControl>
            </form>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end', p: 3 }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2 }}
              justifyContent={{ xs: 'center', sm: 'flex-end' }}
              alignItems={{ xs: 'strech' }}
            >
              <Button
                variant='contained'
                color='neutral'
                startIcon={<HighlightOff />}
                onClick={handlerLimpiar}
              >
                Limpiar
              </Button>

              <LoadingButton
                fullWidth={true}
                variant='contained'
                color='primary'
                startIcon={<Search />}
                type='submit'
                loading={loadingOrden ? 'show' : 'hide'}
                content={'Buscar'}
                onClick={handleSubmit(onSubmit)}
              />
            </Stack>
          </CardActions>
        </Card>

        <OrdenParaReimprimir
          open={open}
          setOpen={setOpen}
          datosOrden={datosOrdenReceived}
          handlerReimprimir={handlerReimprimir}
        ></OrdenParaReimprimir>

        <OrdenPreview
          open={openDialogReporte}
          setOpen={setOpenDialogReporte}
          urlReporte={urlReporte}
          datosOrden={datosOrdenGenerada}
          showMail={true}
          nombreReporte={'Orden_iosper_' + datosOrdenGenerada.numeroOrden}
          handlerCerrarPreview={handlerCerrarPreview}
        ></OrdenPreview>
      </Grid>
    </Grid>
  );
};

export default ReimprimirOrden;
