import { axiosGet } from '../ServiceBase.js';

/**
 * Envía un mensaje por websocket a mi-consultorio
 * @param {string} token
 * @returns
 */
export const sendMessageOrdenCancelada = async (token) => {
  await axiosGet(`/v1/websocket-ordenes/send-cancelada?token=${token}`);
  return true;
};
