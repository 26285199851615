import { useEffect } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import logo from '../../../assets/images/logo-small.png';
import fondo from '../../../assets/images/mantenimiento/svg/maintenance.svg';
import { useVersionControlStore } from '../../../@iosper/components/VersionControl/store/hooks';

const Mantenimiento = () => {
  const { state } = useVersionControlStore();

  const goHome = () => {
    if (state.isUptodate) {
      handleRefresh();
    }
  };

  const handleRefresh = () => {
    deleteAllCookies();
    window.location.reload(true);
  };

  const deleteAllCookies = () => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    }

    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  useEffect(() => {
    goHome();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Container maxWidth='lg'>
      <Grid container direction='row' justifyContent='center' alignItems='center' height={'90vh'}>
        <Grid item xs={12} sm={6}>
          <Box mb={3}>
            <img src={logo} alt='' />
          </Box>
          <Box>
            <Typography variant='h2' gutterBottom fontSize={{ xs: '2.75rem' }}>
              Sitio en mantenimiento
            </Typography>
            <Typography variant='subtitle1' gutterBottom>
              Estaremos inactivos temporalmente, estamos trabajando para mejorar. Disculpe las
              molestias.
            </Typography>
          </Box>
          <Box mt={3}>
            <Button onClick={handleRefresh} variant='contained'>
              Actualizar
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={fondo} alt='' />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Mantenimiento;
