import { axiosPost } from '../ServiceBase.js';

/**
 * Validar token consulta Orden Electrónica api call
 * @returns
 */
export const validarTokenConsulta = async (data) => {
  return axiosPost('/v1/orden-digital/validar-token-consulta', data);
};

/**
 * Validar Orden Electrónica consulta api call
 * @returns
 */
export const validarOrdenDigitalConsulta = async (data) => {
  return axiosPost('/v1/orden-digital/validar-orden-digital-consulta', data);
};

/**
 * Generar Orden Electrónica api call
 * @returns
 */
export const generarOrdenDigital = async (data) => {
  return axiosPost('/v1/orden-digital/generar-orden-digital', data);
};
