import { Assignment, HighlightOff } from '@mui/icons-material';
import {
  Paper,
  Typography,
  FormControl,
  CardContent,
  Button,
  Container,
  Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import LoadingButton from '../../@iosper/components/LoadingButton';
import { useForm } from 'react-hook-form';
import { useAfiliados } from '../Afiliados/AfiliadosProvider';
import AfiliadoAutocomplete from '../Afiliados/AfiliadoAutocomplete';
import { validarTokenConsulta, validarOrdenDigitalConsulta } from '../../services/Seo/OrdenDigital';
import OrdenValidadaResumidaForm from './OrdenValidadaResumidaForm';
import OrdenPreview from './OrdenPreview';
import { Redirect } from 'react-router-dom';
import { Box } from '@mui/system';
import ReplayIcon from '@mui/icons-material/Replay';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ReactComponent as WarningImg } from '../../assets/images/svg/mobile-warning.svg';
import { sendMessageOrdenCancelada } from '../../services/WebSocket/WebSocket';

const GenerarOrdenDigital = (props) => {
  const [selectedAfiliado, setSelectedAfiliado] = useState(null);

  // Dialog Orden
  const [open, setOpen] = useState(false);
  const [datosOrdenReceived, setDatosOrdenReceived] = useState({});
  const [loadingOrden, setLoadingOrden] = useState(false);

  const [showSelectedAfiliado, setShowSelectedAfiliado] = useState(false);
  const [showScanner, setShowScanner] = useState(true);

  const [openDialogReporte, setOpenDialogReporte] = useState(false);
  const [urlReporte, setUrlReporte] = useState('');
  const [datosOrdenGenerada, setDatosOrdenGenerada] = useState('');

  const [onCancelar, setOnCancelar] = useState(false);
  const [qrNoValido, setQrNoValido] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showBtnHelp, setShowBtnHelp] = useState(false);

  const [dataToken, setDataToken] = useState(null);

  const { afiliados } = useAfiliados();

  useEffect(() => {
    setTimeout(() => {
      setShowBtnHelp(true);
    }, 180000); //180000 milisegundos son 3 munitos
  }, [showBtnHelp]);

  const handleOnQRResult = (result, e) => {
    try {
      if (result) {
        setShowScanner(false);
        const res = result?.text;
        JSON.parse(res);
        validarToken(res);
      }
    } catch (e) {
      setQrNoValido(true);
    }
  };

  const validarToken = async (token) => {
    try {
      const data = await validarTokenConsulta(token);
      setDataToken(data);
      setShowScanner(false);

      if (afiliados?.length > 1) {
        setShowSelectedAfiliado(true);
      } else {
        validarOrdenDigital(data);
      }
    } catch (e) {
      setShowScanner(true);
    }
  };

  const validarOrdenDigital = async (data) => {
    try {
      const res = await validarOrdenDigitalConsulta(getDataOrden(data));
      setDatosOrdenReceived(res);
      setOpen(!open);
      setLoadingOrden(false);
    } catch (e) {
      setLoadingOrden(false);
      setShowScanner(true);
      setShowSelectedAfiliado(false);
    }
  };

  const getDataOrden = (dataToken) => {
    let data = {
      numeroTarjeta:
        afiliados?.length === 1
          ? afiliados[0].tarjeta.numeroTarjeta
          : selectedAfiliado.tarjeta.numeroTarjeta,
      codigoPrestador: dataToken.codigoPrestador,
      tipoPrestador: dataToken.tipoPrestador,
      token: dataToken.token,
      nombreUsuario: dataToken.nombreUsuario,
      token: dataToken.token,
      femer: dataToken.femer,
    };
    return data;
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const onSubmit = (data, event) => {
    setLoadingOrden(true);
    event.preventDefault();
    validarOrdenDigital(dataToken);
  };

  const validateAfiliado = () => {
    return selectedAfiliado == null ? false : true;
  };

  const handleSelectAfiliado = async (afiliado) => {
    setSelectedAfiliado(afiliado);
  };

  const handlerOrdenConfirmada = (datosOrden, urlPdf) => {
    setUrlReporte(urlPdf);
    setDatosOrdenGenerada(datosOrden);
    setOpenDialogReporte(true);
  };

  const handleClose = () => {
    setOnCancelar(true);
  };

  const handleReplyFromQrNoValido = () => {
    setQrNoValido(false);
  };

  const handleHelp = () => {
    setShowHelp(true);
  };

  const handleReplyFromHelp = () => {
    setShowHelp(false);
    setShowBtnHelp(false);
  };

  const handleCloseOrdenValidadaResumidaForm = () => {
    setOpen(false);
    setShowScanner(true);
    setShowSelectedAfiliado(false);
  };

  const handlerCerrarPreview = () => {
    setOpenDialogReporte(false);
    setOnCancelar(true);
  };

  const enviarMensajeCancelada = async () => {
    try {
      await sendMessageOrdenCancelada(dataToken.token);
    } catch (e) {
      console.log(e);
    }
  };

  const handlerOrdenCancelada = () => {
    enviarMensajeCancelada();
  };

  const contentHelp = (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={3} justifyContent='center' alignItems='center'>
        <Grid item>
          <Grid container direction='row' spacing={1}>
            <Grid item>
              <HelpOutlineIcon color='primary' />
            </Grid>
            <Grid item>
              <Typography variant='subtitle2'>¿Querés generar una Orden Electrónica?</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant='body2'>
            Apuntá la cámara hacia el código QR que generó el prestador.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body2'>
            Una vez leído correctamente el código, seguí las intrucciones en pantalla para completar
            el proceso.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            size='small'
            startIcon={<ReplayIcon />}
            variant='contained'
            color='primary'
            fullWidth={true}
            onClick={(e) => handleReplyFromHelp(e)}
          >
            Reintentar
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );

  const contentQrNoValido = (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={3}>
        <Grid item>
          <Grid container direction='row' justifyContent='center' alignItems='center'>
            <Grid xs={2} item>
              <WarningImg />
            </Grid>
            <Grid xs={10} item>
              <Typography align='center' variant='body2' color='secondary'>
                Oops! No pudimos escanear correctamente el código QR
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant='body2'>Verificá que tu cámara esté alineada.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body2'>
            Verificá que la cámara de tu dispositivo esté limpia.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body2'>
            Verificá que el reflejo de las luces no genere ruidos en la foto.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            size='small'
            startIcon={<ReplayIcon />}
            variant='contained'
            color='primary'
            fullWidth={true}
            onClick={(e) => handleReplyFromQrNoValido(e)}
          >
            Reintentar
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );

  const contentSeleccionarAfiliado = (
    <Paper sx={{ p: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <FormControl fullWidth={true} variant='outlined' margin='normal'>
            <AfiliadoAutocomplete
              ref={register('afiliadoRegister', {
                validate: validateAfiliado,
              })}
              onSelectAfiliado={handleSelectAfiliado}
            />
            {errors.afiliadoRegister && (
              <p style={{ color: 'red' }}>Debe seleccionar el afiliado</p>
            )}
          </FormControl>
          <LoadingButton
            fullWidth={true}
            variant='contained'
            size='medium'
            color='primary'
            startIcon={<Assignment />}
            type='submit'
            loading={loadingOrden ? 'show' : 'hide'}
            content={'Generar'}
          />
        </CardContent>
      </form>
    </Paper>
  );

  const contentScaner = (
    <Paper sx={{ p: 2 }}>
      <Typography variant='string' component={'div'} align='center' sx={{ pb: 3 }}>
        Escanea el código QR generado por médico prestador en la aplicación miconsultorio
      </Typography>
      <QrReader
        constraints={{ facingMode: 'environment' }}
        onResult={handleOnQRResult}
        style={{ width: '100%' }}
      />
      <Box sx={{ p: 2 }}>
        <Button
          size='small'
          variant='text'
          color='primary'
          fullWidth={true}
          onClick={(e) => handleHelp(e)}
        >
          {' '}
          ¿Necesitás ayuda?{' '}
        </Button>
        <Button
          size='small'
          startIcon={<HighlightOff />}
          variant='contained'
          color='neutral'
          fullWidth={true}
          onClick={(e) => handleClose(e)}
        >
          Cancelar
        </Button>
      </Box>
    </Paper>
  );

  return onCancelar ? (
    <Redirect
      to={{
        pathname: '/',
        state: { from: '/read-orden' },
      }}
    />
  ) : qrNoValido ? (
    contentQrNoValido
  ) : showHelp ? (
    contentHelp
  ) : (
    <Container component='main' maxWidth='sm'>
      {showScanner ? contentScaner : null}
      {showSelectedAfiliado ? contentSeleccionarAfiliado : null}

      <OrdenValidadaResumidaForm
        open={open}
        setOpen={handleCloseOrdenValidadaResumidaForm}
        datosOrden={datosOrdenReceived}
        handlerOrdenConfirmada={handlerOrdenConfirmada}
        handlerOrdenCancelada={handlerOrdenCancelada}
      ></OrdenValidadaResumidaForm>

      <OrdenPreview
        open={openDialogReporte}
        setOpen={setOpenDialogReporte}
        urlReporte={urlReporte}
        datosOrden={datosOrdenGenerada}
        nombreReporte={'Orden_iosper_' + datosOrdenGenerada.numeroOrden}
        handlerCerrarPreview={handlerCerrarPreview}
        showDownload={false}
        showPrint={false}
      ></OrdenPreview>
    </Container>
  );
};

export default GenerarOrdenDigital;
