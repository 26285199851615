import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Input,
  InputLabel,
  InputAdornment,
  useMediaQuery,
  IconButton,
  FormControl,
} from '@mui/material';
import { GetApp, OpenInNew, Print, ForwardToInbox, UploadFile } from '@mui/icons-material';
import { QRCodeSVG } from 'qrcode.react';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { isMobile } from '../../@iosper/utils/DeviceDetector';

const QrPreview = (props) => {
  const [showMail, setShowMail] = useState(props.showMail || false);
  const [correo, setCorreo] = useState(props.correo);
  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [successCorreo, setSuccessCorreo] = useState('');

  /** funcionalidad de envio por correo electronico */
  useEffect(() => {
    setLoadingEnviar(props.loadingEnviar);
  }, [props.loadingEnviar]);

  useEffect(() => {
    setSuccessCorreo(props.successCorreo);
  }, [props.successCorreo]);

  /**------------------------------------------------ */

  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const showPrint =
    (props.showPrint !== undefined && props.showPrint === false) || isMobile() || !matches
      ? false
      : true;

  const showDownload = props.showDownload !== undefined ? props.showDownload : true;

  const handleDescargar = (event) => {
    event.preventDefault();
    const link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('href', props.urlReporte);
    link.setAttribute('download', props.nombreReporte + '.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImprimir = (e) => {
    e.preventDefault();
    var objFra = document.createElement('iframe');
    objFra.style.visibility = 'hidden';
    objFra.src = props.urlReporte;
    objFra.onload = function () {
      objFra.contentWindow.focus();
      objFra.contentWindow.print();
    };
    document.body.appendChild(objFra);
  };

  const handlePreview = () => {
    history.push({
      pathname: '/previsualizar',
      state: { url: props.urlReporte } || {}, // your data array of objects
    });
  };

  const handleEnviar = () => {
    props.onEnviarCorreo(correo);
  };

  const handleChangeCorreo = (event) => {
    setCorreo(event.target.value);
  };

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={2}
      sx={{ mb: 3 }}
    >
      <Grid item>
        <QRCodeSVG value={props.qrValue} />
      </Grid>
      {(showDownload || showPrint) && (
        <Grid item>
          <ButtonGroup size='small' aria-label='large button group'>
            {showDownload && (
              <Button onClick={handleDescargar}>
                Descargar &nbsp;&nbsp; <GetApp />
              </Button>
            )}
            {showPrint && (
              <>
                <Button color='primary' onClick={handleImprimir}>
                  Imprimir &nbsp;&nbsp; <Print />
                </Button>
                <Button color='primary' onClick={handlePreview}>
                  Previsualizar &nbsp;&nbsp; <OpenInNew />
                </Button>
              </>
            )}
          </ButtonGroup>
        </Grid>
      )}
      {showMail && (
        <Grid item sx={{ width: { xs: '100%', sm: '100%', md: '50%', lg: '50%', xl: '50%' } }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl fullWidth sx={{ m: 1 }} disabled={loadingEnviar ? true : false}>
              <InputLabel htmlFor='standard-adornment-amount'>Enviar por correo</InputLabel>
              <Input
                id='standard-adornment-password'
                type='text'
                fullWidth
                onChange={handleChangeCorreo}
                defaultValue={props.correo}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='large button group'
                      onClick={handleEnviar}
                      color='primary'
                      disabled={loadingEnviar || correo === null || correo === '' ? true : false}
                    >
                      {loadingEnviar ? <UploadFile /> : <ForwardToInbox />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          <CustomizedSnackbars open={successCorreo} severity='success' message={successCorreo} />
        </Grid>
      )}
    </Grid>
  );
};

export default QrPreview;
