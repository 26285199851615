import { forwardRef, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { findByProvincia } from '../../services/Busquedas/Departamentos';

const useDepartamento = ({ provinciaId }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const getDepartamentosByProvincia = async (id) => {
    setLoading(true);
    const departamentos = await findByProvincia(id);
    setOptions(departamentos);
    setLoading(false);
  };

  useEffect(() => {
    setOptions([]);
    if (provinciaId) {
      getDepartamentosByProvincia(provinciaId);
    }
  }, [provinciaId]);

  return {
    options,
    loading,
  };
};

const DepartamentoAutocomplete = forwardRef(
  ({ value, provinciaId, onChangeOption, error }, ref) => {
    const { loading, options } = useDepartamento({ provinciaId });

    const handleChange = (newValue) => {
      if (onChangeOption) {
        onChangeOption(newValue);
      }
    };

    return (
      <Autocomplete
        value={value || null}
        isOptionEqualToValue={(option, value) => option?.idDepartamento === value?.idDepartamento}
        getOptionLabel={(option) => `${option?.descripcion || ''}`}
        loading={loading}
        options={options}
        onChange={(_, newValue) => handleChange(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Departamento'
            variant='standard'
            error={!!error}
            helperText={error ? error.message : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color='inherit' size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  },
);

export default DepartamentoAutocomplete;
