import { useEffect, useState } from 'react';
import { ArrowForwardIos, CheckCircleOutline, HighlightOffOutlined } from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Box,
  Stack,
  Tooltip,
  CardHeader,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { dateBirthFormatter } from '../../@iosper/utils/Format';
import { seguirAfiliadoLogin } from '../../services/Busquedas/Afiliados';
import { Domicilio } from '../Afiliados/Domicilio/Domicilio';
import { Contacto } from '../Afiliados/Contacto/Contacto';

const DrawerDataField = ({ label, data }) => {
  return (
    <ListItem>
      <Grid container alignItems={'baseline'}>
        <Grid item xs={12} sm={4} md={3}>
          <ListItemText
            secondary={
              <Typography gutterBottom variant='body2' display='block' sx={{ fontWeight: '600' }}>
                {label}
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <ListItemText primary={data} primaryTypographyProps={{ fontWeight: 'bold' }} />
        </Grid>
      </Grid>
    </ListItem>
  );
};

const DrawerDataFieldAction = ({ label, data, link }) => {
  return (
    <ListItem>
      <ListItemButton component={Link} to={link} sx={{ px: 1 }}>
        <Grid container alignItems={'baseline'}>
          <Grid item xs={12} sm={4} md={3}>
            <ListItemText
              secondary={
                <Typography gutterBottom variant='body2' display='block' sx={{ fontWeight: '600' }}>
                  {label}
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <ListItemText
              primary={
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  {data}
                  <IconButton>
                    <ArrowForwardIos fontSize='small' />
                  </IconButton>
                </Box>
              }
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </Grid>
        </Grid>
      </ListItemButton>
    </ListItem>
  );
};

const DrawerDataEstadoVerificado = ({ data, variaton, message, link }) => {
  return (
    <ListItem
      disablePadding
      secondaryAction={
        data &&
        variaton === 'error' && (
          <Tooltip id='button-link' title='Verificar'>
            <IconButton component={Link} to={link}>
              <ArrowForwardIos fontSize='small' />
            </IconButton>
          </Tooltip>
        )
      }
    >
      <Grid container>
        <Grid item xs={12} md={6} lg={6}>
          <ListItemText primary={data} primaryTypographyProps={{ fontWeight: 'bold' }} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <ListItemText
            primary={
              <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
                {data && variaton === 'error' && (
                  <HighlightOffOutlined color={'error'} fontSize='small' />
                )}
                {data && variaton === 'success' && (
                  <CheckCircleOutline color={'success'} fontSize='small' />
                )}
                <Typography
                  variant='caption'
                  display='block'
                  gutterBottom
                  sx={{ color: variaton === 'error' ? '#ef5350' : '#4caf50' }}
                >
                  {data && message}
                </Typography>
              </Stack>
            }
          />
        </Grid>
      </Grid>
    </ListItem>
  );
};

const Perfil = () => {
  const [infoAfiliado, setInfoAfiliado] = useState(null);
  var numeroCalle;
  const numeroDomicilio = infoAfiliado?.itemsDomicilioPersonaFisica?.filter(
    (t) => t.codigoTipoNumeracion === '6',
  );
  if (numeroDomicilio?.length > 0) {
    numeroCalle = numeroDomicilio[0];
  }

  const contactoTele = infoAfiliado?.contactoPersonaFisica?.filter(
    (t) => t.codigoTipoContacto === '1',
  );
  const contactoMail = infoAfiliado?.contactoPersonaFisica?.filter(
    (m) => m.codigoTipoContacto === '2',
  );
  const contactoCel = infoAfiliado?.contactoPersonaFisica?.filter(
    (m) => m.codigoTipoContacto === '5',
  );

  useEffect(() => {
    const get = async () => {
      const res = await seguirAfiliadoLogin();
      if (res !== null) {
        setInfoAfiliado(res);
      }
    };
    get();
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={9}>
          <Card sx={{ width: '100%' }}>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                gap: '14px',
              }}
            >
              <div>
                <Avatar sx={{ width: 80, height: 80 }} />
              </div>
              <div>
                <Typography
                  gutterBottom
                  variant='h6'
                  textAlign={'center'}
                  sx={{ fontWeight: 'bold' }}
                >
                  Bienvenido, {infoAfiliado?.personaFisica?.nombre}
                </Typography>
                <Typography
                  variant='body2'
                  color='textSecondary'
                  component='p'
                  textAlign={'center'}
                >
                  Gestiona tu información y seguridad personal.
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={9}>
          <Card sx={{ width: '100%', paddingTop: 0, paddingBottom: 0 }}>
            <CardContent
              sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                padding: 1,
              }}
            >
              <List sx={{ marginTop: 0, marginBottom: -3 }}>
                <DrawerDataField
                  label='Nombre Completo'
                  data={
                    <List disablePadding>
                      <DrawerDataEstadoVerificado
                        data={
                          infoAfiliado &&
                          `${infoAfiliado?.personaFisica?.nombre} ${infoAfiliado?.personaFisica?.apellido}`
                        }
                        variaton={infoAfiliado?.identidadVerificada ? 'success' : 'error'}
                        message={
                          infoAfiliado?.identidadVerificada
                            ? `(Identidad validada el ${
                                infoAfiliado?.fechaIdentidadVerificada || ''
                              })`
                            : '(Identidad sin validar)'
                        }
                        key={
                          infoAfiliado
                            ? `${infoAfiliado?.personaFisica?.idPersonaFisica}`
                            : 'nombreCompleto'
                        }
                        link={`/validar-identidad/${JSON.stringify(infoAfiliado?.personaFisica)}`}
                      />
                    </List>
                  }
                />
                <Divider />
                <DrawerDataField
                  label='Documento'
                  data={
                    infoAfiliado
                      ? `${infoAfiliado?.personaFisica?.numeroDocumentoString} (${infoAfiliado?.personaFisica?.descripcionTipoDocumento})`
                      : ''
                  }
                />
                <Divider />
                <DrawerDataField
                  label='Cuit/Cuil'
                  data={infoAfiliado?.persona?.cuilCuitFormateado}
                />
                <Divider />
                <DrawerDataField
                  label='Fecha de Nacimiento'
                  data={dateBirthFormatter(
                    infoAfiliado?.personaFisica?.fechaNacimiento,
                    'dd/mm/yyyy',
                  )}
                />
                <Divider />
                <DrawerDataField
                  label='Género'
                  data={infoAfiliado?.personaFisica?.descripcionSexo}
                />
              </List>
            </CardContent>
          </Card>
        </Grid>

        {infoAfiliado?.domicilioPersonaFisica && <Domicilio infoAfiliado={infoAfiliado} />}
        {infoAfiliado?.contactoPersonaFisica && <Contacto infoAfiliado={infoAfiliado} />}

        <Grid item xs={12} lg={9} sx={{ width: '100%', padding: 0 }}>
          <Card sx={{ width: '100%' }}>
            <CardHeader sx={{ paddingTop: 1, paddingBottom: 0 }} title='Seguridad' />
            <CardContent
              sx={{
                p: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                marginTop: 0,
                marginBottom: -2,
              }}
            >
              <DrawerDataFieldAction
                label='Cambiar mi contraseña'
                data='******'
                link='/cambiar-pin'
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Perfil;
