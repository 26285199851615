import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  CardActions,
  FormControl,
  Collapse,
  ToggleButton,
  Grid,
  Stack,
  List,
  ListItem,
  ListItemText,
  Alert,
  useMediaQuery,
} from '@mui/material';
import { HighlightOff, KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material';
import LoadingButton from '../../@iosper/components/LoadingButton';
import PracticaAutocomplete from '../Practicas/PracticaAutocomplete';
import CustomizedSnackbars from '../Notifications/SnackBar';
import AfiliadoSelect from '../Afiliados/AfiliadoSelect';
import { useTheme } from '@emotion/react';
import { useWidth } from '../../@iosper/utils/UseWidth';
import { consultarCuposOrdenMedica } from '../../services/Seo/Orden';
import ButtonBack from '../Breadcrumb/ButtonBack';
import StyledDataGridCupo from '../Common/StyledDataGridCupo';
import { isMobile } from '../../@iosper/utils/DeviceDetector';

const ConsultarCupo = () => {
  // const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const esMobile = isMobile() || !matches;
  const [selectedAfiliado, setSelectedAfiliado] = useState(null);
  const [selectedPractica, setSelectedPractica] = useState(null);
  const [cupo, setCupo] = useState(null);
  const [keyComponentAfiliado, setKeyComponentAfiliado] = useState(0);
  const [limites, setLimites] = useState([]);
  const [visibleFind, setVisibleFind] = useState(true);
  const [visibleDataGrid, setVisibleDataGrid] = useState(false);
  const [tieneCupo, setTieneCupo] = useState(true);
  const [loading, setLoading] = useState(false);
  const [alertResultFind, setAlertResultFind] = useState('');
  const [mensajeVer, setMensajeVer] = useState('');
  const screenWidth = useWidth();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const customStyle = {
    marginBottom: '0px',
    marginTop: '0px',
  };

  const onSubmitConsultarCupo = async (data, event) => {
    event.preventDefault();
    setLoading(true);
    let cupo = true;
    try {
      const res = await consultarCuposOrdenMedica({
        idAfiliado: selectedAfiliado?.afiliado.idAfiliado,
        idPractica: selectedPractica?.idPractica,
      });
      if (res && res.consumoCupoOrdenMedicaVO.length > 0) {
        setCupo(res);
        setLimites(res.consumoCupoOrdenMedicaVO);

        //Recorro los limites para ver si tiene cupo. Si tiene en todos muestro que posee cupo.
        for (let i = 0; i < res.consumoCupoOrdenMedicaVO.length; i++) {
          if (res.consumoCupoOrdenMedicaVO[i].consumoCupo.restantesConExtraordinarias < 1) {
            cupo = false;
            break;
          }
        }

        setTieneCupo(cupo);

        if (esMobile) {
          setMensajeVer('Ver más');
          setVisibleDataGrid(false);
        } else {
          setVisibleDataGrid(true);
        }

        setVisibleFind(false);
      } else {
        setAlertResultFind('No se han encontrado cupos para la práctica');
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const validatePractica = () => {
    return selectedPractica == null ? false : true;
  };

  const validateAfiliado = () => {
    return selectedAfiliado == null ? false : true;
  };

  const handlerSelectPractica = (practica) => {
    setSelectedPractica(practica);
  };

  /**
   * Evento selección afiliado. Seteamos el nuevo estado de afiliado
   * @param {object} afiliado
   */
  const onSelectAfiliado = (afiliado) => {
    setSelectedAfiliado(afiliado);
    //setDisabledTipoTratamiento(afiliado?false:true);
  };

  const handleBuscarOtro = () => {
    setLimites([]);
    setVisibleFind(true);
    clean();
  };

  const handleVerMas = () => {
    setVisibleDataGrid(!visibleDataGrid);
    setMensajeVer(visibleDataGrid ? 'Ver Más' : 'Ver menos');
  };

  const clean = () => {
    setSelectedAfiliado(null);
    setSelectedPractica(null);
    reset();
    setKeyComponentAfiliado(1 + Math.random() * (1000 - 1));
  };

  const DataCollapseDrawer = (props) => {
    const { limite } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <Box sx={{ width: '100%' }}>
        <ToggleButton
          aria-label='expand row'
          value='check'
          onChange={() => {
            setOpen(!open);
          }}
          sx={{ border: 0 }}
          fullWidth={true}
        >
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={2}
            flexWrap='nowrap'
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            <Typography
              variant='body1'
              align='left'
              gutterBottom={false}
              sx={{ color: '#000', textTransform: 'none' }}
            >
              {`${limite.cantidadMeses || ''}`}
            </Typography>
          </Grid>
        </ToggleButton>

        <Collapse in={open} timeout='auto' unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <List>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Tipo Límite: ${limite?.tipoLimite || ''}`} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Cant. Meses: ${limite?.cantidadMeses || ''}`} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Ordinarias: ${limite?.ordinarias || '0'}`} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Ampliaciones: ${limite?.ampliaciones || '0'}`} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Consumidas: ${limite?.consumidas || '0'}`} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Restantes: ${limite?.restantes || '0'}`} />
              </ListItem>
            </List>
          </Box>
        </Collapse>
      </Box>
    );
  };

  /// -------------------- DATA GRID PRESTADORES --------------------------
  const mapLimiteToRow = (a) => ({
    id: a.consumoCupo.cantidadMeses,
    tipoLimite: a.tipoLimite.descripcion,
    cantidadMeses: a.consumoCupo.cantidadMesesLetras,
    ordinarias: a.consumoCupo.cantidadCupos,
    ampliaciones: a.consumoCupo.extraordinarios,
    consumidas: a.consumoCupo.consumidos,
    restantes:
      a.consumoCupo.cantidadCupos + a.consumoCupo.extraordinarios - a.consumoCupo.consumidos,
    tieneCupo: a.consumoCupo.restantesConExtraordinarias > 0 ? '1' : '0',
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: 'Cupos',
      flex: 1,
      renderCell: (a) => <DataCollapseDrawer limite={a.row} />,
    },
    { field: 'tipoLimite', headerName: 'Tipo Límite', width: 160 },
    { field: 'cantidadMeses', headerName: 'Cant. Meses', width: 180 },
    { field: 'ordinarias', headerName: 'Ordinarias', flex: 0.1 },
    { field: 'ampliaciones', headerName: 'Ampliaciones', flex: 0.1 },
    { field: 'consumidas', headerName: 'Consumidas', flex: 0.1 },
    { field: 'restantes', headerName: 'Restantes', flex: 0.1 },
  ];
  const handleBorrarMensaje = () => {
    setAlertResultFind('');
  };

  return (
    <div className='container'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonBack marginBotton='1' variant='text' size='small'></ButtonBack>
          <Card sx={{ width: '100%', p: 1 }}>
            <CardContent style={customStyle}>
              <Typography gutterBottom variant='h5' style={customStyle}>
                Consultar cupo de consultas y prácticas
              </Typography>
              <Typography gutterBottom style={customStyle}>
                Acá podrás consultar el cupo de la práctica seleccionada para un afiliado
              </Typography>
            </CardContent>
            {visibleFind && (
              <form style={customStyle} onSubmit={handleSubmit(onSubmitConsultarCupo)} noValidate>
                <CardContent style={customStyle}>
                  <FormControl
                    style={customStyle}
                    fullWidth={true}
                    variant='outlined'
                    margin='normal'
                  >
                    <AfiliadoSelect
                      key={keyComponentAfiliado}
                      ref={register('afiliadoRegister', {
                        validate: validateAfiliado,
                      })}
                      onSelectAfiliado={onSelectAfiliado}
                    />
                    {errors.afiliadoRegister && (
                      <p style={{ color: 'red' }}>Debe seleccionar el afiliado</p>
                    )}
                  </FormControl>
                  <FormControl fullWidth={true} variant='outlined' margin='normal'>
                    <PracticaAutocomplete
                      key={keyComponentAfiliado + '_practica'}
                      size='small'
                      ref={register('practicaRegister', {
                        validate: validatePractica,
                      })}
                      onSelectPractica={handlerSelectPractica}
                    />
                    {errors.practicaRegister != null && (
                      <p style={{ color: 'red' }}>Debe seleccionar la práctica</p>
                    )}
                  </FormControl>
                </CardContent>

                <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2 }}
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                  >
                    <Button
                      variant='contained'
                      startIcon={<HighlightOff />}
                      color='neutral'
                      onClick={clean}
                    >
                      Limpiar
                    </Button>

                    <LoadingButton
                      fullWidth={true}
                      variant='contained'
                      size='medium'
                      color='primary'
                      startIcon={<Search />}
                      type='submit'
                      loading={loading ? 'show' : 'hide'}
                      content={'Buscar'}
                    />
                  </Stack>
                </CardActions>
              </form>
            )}
            {!visibleFind && (
              <Grid container direction='column' justifyContent='space-between' spacing={1} p={2}>
                <Grid item>
                  <Alert icon={false} severity='info'>
                    <b style={{ color: '#1d1e1eb0' }}>
                      {selectedAfiliado?.personaFisica?.apellido},{' '}
                      {selectedAfiliado?.personaFisica?.nombre} (
                      {selectedAfiliado?.personaFisica?.numeroDocumentoString})
                    </b>
                    {cupo?.practicaVO.descripcion && (
                      <>
                        <br />
                        <b style={{ color: '#1d1e1eb0' }}>Práctica:</b>{' '}
                        {cupo?.practicaVO.descripcion}
                      </>
                    )}
                  </Alert>
                </Grid>
                {esMobile && (
                  <Grid item>
                    {tieneCupo && (
                      <Grid item>
                        <Alert
                          icon={false}
                          variant='outlined'
                          severity='success'
                          action={
                            <Button color='inherit' size='small' onClick={() => handleVerMas()}>
                              {`${mensajeVer || ''}`}
                            </Button>
                          }
                        >
                          <b>Posee Cupo</b>
                        </Alert>
                      </Grid>
                    )}
                    {!tieneCupo && (
                      <Grid item>
                        <Alert
                          icon={false}
                          variant='outlined'
                          severity='error'
                          action={
                            <Button color='inherit' size='small' onClick={() => handleVerMas()}>
                              {`${mensajeVer || ''}`}
                            </Button>
                          }
                        >
                          <b>No Posee Cupo</b>
                        </Alert>
                      </Grid>
                    )}
                  </Grid>
                )}
                {visibleDataGrid && (
                  <Grid item>
                    <div style={{ height: '50vh', width: '100%' }}>
                      <StyledDataGridCupo
                        getRowClassName={(params) => `super-app-theme--${params.row.tieneCupo}`}
                        sx={{
                          border: 0,
                          '& .MuiDataGrid-cell': {
                            py: '8px',
                          },
                        }}
                        loading={!limites.length}
                        rows={limites.map((a) => mapLimiteToRow(a))}
                        columns={columns}
                        getRowHeight={() => 'auto'}
                        getEstimatedRowHeight={() => 200}
                        columnVisibilityModel={{
                          desplegable: screenWidth === 'xs',
                          tipoLimite: screenWidth !== 'xs',
                          cantidadMeses: screenWidth !== 'xs',
                          ordinarias: screenWidth !== 'xs',
                          ampliaciones: screenWidth !== 'xs',
                          consumidas: screenWidth !== 'xs',
                          restantes: screenWidth !== 'xs',
                        }}
                        pagination
                        autoPageSize
                        disableColumnMenu
                      />
                    </div>
                  </Grid>
                )}
                <Grid item>
                  <Grid container justifyContent='flex-end' spacing={1}>
                    <Grid item>
                      <Button
                        variant='contained'
                        color='neutral'
                        startIcon={<Search />}
                        onClick={() => handleBuscarOtro()}
                      >
                        Buscar otro
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Card>
        </Grid>
        <CustomizedSnackbars
          open={alertResultFind}
          severity='info'
          message={alertResultFind}
          onDeleteMessage={handleBorrarMensaje}
        />
      </Grid>
    </div>
  );
};

export default ConsultarCupo;
