import { useEffect, useState } from 'react';
import { getNotificaciones } from '../../../services/Busquedas/Afiliados';
import { useInfoAfiliado } from '../../../components/Afiliados/InfoAfiliadoProvider';

export const useNotificaciones = () => {
  const { infoAfiliado } = useInfoAfiliado();
  const [notificacionesList, setNotificacionesList] = useState([]);

  useEffect(() => {
    if (infoAfiliado) {
      const getNotificacionesRequest = async () => {
        const result = await getNotificaciones(infoAfiliado?.afiliado?.idAfiliado);
        setNotificacionesList(result);
      };

      getNotificacionesRequest();
    }
  }, [infoAfiliado]);

  return {
    notificacionesList,
    notificacionesCount: notificacionesList?.length || null,
  };
};
