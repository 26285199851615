import { HighlightOff } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import DateTextInput from '../Common/DateTextInput';
import { useForm } from 'react-hook-form';
import { findRecetasElectronicas } from '../../services/Busquedas/RecetasElectronicas';
import LoadingButton from '../../@iosper/components/LoadingButton';
import CustomizedSnackbars from '../Notifications/SnackBar';
import RecetaElectronicaTableResult from './RecetaElectronicaTableResult';
import AfiliadoSelect from '../Afiliados/AfiliadoSelect';
import ButtonBack from '../Breadcrumb/ButtonBack';

const BuscarRecetas = () => {
  const [error, setError] = useState('');
  const [selectedAfiliado, setSelectedAfiliado] = useState(null);
  const [keyComponentAfiliado, setKeyComponentAfiliado] = useState(0);
  const [documentoAfiliado, setDocumentoAfiliado] = useState('');
  const [loading, setLoading] = useState(false);
  const [visibleFind, setVisibleFind] = useState(true);
  const [recetasElectronicas, setRecetasElectronicas] = useState([]);
  const [alertResultFind, setAlertResultFind] = useState('');

  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const getData = () => {
    let data = {
      idAfiliado: selectedAfiliado.afiliado.idAfiliado,
      fechaDesde: fechaDesde,
      fechaHasta: fechaHasta,
    };
    return data;
  };

  const refresh = () => {
    //onBuscar(getData())
  };

  /*const find =  (data, e) => {
    e.preventDefault();
    onBuscar(getData())
    
  }*/
  const find = async (data, e) => {
    e.preventDefault();
    setRecetasElectronicas([]);
    setLoading(true);
    setAlertResultFind('');
    setError('');

    if (
      data.fechaDesde === null &&
      data.fechaDesde === null &&
      (selectedAfiliado === null || selectedAfiliado === '')
    ) {
      setError();
      setError('Debes ingresar por lo menos un criterio para realizar la búsqueda');
      setLoading(false);
    } else {
      try {
        const result = await findRecetasElectronicas({
          idAfiliado: selectedAfiliado.afiliado.idAfiliado,
          fechaDesde: data.fechaDesde,
          fechaHasta: data.fechaHasta,
        });
        if (result?.length) {
          setRecetasElectronicas(result);
          setVisibleFind(false);
        } else {
          setAlertResultFind('Busqueda sin resultados, intente con otros parámetros.');
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const handleVolver = () => {
    setRecetasElectronicas([]);
    setVisibleFind(true);
    //setSelectedAfiliado(null);
    handlerLimpiar();
  };

  const validateAfiliado = () => {
    return selectedAfiliado == null ? false : true;
  };

  const handleSelectAfiliado = async (afiliado) => {
    setSelectedAfiliado(afiliado);
  };

  const handleSetFechaHasta = async (fechaHasta) => {
    setFechaHasta(fechaHasta);
  };

  const handleSetFechaDesde = async (fechaDesde) => {
    setFechaDesde(fechaDesde);
  };

  const handlerLimpiar = () => {
    setKeyComponentAfiliado(1 + Math.random() * (1000 - 1));
    reset();
    setDocumentoAfiliado('');
    setSelectedAfiliado(null);
    setFechaDesde(null);
    setFechaHasta(null);
  };
  const handleBorrarMensaje = () => {
    setError('');
    setAlertResultFind('');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ButtonBack marginBotton='1' variant='text' size='small'></ButtonBack>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Buscar Receta Electrónica
            </Typography>
            <Typography variant='string' component={'div'}>
              Acá podrás consultar las recetas electrónicas generadas en el rango de fechas
              seleccionado.
            </Typography>
          </CardContent>

          {visibleFind && (
            <form>
              <CardContent>
                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <AfiliadoSelect
                    key={keyComponentAfiliado}
                    ref={register('afiliadoRegister', {
                      validate: validateAfiliado,
                    })}
                    onSelectAfiliado={handleSelectAfiliado}
                  />
                  {errors.afiliadoRegister && (
                    <p style={{ color: 'red' }}>Debe seleccionar el afiliado</p>
                  )}
                </FormControl>

                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <DateTextInput
                    register={register}
                    errors={setError}
                    required={false}
                    label='Desde'
                    fieldName='fechaDesde'
                    change={handleSetFechaDesde}
                  />
                  {errors['fechaDesde'] && (
                    <p style={{ color: 'red' }}>{errors['fechaDesde'].message}</p>
                  )}
                </FormControl>
                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <DateTextInput
                    register={register}
                    errors={setError}
                    required={false}
                    label='Hasta'
                    fieldName='fechaHasta'
                    change={handleSetFechaHasta}
                  />
                  {errors['fechaHasta'] && (
                    <p style={{ color: 'red' }}>{errors['fechaHasta'].message}</p>
                  )}
                </FormControl>
              </CardContent>

              <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2 }}
                  justifyContent={{ xs: 'center', sm: 'flex-end' }}
                  alignItems={{ xs: 'strech' }}
                >
                  <Button
                    variant='contained'
                    size='medium'
                    startIcon={<HighlightOff />}
                    color='neutral'
                    onClick={handlerLimpiar}
                  >
                    Limpiar
                  </Button>
                  <LoadingButton
                    fullWidth={true}
                    type='submit'
                    variant='contained'
                    color='primary'
                    loading={loading ? 'show' : 'hide'}
                    content={'Buscar'}
                    startIcon={<SearchIcon />}
                    onClick={handleSubmit(find)}
                  />
                </Stack>
              </CardActions>
            </form>
          )}
          {!visibleFind && (
            <Grid container direction='column' justifyContent='space-between' spacing={1}>
              <Grid item>
                <RecetaElectronicaTableResult
                  recetasElectronicas={recetasElectronicas}
                  onRefresh={refresh}
                  selectedAfiliado={selectedAfiliado}
                />
              </Grid>
              <Grid item sx={{ padding: '0 16px 16px' }}>
                <Grid container justifyContent='flex-end' spacing={1}>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='neutral'
                      startIcon={<SearchIcon />}
                      onClick={() => handleVolver()}
                    >
                      Buscar Otras
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>

      <CustomizedSnackbars
        open={alertResultFind}
        severity='info'
        message={alertResultFind}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default BuscarRecetas;
