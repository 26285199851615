import React, { useEffect, useState } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  FormControl,
  CardActions,
  Divider,
  TextField,
  ListItemButton,
  ListItemIcon,
  Collapse,
  Typography,
} from '@mui/material';
import { Edit as EditIcon, Save, ExpandMore, ExpandLess } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import DrawerDataField from '../../Common/DrawerDataField';
import LoadingButton from '../../../@iosper/components/LoadingButton';
import ProvinciaAutocomplete from '../../Common/ProvinciaAutocomplete';
import DepartamentoAutocomplete from '../../Common/DepartamentoAutocomplete';
import LocalidadSimpleAutocomplete from '../../Common/LocalidadSimpleAutocomplete';
import CalleAutocomplete from '../../Common/CalleAutocomplete';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { agregarDomicilio, constantes } from '../../../services/Common/Domicilio';
import CustomizedSnackbars from '../../Notifications/SnackBar';

const DomicilioForm = React.forwardRef((props, ref) => {
  const [editarDomicilio, setEditarDomicilio] = useState(false);
  const [provincia, setProvincia] = useState(null);
  const [departamento, setDepartamento] = useState(null);
  const [localidad, setLocalidad] = useState(null);
  const [calle, setCalle] = useState(null);
  const [domicilio, setDomicilio] = useState(null);
  const [numero, setNumero] = useState(null);
  const [piso, setPiso] = useState(null);
  const [depto, setDepto] = useState(null);
  const [casa, setCasa] = useState(null);
  const [manzana, setManzana] = useState(null);
  const [tira, setTira] = useState(null);
  const [torre, setTorre] = useState(null);
  const [cuerpo, setCuerpo] = useState(null);
  const [km, setKm] = useState(null);
  const [selectedProvincia, setSelectedProvincia] = useState(null);
  const [selectedDepartamento, setSelectedDepartamento] = useState(null);
  const [selectedLocalidad, setSelectedLocalidad] = useState(null);
  const [selectedCalle, setSelectedCalle] = useState(null);

  const [keyComponentProvincia, setKeyComponentProvincia] = useState(0);
  const [keyComponentDepartamento, setKeyComponentDepartamento] = useState(0);
  const [keyComponentLocalidad, setKeyComponentLocalidad] = useState(0);
  const [keyComponentCalle, setKeyComponentCalle] = useState(0);
  const [openMasTipoNumeracion, setOpenMasTipoNumeracion] = React.useState(false);

  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const [success, setSuccess] = useState('');

  const handleClickCollapse = () => {
    setOpenMasTipoNumeracion(!openMasTipoNumeracion);
  };

  useEffect(() => {
    if (domicilio == null) {
      const torre = props.infoAfiliado?.itemsDomicilioPersonaFisica?.filter(
        (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_TORRE,
      );
      const piso = props.infoAfiliado?.itemsDomicilioPersonaFisica?.filter(
        (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_PISO,
      );
      const manzana = props.infoAfiliado?.itemsDomicilioPersonaFisica?.filter(
        (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_MANZANA,
      );
      const depto = props.infoAfiliado?.itemsDomicilioPersonaFisica?.filter(
        (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_DEPTO,
      );
      const casa = props.infoAfiliado?.itemsDomicilioPersonaFisica?.filter(
        (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_CASA,
      );
      const numero = props.infoAfiliado?.itemsDomicilioPersonaFisica?.filter(
        (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_NUMERO,
      );

      const cuerpo = props.infoAfiliado?.itemsDomicilioPersonaFisica?.filter(
        (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_CUERPO,
      );
      const tira = props.infoAfiliado?.itemsDomicilioPersonaFisica?.filter(
        (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_TIRA,
      );
      const km = props.infoAfiliado?.itemsDomicilioPersonaFisica?.filter(
        (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_KM,
      );

      if (numero?.length > 0) {
        setNumero(numero[0]);
      }
      if (piso?.length > 0) {
        setPiso(piso[0]);
      }
      if (depto?.length > 0) {
        setDepto(depto[0]);
      }
      if (casa?.length > 0) {
        setCasa(casa[0]);
      }
      if (manzana?.length > 0) {
        setManzana(manzana[0]);
      }
      if (tira?.length > 0) {
        setTira(tira[0]);
      }
      if (torre?.length > 0) {
        setTorre(torre[0]);
      }
      if (cuerpo?.length > 0) {
        setCuerpo(cuerpo[0]);
      }
      if (km?.length > 0) {
        setKm(km[0]);
      }

      setDomicilio(props.infoAfiliado?.domicilioPersonaFisica);
      setProvincia(props.infoAfiliado?.provincia);
      setDepartamento(props.infoAfiliado?.departamento);
      setLocalidad(props.infoAfiliado?.localidad);
      setCalle(props.infoAfiliado?.calle);
    }
  }, [props.infoAfiliado]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const onClickEditarDomicilio = async () => {
    setEditarDomicilio(true);
  };

  const handlerGuardarSubmit = (event) => {
    setSuccess('');
    const afiliado = props.infoAfiliado.afiliado;
    const domi = {};
    const itemsDomicilio = {};
    domi.afiliado = afiliado;
    domi.calle = selectedCalle;
    domi.domicilioPersonaFisica = domi.calle.idCalle === domicilio.idCalle ? domicilio : null;

    if (numero) {
      itemsDomicilio['numero'] = numero;
    }
    if (piso) {
      itemsDomicilio['piso'] = piso;
    }
    if (depto) {
      itemsDomicilio['depto'] = depto;
    }
    if (casa) {
      itemsDomicilio['casa'] = casa;
    }
    if (manzana) {
      itemsDomicilio['manzana'] = manzana;
    }
    if (tira) {
      itemsDomicilio['tira'] = tira;
    }
    if (casa) {
      itemsDomicilio['casa'] = casa;
    }
    if (torre) {
      itemsDomicilio['torre'] = torre;
    }
    if (cuerpo) {
      itemsDomicilio['cuerpo'] = cuerpo;
    }
    if (km) {
      itemsDomicilio['km'] = km;
    }
    //domi.numero= numero ;domi.casa= casa;
    // domi.km= km;   domi.piso= piso;  domi.tira= tira;  domi.manzana= manzana;
    // domi.depto = depto;  domi.torre= torre;  domi.cuerpo = cuerpo;
    domi.itemsDomicilioPersonaFisica = itemsDomicilio;

    let agregarDomicilioResponse = agregarDomicilio(domi);
    agregarDomicilioResponse
      .then((result) => {
        if (result.status !== undefined && result.status === 'error') {
          setWarning(result.message);
        } else {
          setEditarDomicilio(false);
          setSuccess('Domicilio guardado con éxito!.');
          setDomicilio(result.domicilio.domicilioPersonaFisica);
          setProvincia(result.domicilio.provincia);
          setDepartamento(result.domicilio.departamento);
          setLocalidad(result.domicilio.localidad);
          setCalle(result.domicilio.calle);
          const torre = result.itemsDomicilioPersonaFisica?.filter(
            (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_TORRE,
          );
          const piso = result.itemsDomicilioPersonaFisica?.filter(
            (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_PISO,
          );
          const manzana = result.itemsDomicilioPersonaFisica?.filter(
            (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_MANZANA,
          );
          const depto = result.itemsDomicilioPersonaFisica?.filter(
            (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_DEPTO,
          );
          const casa = result.itemsDomicilioPersonaFisica?.filter(
            (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_CASA,
          );
          const numero = result.itemsDomicilioPersonaFisica?.filter(
            (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_NUMERO,
          );

          const cuerpo = result.itemsDomicilioPersonaFisica?.filter(
            (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_CUERPO,
          );
          const tira = result.itemsDomicilioPersonaFisica?.filter(
            (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_TIRA,
          );
          const km = result.itemsDomicilioPersonaFisica?.filter(
            (n) => n.codigoTipoNumeracion === constantes.TIPO_NUMERACION_KM,
          );

          if (numero?.length > 0) {
            setNumero(numero[0]);
          }
          if (piso?.length > 0) {
            setPiso(piso[0]);
          }
          if (depto?.length > 0) {
            setDepto(depto[0]);
          }
          if (casa?.length > 0) {
            setCasa(casa[0]);
          }
          if (manzana?.length > 0) {
            setManzana(manzana[0]);
          }
          if (tira?.length > 0) {
            setTira(tira[0]);
          }
          if (torre?.length > 0) {
            setTorre(torre[0]);
          }
          if (cuerpo?.length > 0) {
            setCuerpo(cuerpo[0]);
          }
          if (km?.length > 0) {
            setKm(km[0]);
          }
        }
      })
      .catch((e) => {
        if (e.data.status !== undefined && e.data.status === 'error') {
          setError(e.data.message);
        }
      });
  };

  const clean = () => {
    setKeyComponentProvincia(1 + Math.random() * (1000 - 1));
    setKeyComponentDepartamento(1 + Math.random() * (1000 - 1));
    setKeyComponentLocalidad(1 + Math.random() * (1000 - 1));
    setKeyComponentCalle(1 + Math.random() * (1000 - 1));
    setSelectedProvincia(null);
    setSelectedDepartamento(null);
    setSelectedLocalidad(null);
    setSelectedCalle(null);
    reset();
  };

  const handlerSelectProvincia = (provincia) => {
    setSelectedProvincia(provincia);
    setKeyComponentDepartamento(1 + Math.random() * (1000 - 1));
    setKeyComponentLocalidad(1 + Math.random() * (1000 - 1));
    setKeyComponentCalle(1 + Math.random() * (1000 - 1));
    setSelectedDepartamento(null);
    setSelectedLocalidad(null);
    setSelectedCalle(null);
  };
  const handlerSelectDepartamento = (departamento) => {
    setSelectedDepartamento(departamento);
    setKeyComponentLocalidad(1 + Math.random() * (1000 - 1));
    setKeyComponentCalle(1 + Math.random() * (1000 - 1));
    setSelectedLocalidad(null);
    setSelectedCalle(null);
  };
  const handlerSelectLocalidad = (localidad) => {
    setSelectedLocalidad(localidad);
    setKeyComponentCalle(1 + Math.random() * (1000 - 1));
    setSelectedCalle(null);
  };
  const handlerSelectCalle = (calle) => {
    setSelectedCalle(calle);
  };

  const validateProvincia = () => {
    return selectedProvincia == null ? false : true;
  };

  const validateDepartamento = () => {
    return selectedDepartamento == null ? false : true;
  };

  const validateLocalidad = () => {
    return selectedLocalidad == null ? false : true;
  };

  const validateCalle = () => {
    return selectedCalle == null ? false : true;
  };

  const handleChangeNumero = (e) => {
    let numero = {};
    numero['valor'] = e.target.value;
    numero['codigoTipoNumeracion'] = constantes.TIPO_NUMERACION_NUMERO;
    setNumero(numero);
  };
  const handleChangeCasa = (e) => {
    let casa = {};
    casa['valor'] = e.target.value;
    casa['codigoTipoNumeracion'] = constantes.TIPO_NUMERACION_CASA;
    setCasa(casa);
  };
  const handleChangeTira = (e) => {
    let tira = {};
    tira['valor'] = e.target.value;
    tira['codigoTipoNumeracion'] = constantes.TIPO_NUMERACION_TIRA;
    setTira(tira);
  };
  const handleChangeDepto = (e) => {
    let depto = {};
    depto['valor'] = e.target.value;
    depto['codigoTipoNumeracion'] = constantes.TIPO_NUMERACION_DEPTO;
    setDepto(depto);
  };
  const handleChangeKm = (e) => {
    let km = {};
    km['valor'] = e.target.value;
    km['codigoTipoNumeracion'] = constantes.TIPO_NUMERACION_KM;
    setKm(km);
  };
  const handleChangePiso = (e) => {
    let piso = {};
    piso['valor'] = e.target.value;
    piso['codigoTipoNumeracion'] = constantes.TIPO_NUMERACION_PISO;
    setPiso(piso);
  };
  const handleChangeManzana = (e) => {
    let manzana = {};
    manzana['valor'] = e.target.value;
    manzana['codigoTipoNumeracion'] = constantes.TIPO_NUMERACION_MANZANA;
    setManzana(manzana);
  };
  const handleChangeTorre = (e) => {
    let torre = {};
    torre['valor'] = e.target.value;
    torre['codigoTipoNumeracion'] = constantes.TIPO_NUMERACION_TORRE;
    setTorre(torre);
  };
  const handleChangeCuerpo = (e) => {
    let cuerpo = {};
    cuerpo['valor'] = e.target.value;
    cuerpo['codigoTipoNumeracion'] = constantes.TIPO_NUMERACION_CUERPO;
    setCuerpo(cuerpo);
  };

  const handleBorrarMensaje = () => {
    setError('');
    setWarning('');
    setSuccess('');
  };

  return (
    <Grid item xs={12} mt={3} lg={9} sx={{ mt: 0 }}>
      <Card sx={{ width: '100%', p: 1 }}>
        <CardContent sx={{ marginTop: -4, marginBottom: -5 }}>
          <List>
            <ListItem disableGutters>
              {!editarDomicilio && (
                <ListItemText
                  primary='Domicilio Real Actual (Residencia)'
                  primaryTypographyProps={{
                    fontSize: '1.2rem',
                    fontWeight: 'medium',
                  }}
                />
              )}
              {editarDomicilio && (
                <ListItemText
                  primary='Editar Domicilio'
                  primaryTypographyProps={{
                    fontSize: '1.2rem',
                    fontWeight: 'medium',
                  }}
                />
              )}
              <Tooltip id='button-link' title='Editar Domicilio'>
                <IconButton
                  disabled={editarDomicilio}
                  aria-label='editar domicilio'
                  onClick={onClickEditarDomicilio}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </ListItem>
          </List>
          {editarDomicilio && (
            <Grid>
              <form onSubmit={handleSubmit(handlerGuardarSubmit)}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4} sx={{ paddingBottom: -5, paddingTop: -5 }}>
                    <FormControl fullWidth={true} variant='outlined'>
                      <ProvinciaAutocomplete
                        field
                        key={keyComponentProvincia}
                        onDefaultProvincia={provincia}
                        onSelectProvincia={handlerSelectProvincia}
                        ref={register('provinciaRegister', {
                          validate: validateProvincia,
                        })}
                      />
                      {errors.provinciaRegister && (
                        <p style={{ color: 'red' }}>Debe seleccionar la provincia</p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ paddingBottom: -5, paddingTop: -5 }}>
                    <FormControl fullWidth={true} variant='outlined'>
                      <DepartamentoAutocomplete
                        key={keyComponentDepartamento}
                        filterByIdProvincia={selectedProvincia?.idProvincia}
                        onDefaultDepartamento={departamento}
                        onSelectDepartamento={handlerSelectDepartamento}
                        ref={register('departamentoRegister', {
                          validate: validateDepartamento,
                        })}
                      />
                      {errors.departamentoRegister && (
                        <p style={{ color: 'red' }}>Debe seleccionar el departamento</p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ paddingBottom: -5, paddingTop: -5 }}>
                    <FormControl fullWidth={true} variant='outlined'>
                      <LocalidadSimpleAutocomplete
                        key={keyComponentLocalidad}
                        filterByIdDepartamento={selectedDepartamento?.idDepartamento}
                        onDefaultLocalidad={localidad}
                        onSelectLocalidad={handlerSelectLocalidad}
                        ref={register('localidadRegister', {
                          validate: validateLocalidad,
                        })}
                      />
                      {errors.localidadRegister && (
                        <p style={{ color: 'red' }}>Debe seleccionar la localidad</p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormControl fullWidth={true} variant='outlined'>
                      <CalleAutocomplete
                        key={keyComponentCalle}
                        filterByIdLocalidad={selectedLocalidad?.idLocalidad}
                        onDefaultCalle={calle}
                        onSelectCalle={handlerSelectCalle}
                        ref={register('calleRegister', {
                          validate: validateCalle,
                        })}
                      />
                      {errors.calleRegister && (
                        <p style={{ color: 'red' }}>Debe seleccionar la calle</p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth={true} variant='outlined'>
                      <TextField
                        id='numero'
                        label='Número'
                        variant='standard'
                        defaultValue={numero?.valor}
                        fullWidth
                        {...register('numero', {
                          required: {
                            value: true,
                            message: 'Debe ingresar un número',
                          },
                          maxLength: {
                            value: 4,
                            message: 'Número debe tener 4 dígitos.',
                          },
                          minLength: {
                            value: 1,
                            message: 'Número debe tener 1 dígitos.',
                          },
                          pattern: {
                            value: /^((([0-9]{1,4}|s\/n)))$/,
                            message:
                              'El número no parecer ser válido, recuerde que debe ser un número entre el 1 y el 9999 o ingresar s/n',
                          },
                        })}
                        onChange={handleChangeNumero}
                      />
                      {errors['numero'] && (
                        <p style={{ color: 'red' }}>{errors['numero'].message}</p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth={true} variant='outlined'>
                      <TextField
                        id='piso'
                        label='Piso'
                        variant='standard'
                        defaultValue={piso?.valor}
                        fullWidth
                        {...register('piso', {
                          pattern: {
                            value: /^(([a-zA-Z0-9]{1,10}))$/,
                            message:
                              'Longitud máxima superada, recuerde que no debe superar los 10 caracteres',
                          },
                        })}
                        onChange={handleChangePiso}
                      />
                      {errors['piso'] && <p style={{ color: 'red' }}>{errors['piso'].message}</p>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth={true} variant='outlined'>
                      <TextField
                        id='depto'
                        label='Depto.'
                        variant='standard'
                        value={depto?.valor}
                        fullWidth
                        {...register('depto', {
                          pattern: {
                            value: /^(([a-zA-Z0-9]{0,4}))$/,
                            message:
                              'El departamento no parece ser válido, recuerde que debe ingresar letras o números no más de 4 caracteres',
                          },
                        })}
                        onChange={handleChangeDepto}
                      />
                      {errors['depto'] && <p style={{ color: 'red' }}>{errors['depto'].message}</p>}
                    </FormControl>
                  </Grid>
                </Grid>

                <List
                  sx={{ width: '100%', paddingLeft: 0, bgcolor: 'background.paper' }}
                  component='nav'
                  aria-labelledby='nested-list-subheader'
                >
                  <FormControl fullWidth={true} variant='outlined' margin='normal'>
                    <ListItemButton onClick={handleClickCollapse}>
                      <ListItemIcon>
                        <ReadMoreIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontWeight: 'bold' }} style={{ color: '#000000' }}>
                            Más opciones
                          </Typography>
                        }
                      />
                      {openMasTipoNumeracion ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </FormControl>
                </List>

                <Collapse in={openMasTipoNumeracion} timeout='auto' unmountOnExit>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth={true} variant='outlined'>
                        <TextField
                          id='casa'
                          label='Casa'
                          variant='standard'
                          defaultValue={casa?.valor}
                          fullWidth
                          {...register('casa', {
                            pattern: {
                              value: /^(([a-zA-Z0-9]{1,10}))$/,
                              message:
                                'Longitud máxima superada, recuerde que no debe superar los 10 caracteres',
                            },
                          })}
                          onChange={handleChangeCasa}
                        />
                        {errors['casa'] && <p style={{ color: 'red' }}>{errors['casa'].message}</p>}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth={true} variant='outlined'>
                        <TextField
                          id='manzana'
                          label='Manzana'
                          variant='standard'
                          defaultValue={manzana?.valor}
                          fullWidth
                          {...register('manzana', {
                            pattern: {
                              value: /^(([a-zA-Z0-9]{1,10}))$/,
                              message:
                                'Longitud máxima superada, recuerde que no debe superar los 10 caracteres',
                            },
                          })}
                          onChange={handleChangeManzana}
                        />
                        {errors['manzana'] && (
                          <p style={{ color: 'red' }}>{errors['manzana'].message}</p>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth={true} variant='outlined'>
                        <TextField
                          id='tira'
                          label='Tira'
                          variant='standard'
                          defaultValue={tira?.valor}
                          fullWidth
                          {...register('tira', {
                            pattern: {
                              value: /^(([a-zA-Z0-9]{1,10}))$/,
                              message:
                                'Longitud máxima superada, recuerde que no debe superar los 10 caracteres',
                            },
                          })}
                          onChange={handleChangeTira}
                        />
                        {errors['tira'] && <p style={{ color: 'red' }}>{errors['tira'].message}</p>}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth={true} variant='outlined'>
                        <TextField
                          id='torre'
                          label='Torre'
                          variant='standard'
                          defaultValue={torre?.valor}
                          fullWidth
                          {...register('torre', {
                            pattern: {
                              value: /^(([a-zA-Z0-9]{1,10}))$/,
                              message:
                                'Longitud máxima superada, recuerde que no debe superar los 10 caracteres',
                            },
                          })}
                          onChange={handleChangeTorre}
                        />
                        {errors['torre'] && (
                          <p style={{ color: 'red' }}>{errors['torre'].message}</p>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth={true} variant='outlined'>
                        <TextField
                          id='cuerpo'
                          label='Cuerpo'
                          variant='standard'
                          defaultValue={cuerpo?.valor}
                          fullWidth
                          {...register('cuerpo', {
                            pattern: {
                              value: /^(([a-zA-Z0-9]{1,10}))$/,
                              message:
                                'Longitud máxima superada, recuerde que no debe superar los 10 caracteres',
                            },
                          })}
                          onChange={handleChangeCuerpo}
                        />
                        {errors['cuerpo'] && (
                          <p style={{ color: 'red' }}>{errors['cuerpo'].message}</p>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth={true} variant='outlined'>
                        <TextField
                          id='km'
                          label='Km.'
                          variant='standard'
                          defaultValue={km?.valor}
                          fullWidth
                          {...register('km', {
                            pattern: {
                              value: /^(([a-zA-Z0-9]{1,10}))$/,
                              message:
                                'Longitud máxima superada, recuerde que no debe superar los 10 caracteres',
                            },
                          })}
                          onChange={handleChangeKm}
                        />
                        {errors['km'] && <p style={{ color: 'red' }}>{errors['km'].message}</p>}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Collapse>

                <CardActions sx={{ justifyContent: 'flex-end', p: 3 }}>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2 }}
                    justifyContent={{ xs: 'center', sm: 'flex-end', sx: 'flex-end' }}
                    alignItems={{ xs: 'strech' }}
                  >
                    {/*<Button
                                  color="neutral" variant="contained"
                                  startIcon={<Cancel />}
                                  onClick={()=>{setEditarDomicilio(false)}} >
                                  Cancelar
                            </Button>*/}
                    <LoadingButton
                      variant='contained'
                      size='medium'
                      color='primary'
                      type='submit'
                      startIcon={<Save />}
                      content={'Guardar Domicilio'}
                    />
                  </Stack>
                </CardActions>
              </form>
            </Grid>
          )}
          {!editarDomicilio && (
            <List sx={{ marginTop: -2 }}>
              <DrawerDataField
                label='Dirección'
                data={`${calle?.descripcion || ''} ${numero?.valor || ''} 
                      ${casa?.valor ? '- Casa ' + casa?.valor : ''}
                      ${depto?.valor ? '- Depto ' + depto?.valor : ''}
                      ${manzana?.valor ? '- Manz. ' + manzana?.valor : ''}
                      ${piso?.valor ? '- Piso ' + piso?.valor : ''}
                      ${cuerpo?.valor ? '- Cuerpo ' + cuerpo?.valor : ''}
                      ${torre?.valor ? '- Torre ' + torre?.valor : ''}
                      ${tira?.valor ? '- Tira ' + tira?.valor : ''}
                      ${km?.valor ? '- Km. ' + km?.valor : ''}
                      `}
              />
              <Divider />
              <DrawerDataField label='Localidad' data={`${localidad?.descripcion || ''}`} />
              <Divider />
              <DrawerDataField label='Departamento' data={`${departamento?.descripcion || ''}`} />
              <Divider />
              <DrawerDataField label='Provincia' data={`${provincia?.descripcion || ''}`} />
            </List>
          )}
        </CardContent>
      </Card>
      <CustomizedSnackbars
        open={success}
        severity='success'
        message={success}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={warning}
        severity='warning'
        message={warning}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
});

export default DomicilioForm;
