import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { esES as coreEsEs } from '@mui/material/locale';
import { esES } from '@mui/x-data-grid';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const theme = createTheme(
  {
    palette: {
      neutral: {
        main: '#e0e0e0',
        contrastText: 'rgba(2,37,34,0.96)',
      },
      primary: {
        main: '#005a55',
        contrastText: 'rgba(255,255,255,0.93)',
      },
      secondary: {
        main: '#b12823',
      },
      text: {
        neutral: 'rgba(2,37,34,0.96)',
      },
    },
    typography: {
      subtitle1: {
        color: '#5f6368',
      },
      button: {
        fontWeight: 400,
      },
      body1: {
        color: '#1A2027',
      },
      body2: {
        color: 'rgba(0, 0, 0, 0.6)',
      },
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    components: {
      MuiCard: {
        defaultProps: {
          variant: 'elevation',
          elevation: 2,
        },
        styleOverrides: {
          root: {
            p: 1,
            borderRadius: '10px',
            boxShadow:
              '0px 1px 5px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 3px 1px -2px rgb(0 0 0 / 10%)',
          },
        },
      },
    },
  },
  esES,
  coreEsEs,
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </StyledEngineProvider>,
);

serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    // Corremos este código si hay una nueva versión de nuestra app
    // Detalles en: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration && registration.waiting) {
      await registration.unregister();
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      // Des-registramos el SW para recargar la página y obtener la nueva versión. Lo cual permite que el navegador descargue lo nuevo y que invalida la cache que tenía previamente.
      window.location.reload();
    }
  },
});
