import { axiosPost } from '../ServiceBase.js';

/**
 * crearCuenta api call
 * @returns
 */
export const validarCrearCuenta = async (cuenta) => {
  const afiliado = await axiosPost('/v1/cuenta/validar-crear-cuenta', cuenta);
  return afiliado;
};

/**
 * verificarIdentidad api call
 * @returns
 */
export const verificarIdentidad = async (cuenta) => {
  const afiliado = await axiosPost('/v1/cuenta/verificar-identidad', cuenta);
  return afiliado;
};

/**
 * verificarIdentidad Por cambio de Pin - api call
 * @returns
 */
export const verificarIdentidadRecuperarPin = async (cuenta) => {
  const afiliado = await axiosPost('/v1/cuenta/verificar-identidad/recuperar-pin', cuenta);
  return afiliado;
};

/**
 * crearCuenta api call
 * @returns
 */
export const crearCuenta = async (cuenta) => {
  /**
     cuenta: {
                "cuil": "20325653907",
                "code":  "mxEKX"   
            }
     */
  const afiliado = await axiosPost('/v1/cuenta/crear-cuenta', cuenta);
  return afiliado;
};
/**
 * agregarCorreoRecuperarPin api call
 * @returns
 */
export const agregarCorreoRecuperarPin = async (cuenta) => {
  /**
     cuenta: {
                "cuil": "20325653907",
                "code":  "mxEKX"   
            }
     */
  const afiliado = await axiosPost('/v1/cuenta/verificar-identidad/agregar-correo', cuenta);
  return afiliado;
};

/**
 *
 * deshabilita la notificacion
 * @returns
 */
export const deshabilitarNotificacion = async (notificacion) => {
  const noti = await axiosPost('/v1/afiliados/deshabilitar-notificacion-pendiente', {
    notificacion: notificacion,
  });
  return noti;
};
