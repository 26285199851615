import React, { useEffect, useState } from 'react';
import {
  ToggleButton,
  Collapse,
  List,
  ListItem,
  FormControl,
  ListItemIcon,
  ListItemText,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { buscarDelegaciones } from '../../services/Busquedas/Delegaciones';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  FmdGood,
  LocalPhone,
  Email,
  AccessTime,
} from '@mui/icons-material';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { useWidth } from '../../@iosper/utils/UseWidth';
import LocalidadAutocomplete from '../Common/LocalidadAutocomplete';

const BuscarDelegaciones = () => {
  const [alertResultFind, setAlertResultFind] = useState('');
  const [delegaciones, setDelegaciones] = useState([]);
  const [delegacionesFiltradas, setDelegacionesFiltradas] = useState([]);
  const [visibleFind, setVisibleFind] = useState(false);
  const screenWidth = useWidth();

  const DataCollapseDrawer = (props) => {
    const { delegacion } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <Box sx={{ width: '100%' }}>
        <ToggleButton
          aria-label='expand row'
          value='check'
          onChange={() => {
            setOpen(!open);
          }}
          sx={{ border: 0, p: 0 }}
          fullWidth={true}
        >
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={2}
            flexWrap='nowrap'
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            <Typography
              variant='body1'
              align='left'
              gutterBottom={false}
              sx={{ fontWeight: 'bold', color: '#000', textTransform: 'none' }}
            >
              {`${delegacion?.nombre || ''}`}
            </Typography>
          </Grid>
        </ToggleButton>

        <Collapse in={open} timeout='auto' unmountOnExit>
          <Box>
            <List sx={{ py: 0 }}>
              {delegacion?.direccion && (
                <ListItem
                  component='a'
                  href={`https://www.google.com/maps/search/${encodeURIComponent(
                    delegacion?.direccion + ', ' + delegacion?.localidad,
                  )}`}
                  sx={{ py: 1, px: 0 }}
                >
                  <ListItemIcon sx={{ color: '#B12823' }}>
                    <FmdGood />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ margin: -3 }}
                    primary={
                      <Typography
                        variant='body2'
                        sx={{ color: '#005a55', textDecoration: 'underline' }}
                      >
                        {`${delegacion?.direccion || ''}`}
                      </Typography>
                    }
                  />
                </ListItem>
              )}
              {delegacion?.telefono !== '-' && (
                <ListItem
                  component='a'
                  href={`tel:${delegacion?.telefono || ''}`}
                  sx={{ py: 1, px: 0 }}
                >
                  <ListItemIcon sx={{ color: '#B12823' }}>
                    <LocalPhone />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ margin: -3 }}
                    primary={
                      <Typography
                        variant='body2'
                        sx={{ color: '#005a55', textDecoration: 'underline' }}
                      >
                        {`${delegacion?.telefono || ''}`}
                      </Typography>
                    }
                  />
                </ListItem>
              )}
              {delegacion?.email !== '-' && (
                <ListItem
                  component='a'
                  href={`mailto:${delegacion?.email || ''}`}
                  sx={{ py: 1, px: 0 }}
                >
                  <ListItemIcon sx={{ color: '#B12823' }}>
                    <Email />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ margin: -3 }}
                    primary={
                      <Typography
                        variant='body2'
                        sx={{ color: '#005a55', textDecoration: 'underline' }}
                      >
                        {`${delegacion?.email || ''}`}
                      </Typography>
                    }
                  />
                </ListItem>
              )}
              <ListItem sx={{ py: 1, px: 0 }}>
                <ListItemIcon sx={{ color: '#B12823' }}>
                  <AccessTime />
                </ListItemIcon>
                <ListItemText
                  sx={{ margin: -3 }}
                  primary={`${delegacion?.horarioatencion || ''}`}
                />
              </ListItem>
            </List>
          </Box>
        </Collapse>
      </Box>
    );
  };

  /// -------------------- DATA GRID DELEGACION --------------------------
  const mapDelegacionToRow = (p) => ({
    id: p.id,
    localidad: p.localidad,
    nombre: p.nombre,
    direccion: p.direccion,
    telefono: p.telefono,
    email: p.email,
    horarioatencion: p.horarioatencion,
  });

  function getFullName(params) {
    return `${params.row.localidad || ''} ( ${params.row.nombre || ''} )`;
  }

  const columns = [
    {
      field: 'desplegable',
      headerName: '',
      flex: 1,
      renderCell: (a) => <DataCollapseDrawer delegacion={a.row} />,
    },
    { field: 'id', headerName: 'Nº', width: 5 },
    { field: 'localidad', headerName: 'Localidad (Nombre)', width: 220, valueGetter: getFullName },
    { field: 'direccion', headerName: 'Domicilio', width: 220 },
    { field: 'telefono', headerName: 'Teléfono', width: 140 },
    { field: 'email', headerName: 'Correo electrónico', flex: 0.1 },
    { field: 'horarioatencion', headerName: 'Horario', flex: 0.1 },
  ];

  useEffect(() => {
    const get = async () => {
      //const res = await buscarAportes();
      const res = await buscarDelegaciones();
      if (res && res.length > 0) {
        setVisibleFind(true);
        setDelegaciones(res);
        setDelegacionesFiltradas(res);
      } else {
        setAlertResultFind('No se han encontrado delegaciones');
      }
    };

    get();
  }, []);

  const handlerSelectLocalidad = (localidad) => {
    if (localidad != null) {
      const delegacionesAux = delegaciones?.filter(
        (d) => d.idlocalidad === localidad.localidadVO.idLocalidad,
      );

      if (delegacionesAux.length > 0) {
        setVisibleFind(true);
        setDelegacionesFiltradas(delegacionesAux);
      } else {
        setVisibleFind(false);
        setAlertResultFind('No se han encontrado delegaciones');
      }
    } else {
      setDelegacionesFiltradas(delegaciones);
    }
  };

  const handleBorrarMensaje = () => {
    setAlertResultFind('');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 0 }}>
          <CardContent>
            <Typography
              tabIndex={0}
              aria-label='Buscar Delegaciones'
              gutterBottom
              variant='h5'
              component='h2'
            >
              Buscar Delegaciones
            </Typography>
            <Typography variant='string' component={'div'}>
              Acá podrás ver el listado de delegaciones de toda la provincia, los datos de domicilio
              y contacto de cada una.
            </Typography>
            <Grid container direction='column' justifyContent='space-between' spacing={1}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <LocalidadAutocomplete onSelectLocalidad={handlerSelectLocalidad} />
                </FormControl>
              </Grid>
            </Grid>
            {visibleFind && (
              <Grid container direction='column' justifyContent='space-between' spacing={1}>
                <Grid item>
                  <div style={{ height: '50vh', width: '100%' }}>
                    <DataGrid
                      sx={{
                        border: 0,
                        '& .MuiDataGrid-cell': {
                          py: '8px',
                        },
                      }}
                      loading={!delegacionesFiltradas.length}
                      rows={delegacionesFiltradas.map((a) => mapDelegacionToRow(a))}
                      columns={columns}
                      getRowHeight={() => 'auto'}
                      getEstimatedRowHeight={() => 200}
                      columnVisibilityModel={{
                        desplegable: screenWidth === 'xs',
                        id: false,
                        localidad: screenWidth !== 'xs',
                        direccion: screenWidth !== 'xs',
                        telefono: screenWidth !== 'xs',
                        email: screenWidth !== 'xs',
                        horarioatencion: screenWidth !== 'xs',
                      }}
                      pagination
                      autoPageSize
                      disableColumnMenu
                    />
                  </div>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
      <CustomizedSnackbars
        open={alertResultFind}
        severity='info'
        message={alertResultFind}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default BuscarDelegaciones;
