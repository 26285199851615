import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, FormControl, Stack, TextField } from '@mui/material';
import { getGenerarOrden, getImprimirOrden } from '../../services/Seo/Orden';
import LoadingButton from '../../@iosper/components/LoadingButton';
import DetalleCoberturaOrdenData from './components/DetalleCoberturaOrdenData';
import Assignment from '@mui/icons-material/Assignment';
import HighlightOff from '@mui/icons-material/HighlightOff';

const OrdenValidadaResumidaForm = (props) => {
  const [datosOrden, setDatosOrden] = useState({});
  const [ordenGenerada, setOrdenGenerada] = useState(false);
  const [loadingGenerarOrden, setLoadingGenerarOrden] = useState(false);
  const { handlerOrdenConfirmada } = props;

  useEffect(() => {
    setDatosOrden(props?.datosOrden);

    return function cleanup() {
      setDatosOrden({});
    };
  }, [props.datosOrden]);

  if (!datosOrden) {
    return null;
  }

  const handleCancel = () => {
    if (props.handlerOrdenCancelada !== undefined) {
      props.handlerOrdenCancelada();
    }
    handleClose();
  };

  const handleClose = () => {
    setDatosOrden({});
    setLoadingGenerarOrden(false);
    setOrdenGenerada(false);
    props.setOpen(false);
  };

  const handleGenerate = async (event) => {
    event.preventDefault();
    setLoadingGenerarOrden(true);

    try {
      const res = await getGenerarOrden(datosOrden.codigoTransaccion);
      setDatosOrden(res);
      setOrdenGenerada(true);
      const resGenerada = await getImprimirOrden({
        numeroOrden: res.orden.numeroOrdenSinFormato,
        codigoTransaccion: res.orden.codigoTransaccion,
      });
      setLoadingGenerarOrden(false);
      handlerOrdenConfirmada(res, resGenerada);
      handleClose();
    } catch (e) {
      setLoadingGenerarOrden(false);
    }
  };

  return (
    <div>
      <Dialog
        maxWidth='lg'
        open={props.open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center' }} id='alert-dialog-title'>
          ¿Confirma esta orden?
        </DialogTitle>
        <DialogContent sx={{ pr: 2, pl: 3, pb: 0, pt: 0 }}>
          {datosOrden.practicas && (
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='practica'
                variant='standard'
                label='Práctica'
                defaultValue={
                  datosOrden.practicas &&
                  Object.values(datosOrden.practicas).map(
                    (practica) => `(${practica.codigoPractica}) ${practica.descripcionPractica}`,
                  )
                }
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          )}
          {datosOrden.afiliado && (
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='afiliado'
                variant='standard'
                label='Afiliado'
                defaultValue={`(${datosOrden.afiliado.numerodocumentoformateado}) ${datosOrden.afiliado.nombre}, ${datosOrden.afiliado.apellido}`}
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          )}
          {datosOrden.prestador && (
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='prestador'
                variant='standard'
                label='Prestador'
                defaultValue={`(${datosOrden.prestador.codigoPrestador}) ${
                  datosOrden.prestador.razonSocial
                } ${
                  datosOrden.prestador?.especialidades
                    ? `(${datosOrden.prestador?.especialidades
                        ?.map((espe) => espe.descripcion)
                        .join(', ')} ) `
                    : ''
                } `}
                size='small'
                multiline
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          )}
          {datosOrden.totales && (
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='coseguro'
                variant='standard'
                label='Coseguro a pagar al Prestador'
                defaultValue={`$ ${datosOrden.totales.totalPagar}`}
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { fontWeight: 'bold' },
                }}
              />
            </FormControl>
          )}

          {datosOrden.practicas && (
            <DetalleCoberturaOrdenData
              detallePracticas={datosOrden.practicas}
            ></DetalleCoberturaOrdenData>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            justifyContent={{ xs: 'center', sm: 'flex-end' }}
            alignItems={{ xs: 'strech' }}
          >
            <Button
              variant='contained'
              color='neutral'
              startIcon={<HighlightOff />}
              onClick={handleCancel}
            >
              {ordenGenerada ? 'Cerrar' : 'Cancelar'}
            </Button>
            <LoadingButton
              variant='contained'
              color='primary'
              type='submit'
              startIcon={<Assignment />}
              loading={loadingGenerarOrden ? 'show' : 'hide'}
              autoFocus={true}
              onClick={handleGenerate}
              disabled={ordenGenerada}
              content={ordenGenerada ? 'Confirmada' : 'Confirmar'}
            />
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrdenValidadaResumidaForm;
