import React, { useEffect, useState } from 'react';
import {
  Menu,
  MenuItem,
  ListItemButton,
  Card,
  Grid,
  Typography,
  ListItem,
  ListItemText,
  Box,
  ToggleButton,
  Collapse,
  List,
  CardContent,
  useMediaQuery,
  Button,
  Stack,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Delete,
  OpenInNew,
  ForwardToInbox,
  MoreVert,
  GetApp,
} from '@mui/icons-material';
import { findAutorizacionesAmbulatorias } from '../../services/Busquedas/Autorizaciones';
import {
  getComprobanteAutorizacion,
  getProvidenciaAutorizacion,
} from '../../services/Afiliado/Autorizaciones';
import { useForm } from 'react-hook-form';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useWidth } from '../../@iosper/utils/UseWidth';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import StyledDataGridSolicitudCobertura from '../Common/StyledDataGridSolicitudCobertura';
import AddAutorizacion from './AddAutorizacion';
import AnularAutorizacionPopUp from './AnularAutorizacionPopUp';
import EnviarAutorizacionPopUp from './EnviarAutorizacionPopUp';
import EnviarProvidenciaAutorizacionPopUp from './EnviarProvidenciaAutorizacionPopUp';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { isMobile } from '../../@iosper/utils/DeviceDetector';

const DataCollapseDrawer = (props) => {
  const { autorizacion } = props;
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDescargar = async (filename, idAutorizacion) => {
    try {
      const urlReporte = await getComprobanteAutorizacion({
        id: idAutorizacion,
        enviarMail: false,
      });
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('href', urlReporte);
      link.setAttribute('download', 'Solicitud cobertura ' + filename + '.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleDescargarProvidencia = async (filename, idAutorizacion) => {
    try {
      const urlReporte = await getProvidenciaAutorizacion({ id: idAutorizacion });
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('href', urlReporte);
      link.setAttribute('download', 'Providencia Solicitud cobertura ' + filename + '.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleEnviar = (autorizacion) => {
    props.onEnviar(autorizacion);
    handleClose();
  };

  const handleProvidenciaEnviar = (autorizacion) => {
    props.onProvidenciaEnviar(autorizacion);
    handleClose();
  };

  const handleAnular = (autorizacion) => {
    props.onAnular(autorizacion);
    handleClose();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0, p: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <List sx={{ p: 0 }}>
            <ListItem sx={{ py: 0 }}>
              <ListItemText
                sx={{ py: 0 }}
                primaryTypographyProps={{ textTransform: 'none', fontSize: '14px' }}
                primary={` ${autorizacion?.afiliadonumerodocumento || ''}`}
              />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText
                primaryTypographyProps={{ fontSize: '14px' }}
                primary={`nº: ${autorizacion?.idFormateado || ''}`}
              />
            </ListItem>
          </List>
          <List sx={{ p: 0 }}>
            <ListItem sx={{ justifyContent: 'flex-end', p: 0 }}>
              <ListItemButton
                aria-label='more'
                id='long-button'
                aria-controls={openAction ? 'long-menu' : undefined}
                aria-expanded={openAction ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
              >
                <MoreVert />
              </ListItemButton>
              <Menu
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={openAction}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: 58 * 4.5,
                    width: '200px',
                  },
                }}
              >
                <MenuItem
                  key={'descargar'}
                  onClick={() => {
                    handleDescargar(autorizacion.afiliado, autorizacion.id);
                  }}
                >
                  <GridActionsCellItem
                    icon={<GetApp />}
                    title='Descargar Solicitud'
                    label='Descargar Solicitud'
                  />
                  <Typography variant='body2'>Descargar Solicitud</Typography>
                </MenuItem>
                <MenuItem
                  key={'anular'}
                  onClick={() => {
                    handleAnular(autorizacion);
                  }}
                  disabled={autorizacion.codigoEstado !== '1'}
                >
                  <GridActionsCellItem
                    icon={<Delete />}
                    title='Anular Solicitud'
                    label='Anular Solicitud'
                  />
                  <Typography variant='body2'>Anular Solicitud</Typography>
                </MenuItem>
                <MenuItem
                  key={'enviar'}
                  onClick={() => {
                    handleEnviar(autorizacion);
                  }}
                  disabled={autorizacion.codigoEstado !== '1' && autorizacion.codigoEstado !== '4'}
                >
                  <GridActionsCellItem
                    icon={<ForwardToInbox />}
                    disabled={
                      autorizacion.codigoEstado !== '1' && autorizacion.codigoEstado !== '4'
                    }
                    title='Enviar Solicitud'
                    label='Enviar Solicitud'
                  />
                  <Typography variant='body2'>Enviar Solicitud</Typography>
                </MenuItem>
                <MenuItem
                  key={'descargar-providencia'}
                  onClick={() => {
                    handleDescargarProvidencia(autorizacion.afiliado, autorizacion.id);
                  }}
                  disabled={autorizacion.codigoEstado !== '3' && autorizacion.codigoEstado !== '4'}
                >
                  <GridActionsCellItem
                    icon={<GetApp />}
                    disabled={
                      autorizacion.codigoEstado !== '3' && autorizacion.codigoEstado !== '4'
                    }
                    title='Descargar Providencia'
                    label='Descargar Providencia'
                  />
                  <Typography variant='body2'>Descargar Providencia</Typography>
                </MenuItem>
                <MenuItem
                  key={'enviar-providencia'}
                  onClick={() => {
                    handleProvidenciaEnviar(autorizacion);
                  }}
                  disabled={autorizacion.codigoEstado !== '3' && autorizacion.codigoEstado !== '4'}
                >
                  <GridActionsCellItem
                    icon={<ForwardToInbox />}
                    disabled={
                      autorizacion.codigoEstado !== '3' && autorizacion.codigoEstado !== '4'
                    }
                    title='Enviar Providencia'
                    label='Enviar Providencia'
                  />
                  <Typography variant='body2'>Enviar Providencia</Typography>
                </MenuItem>
              </Menu>
            </ListItem>
          </List>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box>
          <List sx={{ py: 0 }}>
            <ListItem sx={{ py: 0 }} style={{ fontWeight: 'bold' }}>
              <ListItemText
                primaryTypographyProps={{ fontSize: '14px' }}
                primary={`Tipo: ${autorizacion?.tipoCobertura || ''}`}
              />
            </ListItem>
            <ListItem sx={{ py: 0 }} style={{ fontWeight: 'bold' }}>
              <ListItemText
                primaryTypographyProps={{ fontSize: '14px' }}
                primary={`Estado: ${autorizacion?.estado || ''}`}
                sx={{ my: 0 }}
              />
            </ListItem>
            {autorizacion?.practicas?.length > 0 && (
              <ListItem sx={{ py: 0 }}>
                <ListItemText
                  secondaryTypographyProps={{ fontSize: '14px' }}
                  secondary={`Prestaciones autorizadas:`}
                />
              </ListItem>
            )}

            {autorizacion?.practicas?.map((i, k) => (
              <ListItem key={k}>
                <ListItemText
                  secondaryTypographyProps={{ fontSize: '14px' }}
                  secondary={`${i?.cantidad || ''} x ${i?.nombre || ''}`}
                  sx={{ my: 0 }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const VerAutorizaciones = () => {
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const esMobile = isMobile() || !matches;

  const screenWidth = useWidth();

  const [autorizaciones, setAutorizaciones] = useState(null);
  const [onAdd, setAdd] = useState(false);
  const [onEdit, setEdit] = useState(null);
  const [autorizacion, setAutorizacion] = useState(null);
  const [onOpenAnular, setOpenAnular] = useState(null);
  const [onOpenEnviar, setOpenEnviar] = useState(null);
  const [onOpenEnviarProvidencia, setOpenEnviarProvidencia] = useState(null);
  const [success, setSuccess] = useState('');

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (autorizaciones === null) {
      getInfo();
    }
  }, [autorizaciones]);

  const getInfo = async () => {
    const response = await findAutorizacionesAmbulatorias();
    setAutorizaciones(response);
  };

  const handleAnular = (autorizacion) => {
    setAutorizacion(autorizacion);
    setOpenAnular(true);
  };

  const handleEnviar = (autorizacion) => {
    setAutorizacion(autorizacion);
    setOpenEnviar(true);
  };

  const handleProvidenciaEnviar = (autorizacion) => {
    setAutorizacion(autorizacion);
    setOpenEnviarProvidencia(true);
  };

  const previewClic = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        handlePreview(id);
      });
    },
    [],
  );

  const previewProvidenciaClic = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        handlePreviewProvidencia(id);
      });
    },
    [],
  );

  const handlePreviewProvidencia = async (idAutorizacion) => {
    try {
      const pdf = await getProvidenciaAutorizacion({ id: idAutorizacion });
      history.push({
        pathname: '/previsualizar',
        state: { url: pdf } || {}, // your data array of objects
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handlePreview = async (idAutorizacion) => {
    try {
      const pdf = await getComprobanteAutorizacion({ id: idAutorizacion, enviarMail: false });
      history.push({
        pathname: '/previsualizar',
        state: { url: pdf } || {}, // your data array of objects
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handlerAutorizacionGenerada = (datosAutorizacion, urlPdf) => {
    //setUrlReporte(urlPdf);
    //setOpenDialogReporte(true);
    //setDatosAutorizacionGenerada(datosAutorizacion);
  };

  const enviarClic = React.useCallback(
    (value) => () => {
      handleEnviar(value);
    },
    [],
  );

  const enviarProvidenciaClic = React.useCallback(
    (value) => () => {
      handleProvidenciaEnviar(value);
    },
    [],
  );

  const handleAnulada = async (anulada) => {
    setAutorizacion(null);
    setOpenAnular(false);
    if (anulada) {
      getInfo();
    }
  };

  const handleEnviada = async (anulada) => {
    setSuccess('La solicitud de autorización ha sido enviada correctamente.');
    setAutorizacion(null);
    setOpenEnviar(false);
  };

  const handleProvidenciaEnviada = async (anulada) => {
    setSuccess('La providencia ha sido enviada correctamente');
    setAutorizacion(null);
    setOpenEnviarProvidencia(false);
  };

  const mapListAutorizaciones = (a) => {
    return ' ' + a.cantidad + ' x ' + a.nombre;
  };

  const mapAutorizacionToRow = (p, index) => ({
    id: p.idSolicitudCobertura,
    correo: p.correo,
    idFormateado: p.idSolicitudCoberturaFormateado,
    afiliado: p.afiliado,
    afiliadonumerodocumento: p.afiliado + ' (' + p.numerodocumento + ')',
    tipoCobertura: p.tipoCobertura,
    estado: p.estado + ' (' + p.fechaestado + ')',
    fechaestado: p.fechaestado,
    practicasLabel: p.practicas.map((a) => mapListAutorizaciones(a)),
    practicas: p.practicas,
    idGrupoTipoCobertura: p.idGrupoTipoCobertura,
    codigoEstado: p.codigoEstado,
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: (
        <div>
          <Typography component='div' align='justify' sx={{ fontSize: '14px' }}>
            Una vez iniciada, la auditoría puede
          </Typography>
          <Typography component='div' align='justify' sx={{ fontSize: '14px' }}>
            demorar hasta 48 hs. hábiles.
          </Typography>
        </div>
      ),
      flex: 1,
      renderCell: (a) => (
        <DataCollapseDrawer
          autorizacion={a.row}
          onAnular={(e) => {
            handleAnular(e);
          }}
          onEnviar={(e) => {
            handleEnviar(e);
          }}
          onProvidenciaEnviar={(e) => {
            handleProvidenciaEnviar(e);
          }}
        />
      ),
    },
    { field: 'idFormateado', headerName: 'Nº', flex: 0.03 },
    { field: 'afiliadonumerodocumento', headerName: 'Afiliado', flex: 0.07 },
    { field: 'tipoCobertura', headerName: 'Tipo', flex: 0.1 },
    { field: 'estado', headerName: 'Estado', flex: 0.08 },
    { field: 'practicasLabel', headerName: 'Prestaciones autorizadas', flex: 0.1 },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<OpenInNew />}
          title='Ver Solicitud'
          label='Ver Solicitud'
          onClick={previewClic(params.row.id)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<Delete />}
          title='Anular Solicitud'
          disabled={params.row.codigoEstado !== '1'}
          label='Anular Solicitud'
          onClick={() => {
            handleAnular(params.row);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<ForwardToInbox />}
          label='Enviar Solicitud'
          title='Enviar Solicitud'
          disabled={params.row.codigoEstado !== '1' && params.row.codigoEstado !== '4'}
          onClick={enviarClic(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<OpenInNew />}
          label='Ver Providencia'
          title='Ver Providencia'
          disabled={params.row.codigoEstado !== '3' && params.row.codigoEstado !== '4'}
          onClick={previewProvidenciaClic(params.row.id)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<ForwardToInbox />}
          label='Enviar Providencia'
          title='Enviar Providencia'
          disabled={params.row.codigoEstado !== '3' && params.row.codigoEstado !== '4'}
          onClick={enviarProvidenciaClic(params.row)}
          showInMenu
        />,
      ],
    },
  ];

  const dataGridAutorizaciones = autorizaciones && (
    <Grid container direction='column' justifyContent='space-between' spacing={1}>
      <Grid item>
        <div style={{ height: '50vh', width: '100%' }}>
          <StyledDataGridSolicitudCobertura
            sx={{
              border: 0,
              '& .MuiDataGrid-cell': {
                py: '8px',
              },
            }}
            getRowClassName={(params) => `super-app-theme--${params.row.codigoEstado}`}
            columns={columns}
            rows={autorizaciones.map((a, index) => mapAutorizacionToRow(a, index))} //
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}
            components={{
              NoRowsOverlay: () => (
                <Stack height='100%' alignItems='center' justifyContent='center'>
                  Sin solicitudes de autorizaciones ambulantes.
                </Stack>
              ),
            }}
            columnVisibilityModel={{
              desplegable: screenWidth === 'xs',
              idFormateado: screenWidth !== 'xs',
              afiliadonumerodocumento: screenWidth !== 'xs',
              tipoCobertura: screenWidth !== 'xs',
              estado: screenWidth !== 'xs',
              practicasLabel: screenWidth !== 'xs',
              actions: screenWidth !== 'xs',
            }}
            pagination
            autoPageSize
            disableColumnMenu
          />
        </div>
      </Grid>
    </Grid>
  );

  const handleBorrarMensaje = () => {
    setSuccess('');
  };

  return onAdd ? (
    <AddAutorizacion
      onCancelar={() => {
        setAdd(false);
        setAutorizaciones(null);
      }}
      onSaved={() => {
        setAutorizaciones(null);
      }}
    ></AddAutorizacion>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          {!esMobile && (
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Solicitudes de Autorización
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p' align='justify'>
                Acá podrás consultar el estado de las solicitudes de autorización de{' '}
                <b>prestaciones médicas y bioquímicas ambulatorias</b> solicitadas en los últimos 30
                días.
                <br />
                Una vez iniciada, la auditoria del pedido{' '}
                <b>puede demorar hasta un máximo de 48 hs. hábiles</b>.
              </Typography>
              <CardContent></CardContent>
              {dataGridAutorizaciones}

              <Grid item>
                <Grid container justifyContent='flex-end' spacing={1}>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setAdd(true);
                      }}
                    >
                      Agregar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          )}
          {esMobile && (
            <CardContent>
              <Typography gutterBottom variant='h6' component='h2'>
                Solicitudes de Autorización
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p' align='justify'>
                Acá podrás solicitar y consultar el estado de las últimas solicitudes de
                autorización de <b>prestaciones médicas y bioquímicas ambulatorias.</b>
                <br />
              </Typography>
              {dataGridAutorizaciones}
              <Grid
                item
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Alert severity='info'>
                  <b style={{ color: '#1d1e1eb0' }}>
                    Usando el + podés enviar una nueva solicitud de autorización.
                  </b>
                </Alert>
                <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                  <AddCircleIcon
                    color='primary'
                    aria-label='add'
                    sx={{ fontSize: 60 }}
                    onClick={() => {
                      setAdd(true);
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          )}
        </Card>
      </Grid>
      {onOpenAnular ? (
        <AnularAutorizacionPopUp
          open={onOpenAnular}
          autorizacion={autorizacion}
          setAutorizacionAnulada={handleAnulada}
        />
      ) : null}
      {onOpenEnviar ? (
        <EnviarAutorizacionPopUp
          open={onOpenEnviar}
          autorizarion={autorizacion}
          setAutorizacion={handleEnviada}
          onCancelar={() => setOpenEnviar(false)}
        />
      ) : null}
      {onOpenEnviarProvidencia ? (
        <EnviarProvidenciaAutorizacionPopUp
          open={onOpenEnviarProvidencia}
          autorizarion={autorizacion}
          setAutorizacion={handleProvidenciaEnviada}
          onCancelar={() => setOpenEnviarProvidencia(false)}
        />
      ) : null}

      <CustomizedSnackbars
        open={success}
        autoHideDuration={8000}
        severity='success'
        message={success}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default VerAutorizaciones;
