import { useCallback, useEffect, useState } from 'react';
import { getCuponCapita, getImprimirCapita } from '../../services/Capita/Capita';
import CustomizedSnackbars from '../Notifications/SnackBar';
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  TextField,
  Box,
  Input,
} from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import ButtonBack from '../Breadcrumb/ButtonBack';
import QrPreview from '../Common/QrPreview';
import { useInfoAfiliado } from '../../components/Afiliados/InfoAfiliadoProvider';
import LoadingButton from '../../@iosper/components/LoadingButton';
import Assignment from '@mui/icons-material/Assignment';

const ImprimirCuponPagoCapita = () => {
  const { infoAfiliado } = useInfoAfiliado();

  const [success, setSuccess] = useState('');
  const [codigoLinkPagos, setCodigoLinkPagos] = useState('');
  const [preview, setPreview] = useState(false);
  const [urlReporte, setUrlReporte] = useState('');
  const [warning, setWarning] = useState('');
  const [loading, setLoading] = useState(false);
  const [vencimiento, setVencimiento] = useState('');

  // Detalle para vista previa
  const [numeroRecibo, setNumeroRecibo] = useState('');
  const [periodo, setPeriodo] = useState('');
  const [total, setTotal] = useState('');
  const [detalle, setDetalle] = useState('');

  useEffect(() => {
    setVencimiento(infoAfiliado.tipoAfiliado.fechaVencimientoCapita.split('/'));
  }, [infoAfiliado]);

  const copyToClipBoard = async () => {
    setSuccess('');
    setWarning('');
    try {
      await navigator.clipboard.writeText(codigoLinkPagos);
      setSuccess('¡Código Link copiado correctamente!');
    } catch (err) {
      setWarning('¡El código link no pudo copiarse!');
    }
  };

  const getAfiliadoData = useCallback(() => {
    let data = {
      numeroDocumento: infoAfiliado.personaFisica.numeroDocumento,
      tipoDocumento: infoAfiliado.personaFisica.idTipoDocumento,
    };
    return data;
  }, [infoAfiliado]);

  const generarCupon = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess('');
    const afiliadoData = getAfiliadoData();

    try {
      // Traigo los datos del cupon y luego genero el PDF
      const res = await getCuponCapita(afiliadoData);
      let d = 'Cápita: $ ' + res.totalCapita;

      if (res.totalCredito !== '0') {
        d = d + '\nCrédito: $ ' + res.totalCredito;
      }

      if (res.totalFondoVoluntario !== '0') {
        d = d + '\nFondo Voluntario: $ ' + res.totalFondoVoluntario;
      }

      setCodigoLinkPagos(res.codigoLinkPagos);
      setNumeroRecibo(res.numeroRecibo);
      setPeriodo(res.periodo + ' (Vence el ' + res.fechaVencimiento.replaceAll('-', '/') + ')');
      setTotal('$ ' + res.totalAPagar);
      setDetalle(d);

      // Genero el PDF
      const report = await getImprimirCapita(afiliadoData);
      setUrlReporte(report);
      setSuccess('Cápita generada correctamente');
      setPreview(true);
      setLoading(false);
    } catch (e) {
      setNumeroRecibo('');
      setDetalle('');
      setPeriodo('');
      setTotal('');
      setCodigoLinkPagos('');
      setLoading(false);
    }
  };

  const handleBorrarMensaje = () => {
    setWarning('');
    setSuccess('');
  };

  const content = (
    <Grid container spacing={1}>
      <ButtonBack></ButtonBack>
      <Card sx={{ width: '100%', p: 1 }}>
        <CardContent>
          <Grid container direction='column' justifyContent='space-between' alignItems='flex-start'>
            <Grid item>
              <Typography gutterBottom variant='h5' component='h5' sx={{ paddingBottom: 3 }}>
                Cápita de Afiliado Voluntario
              </Typography>
              <Typography variant='string' component={'div'}>
                Acá podras descargar los cupones de pago del mes en curso para ser abonados en
                alguno de los medios de pago disponibles: Entre Ríos Servicios, Home Banking (de Red
                Link) o Plus Pagos (con tarjeta de débito).
              </Typography>
            </Grid>
            <Grid item>
              <LoadingButton
                variant='contained'
                size='small'
                color='primary'
                type='submit'
                content={'Generar cupón'}
                onClick={generarCupon}
                loading={loading ? 'show' : 'hide'}
                startIcon={<Assignment />}
              />
            </Grid>
            <Grid item>
              <Typography gutterBottom variant='subtitle2' sx={{ paddingLeft: 2 }}>
                Este mes el vencimiento es el {vencimiento[0]}/{vencimiento[1]}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );

  const contentPreview = (
    <Grid container spacing={1}>
      <ButtonBack></ButtonBack>

      <CustomizedSnackbars
        open={warning}
        severity='warning'
        message={warning}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={success}
        severity='success'
        message={success}
        onDeleteMessage={handleBorrarMensaje}
      />

      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Cupón de Pago de Cápita
            </Typography>
            <QrPreview
              urlReporte={urlReporte}
              nombreReporte={'Capita_iosper_' + codigoLinkPagos}
              qrValue={JSON.stringify({ codigo: codigoLinkPagos })}
            />
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='afiliado'
                label='Afiliado'
                variant='standard'
                defaultValue={`(${infoAfiliado.afiliado?.numeroDocumentoFormateado}) ${infoAfiliado.personaFisica?.apellido}, ${infoAfiliado.personaFisica?.nombre}`}
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <InputLabel htmlFor='outlined-adornment-link'>Link de pago</InputLabel>
              <Input
                id='outlined-adornment-link'
                type='number'
                fullWidth={true}
                variant='standard'
                value={codigoLinkPagos}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      variant='outlined'
                      aria-label='Buscar codigoLink'
                      onClick={copyToClipBoard}
                      size='large'
                      disabled={!codigoLinkPagos}
                      edge='end'
                    >
                      <CopyAll />
                    </IconButton>
                  </InputAdornment>
                }
                label='Link de Pago'
                aria-describedby='outlined-prestador-helper-text'
              />
            </FormControl>
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='nro-recibo'
                label='Nro. de Recibo'
                variant='standard'
                defaultValue={numeroRecibo}
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='periodo'
                variant='standard'
                label='Periodo'
                defaultValue={periodo}
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='total'
                label='Total a pagar'
                variant='standard'
                defaultValue={total}
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { fontWeight: 'bold' },
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='detalle'
                label='Detalle'
                variant='standard'
                defaultValue={detalle}
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                multiline
                rows={3}
              />
            </FormControl>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  return <Box sx={{ width: '100%' }}>{preview ? contentPreview : content}</Box>;
};

export default ImprimirCuponPagoCapita;
