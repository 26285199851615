import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getEspecialidades } from '../../services/Busquedas/Especialidades';

const EspecialidadAutocomplete = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [especialidadLabel, setEspecilidadLabel] = React.useState('Seleccioná la especialidad');

  React.useEffect(() => {
    const getAllEspecialidades = async () => {
      const especialidades = await getEspecialidades();
      setOptions(especialidades);
    };

    try {
      if (options && options.length === 0) {
        getAllEspecialidades();
      }
    } catch (err) {
      console.error(err);
    }
  }, [options]);

  const handleChange = (event, newValue) => {
    props.onSelectEspecialidad(newValue);
    setEspecilidadLabel('Especialidad');
  };

  return (
    <Autocomplete
      id='especialidad-autocomplete'
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) =>
        option.especialidadVO?.idEspecialidad === value.especialidadVO?.idEspecialidad
      }
      getOptionLabel={(a) => `${a.especialidadVO.descripcion} - (${a.profesionVO.descripcion})`}
      onChange={handleChange}
      options={options}
      loading={options && options.length === 0}
      renderInput={(params) => (
        <TextField
          id='textPractica'
          {...params}
          label={especialidadLabel}
          variant='standard'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {options && options.length === 0 ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
});

export default EspecialidadAutocomplete;
