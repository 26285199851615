import { axiosGet } from '../ServiceBase.js';

export const getPracticasAfiliado = async () => {
  try {
    const practicasAfiliado = JSON.parse(sessionStorage.getItem('practicasAfiliado'));
    if (practicasAfiliado == null) {
      const practicas = await axiosGet('/v1/practicas/practicas-afiliado');
      sessionStorage.setItem('practicasAfiliado', JSON.stringify(practicas));
      return practicas;
    } else {
      return practicasAfiliado;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
