import BuscarDelegaciones from '../Delegaciones/BuscarDelegaciones';

const Delegaciones = () => {
  return (
    <>
      <BuscarDelegaciones />
    </>
  );
};

export default Delegaciones;
