import { createContext, useReducer } from 'react';
import { getVersionActual } from '../AppVersionService';

const CHECK_VERSION = 'CHECK_VERSION';

export const VersionControlContext = createContext();
export const { Consumer, Provider } = VersionControlContext;

const initialState = {
  isUptodate: true,
};

function reducer(state, action) {
  switch (action.type) {
    case CHECK_VERSION:
      return {
        isUptodate: action.response,
      };
    default:
      throw new Error();
  }
}

const VersionControlProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getVersion = async () => {
    const dataResponse = await getVersionActual({
      idAplicacion: process.env.REACT_APP_MI_IOSPER_ID,
    });
    return process.env.REACT_APP_MI_IOSPER_VERSION === dataResponse?.version;
  };

  function dispatchCheckVersion() {
    getVersion().then((r) => {
      dispatch({
        type: CHECK_VERSION,
        response: r,
      });
    });
  }

  return <Provider value={{ state, dispatch, dispatchCheckVersion }}>{props.children}</Provider>;
};

export default VersionControlProvider;
