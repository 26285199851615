import { Avatar, Card, CardActionArea, CardContent, CardHeader, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const AfiliadoCardActionComponent = ({ avatar, title, description, afiliadoId, nro }) => {
  return (
    <Card
      role='article'
      aria-posinset={nro}
      aria-labelledby={`afiliado-card-name-${nro}`}
      aria-describedby={`afiliado-card-info-${nro}`}
      tabIndex='0'
    >
      <CardActionArea
        sx={{ borderRadius: '10px' }}
        component={Link}
        to={`/ver-afiliado/${afiliadoId}`}
        aria-label={title}
      >
        <CardHeader
          id={`afiliado-card-header-${nro}`}
          sx={{ paddingBottom: '0px' }}
          avatar={<Avatar sx={{ bgcolor: '#00615C' }}>{avatar}</Avatar>}
          title={
            <Typography
              id={`afiliado-card-name-${nro}`}
              gutterBottom
              variant='h6'
              component='p'
              sx={{ fontWeight: 'bold', lineHeight: '1.3' }}
            >
              {title}.
            </Typography>
          }
        />
        <CardContent
          id={`afiliado-card-info-${nro}`}
          sx={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'flex-start',
            padding: '10px 16px',
          }}
        >
          {description}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const AfiliadoCard = ({ afiliado, position }) => {
  const iniciales = `${afiliado?.personaFisica?.apellido?.charAt(
    0,
  )}${afiliado?.personaFisica?.nombre?.charAt(0)}`;
  const nombre = `${afiliado.personaFisica.apellido}, ${afiliado.personaFisica.nombre}`;

  return (
    <AfiliadoCardActionComponent
      position={position}
      initials={iniciales?.toUpperCase()}
      title={nombre}
      description={
        <>
          <Typography variant='body2' color='text.secondary'>
            <b style={{ color: '#1d1e1eb0' }}>DNI:</b>{' '}
            {`${afiliado?.afiliado?.numeroDocumentoFormateado} (${afiliado?.relacionTitularAdherente?.descripcion})`}
          </Typography>
        </>
      }
      afiliadoId={afiliado?.afiliado?.idAfiliado}
    />
  );
};

export default AfiliadoCard;
