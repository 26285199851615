import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

const CardButtonProfile = ({ title, description, to, image }) => {
  return (
    <Card>
      <CardActionArea
        sx={{ borderRadius: '10px' }}
        component={Link}
        to={to || '/'}
        aria-label={`Ver el perfil de ${title}`}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <CardMedia>
            <Avatar sx={{ mt: '16px', width: 80, height: 80 }} />
          </CardMedia>
          <CardContent>
            {title && (
              <Typography
                gutterBottom
                variant='h6'
                component='h2'
                textAlign={'center'}
                sx={{ fontWeight: 'bold' }}
              >
                {title || ''}
              </Typography>
            )}
            {description && (
              <Typography variant='body2' color='textSecondary' component='p' textAlign={'center'}>
                {description}
              </Typography>
            )}
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default CardButtonProfile;
