import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getLocalidades } from '../../services/Busquedas/Localidad';

const LocalidadAutocomplete = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [localidadLabel, setLocalidadLabel] = React.useState('Seleccioná la localidad');

  React.useEffect(() => {
    const getAllLocalidades = async () => {
      const localidades = await getLocalidades();
      setOptions(localidades);
    };

    try {
      if (options && options.length === 0) {
        getAllLocalidades();
      }
    } catch (err) {
      console.error(err);
    }
  }, [options]);

  const handleChange = (event, newValue) => {
    props.onSelectLocalidad(newValue);
    setLocalidadLabel('Localidad');
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.localidadVO.descripcion,
  });

  return (
    <Autocomplete
      id='localidad-autocomplete'
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(a) =>
        `${a.localidadVO.descripcion} - (${a.departamentoVO.descripcion} - ${a.provinciaVO.descripcion})`
      }
      onChange={handleChange}
      filterOptions={filterOptions}
      options={options}
      loading={options && options.length === 0}
      renderInput={(params) => (
        <TextField
          id='textPractica'
          {...params}
          label={localidadLabel}
          variant='standard'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {options && options.length === 0 ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
});

export default LocalidadAutocomplete;
