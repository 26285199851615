import React from 'react';
import { Alert, Fade } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useInterceptorStore } from '../../services/Axios/store/hooks';
import { codInternal } from '../../services/Axios/codesHTTP';

const HttpResponseSnackbar = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} role='alert'>
      <Alert severity={props.severity} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </div>
  );
});

const SnackbarMessages = () => {
  const [state] = useInterceptorStore();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (state?.response?.status) {
      const message =
        state?.response?.status === 'warning'
          ? state?.response?.statusText
          : codInternal.find((c) => c.code === state?.response?.status)?.msg ||
            `Error (${state?.response?.status})`;

      handleEventSnackbarMessage(message, state?.response?.severity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.interceptorRunning]);

  const handleEventSnackbarMessage = (message, severity) => {
    enqueueSnackbar(message, {
      preventDuplicate: true,
      TransitionComponent: Fade,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      content: (key, message) => (
        <HttpResponseSnackbar id={key} message={message} severity={severity} />
      ),
    });
  };
  return <></>;
};

export default SnackbarMessages;
