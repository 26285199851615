import { generarCuestionario } from '../../../../services/Busquedas/Cuestionario';
import { useState } from 'react';

const rpModel = [];

export const useCuestionario = (initCuestionario = null) => {
  const [cuestionarioData, setCuestionarioData] = useState(initCuestionario);
  const [messageStatus, setMessageStatus] = useState({
    status: '',
    message: '',
  });

  const onSendMessageEvent = ({ status = 'success', message = '' }) => {
    setMessageStatus({ status, message });
  };

  const onResetCuestionarioData = async (cuil) => {
    try {
      setCuestionarioData(null);
      const res = await generarCuestionario({ cuil: cuil });
      if (res) {
        setCuestionarioData(res);
      }
    } catch (e) {
      onSendMessageEvent({ status: 'error', message: 'ups...error' });
    }

    return null;
  };

  /**
   * Evento que valida cuestionario completo (al menos una opcion seleccionada por pregunta)
   * @returns Boolean
   */
  const onCuestionarioCompleto = () => {
    let result = [];
    cuestionarioData?.forEach((pregunta) => {
      result[pregunta.preguntaVO.idPregunta] = 0;
      pregunta.opciones?.forEach((opcion) => {
        if (opcion.selected) {
          result[pregunta.preguntaVO.idPregunta]++;
        }
      });
    });

    const cant = result.filter((value) => {
      const r = value && value > 0;
      return r;
    });

    const salida = cant?.length == cuestionarioData.length ? true : false;
    return salida;
  };
  /**
   * Evento que busca el cuestionario
   * @returns [{Object}]
   */
  const onBuscarCuestionario = async (cuil) => {
    try {
      if (cuestionarioData == null || cuestionarioData == []) {
        const res = await generarCuestionario({ cuil: cuil });

        if (res) {
          setCuestionarioData(res);
        }
      }
    } catch (e) {
      onSendMessageEvent({ status: 'error', message: 'ups...error' });
    }

    return null;
  };

  /**
   * Evento que actualiza las respuestas del cuestionario
   * @param string codigoOpcion
   * @param int idPregunta
   * @param boolean checked
   */
  const onChangeOptions = (codigoOpcion, idPregunta, checked) => {
    let newCuestionario = cuestionarioData?.map((element) => {
      if (element.preguntaVO.idPregunta === idPregunta) {
        if (element.preguntaVO.codigoTipoPregunta === 'MULTIPLE') {
          element.opciones?.forEach((opcion) => {
            if (opcion.codigo != null && opcion.codigo === codigoOpcion) {
              opcion.selected = checked;
            }
          });
        } else {
          element.opciones?.forEach((opcion) => {
            if (opcion.codigo != null && opcion.codigo === codigoOpcion) {
              opcion.selected = checked;
            } else {
              opcion.selected = !checked;
            }
          });
        }
      }
      return element;
    });

    setCuestionarioData(newCuestionario);
  };

  return {
    cuestionarioData,
    onChangeOptions,
    onBuscarCuestionario,
    onSendMessageEvent,
    onCuestionarioCompleto,
    onResetCuestionarioData,
  };
};
