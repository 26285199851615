import { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  Typography,
  CardContent,
  FormControl,
  CardActions,
  Stack,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import ObraSocialAutocomplete from './ObraSocialAutocomplete';
import DateTextInput from '../Common/DateTextInput';
import { useAfiliados } from '../Afiliados/AfiliadosProvider';
import { HighlightOff, Save } from '@mui/icons-material';
import LoadingButton from '../../@iosper/components/LoadingButton';
import {
  getCartaDerivacionTransitoria,
  saveTransitorias,
} from '../../services/Afiliado/Derivaciones';
import CustomizedSnackbars from '../Notifications/SnackBar';
import TransitoriaPreview from './TransitoriaPreview';
import { dateToFormatter } from '../../@iosper/utils/Format';

const AddTransitoria = (props) => {
  const { afiliados } = useAfiliados();

  const [keyComponentOS, setKeyComponentOS] = useState(0);
  const [keyComponentDesde, setKeyComponentDesde] = useState(0);
  const [keyComponentHasta, setKeyComponentHasta] = useState(0);
  const [keyComponentChk, setKeyComponentChk] = useState(0);
  const [selectedObraSocial, setSelectedObraSocial] = useState(null);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [afiliadosDerivados, setAfiliadosDerivados] = useState([]);

  const [openDialogReporte, setOpenDialogReporte] = useState(false);
  const [urlReporte, setUrlReporte] = useState('');
  const [datosDerivacionTransitoriaGenerada, setDatosDerivacionTransitoriaGenerada] = useState('');

  useEffect(() => {
    if (afiliados !== null) {
      initAfiliadosDerivados(afiliados, props.afiliados);
    }
  }, [afiliados, props.afiliados]);

  const initAfiliadosDerivados = (lista, derivadosEdit) => {
    const v = lista.map((a) => {
      return JSON.parse(
        `{"${a.afiliado.idAfiliado}": {"activo":false, "idHistorialDerivacionAfiliado":null, "nombre":"${a.personaFisica.nombre}" , "apellido":"${a.personaFisica.apellido}", "relacionTitularAdherente":"${a.relacionTitularAdherente.descripcion}"  }}`,
      );
    });
    const derivados = v.reduce((previo, current, index) => {
      return Object.assign({}, previo, current);
    });

    derivadosEdit?.forEach((element) => {
      derivados[element.idAfiliado].idHistorialDerivacionAfiliado =
        element.idHistorialDerivacionAfiliado;
      derivados[element.idAfiliado].activo = true;
    });

    setAfiliadosDerivados(derivados);
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const handlerLimpiar = () => {
    reset();
    initAfiliadosDerivados(afiliados);
    setKeyComponentOS(1 + Math.random() * (1000 - 1));
    setKeyComponentDesde(1 + Math.random() * (1000 - 1));
    setKeyComponentHasta(1 + Math.random() * (1000 - 1));
    setKeyComponentChk(1 + Math.random() * (1000 - 1));
    setLoading(false);
  };

  const handlerCancelar = () => {
    handlerLimpiar();
    props?.onCancelar();
  };

  const validateObraSocial = () => {
    return selectedObraSocial == null ? false : true;
  };

  const validateAfiliadosSelected = () => {
    return Object.values(afiliadosDerivados).find((v) => v) ? true : false;
  };
  const handlerSelectObraSocial = (obrasocial) => {
    setSelectedObraSocial(obrasocial);
  };

  const handlerDerivacionGenerada = (datosDerivacion, urlPdf) => {
    setUrlReporte(urlPdf);
    setOpenDialogReporte(true);
    setDatosDerivacionTransitoriaGenerada(datosDerivacion);
    handlerLimpiar();
    props.onSaved();
  };

  const handlerCerrarPreview = () => {
    handlerLimpiar();
  };

  const onSubmit = async (data, event) => {
    setLoading(true);
    event.preventDefault();

    let derivacion = {
      idObraSocial: selectedObraSocial.idObraSocial,
      fechaDesde: data.desde,
      fechaHasta: data.hasta,
      afiliados: afiliadosDerivados,
    };

    try {
      const datosDerivacion = await saveTransitorias(derivacion);
      const pdf = await getCartaDerivacionTransitoria({
        ids: datosDerivacion.afiliados.map((d) => d.idHistorialDerivacionAfiliado),
      });
      handlerDerivacionGenerada(datosDerivacion, pdf);
    } catch (e) {
      setLoading(false);
    }
  };

  const onChangeChecked = (e) => {
    const addNew = (afiliadosDerivados[e.target.name].activo = e.target.checked);
    setAfiliadosDerivados({
      ...afiliadosDerivados,
    });
  };

  const handleBorrarMensaje = () => {
    setError('');
    setSuccess('');
  };

  return !openDialogReporte ? (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Derivación Transitoria
              </Typography>
              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <ObraSocialAutocomplete
                  key={keyComponentOS + '_obrasocial'}
                  size='small'
                  ref={register('obraSocialRegister', {
                    validate: validateObraSocial,
                  })}
                  onSelectObraSocial={handlerSelectObraSocial}
                  onDefaultObraSocial={props.idObraSocial || null}
                />
                {errors.obraSocialRegister != null && (
                  <p style={{ color: 'red' }}>Debe seleccionar la obra social</p>
                )}
              </FormControl>
              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <DateTextInput
                  key={keyComponentDesde + '_desde'}
                  register={register}
                  errors={setError}
                  required={true}
                  label='Fecha Desde'
                  fieldName='desde'
                  value={
                    props.fechaDesde &&
                    dateToFormatter(props.fechaDesde, 'dd/mm/yyyy', 'yyyy-mm-dd')
                  }
                />
                {errors['desde'] && <p style={{ color: 'red' }}>{errors['desde'].message}</p>}
              </FormControl>
              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <DateTextInput
                  key={keyComponentHasta + '_hasta'}
                  register={register}
                  errors={setError}
                  required={true}
                  label='Fecha Hasta'
                  fieldName='hasta'
                  value={
                    props.fechaHasta &&
                    dateToFormatter(props.fechaHasta, 'dd/mm/yyyy', 'yyyy-mm-dd')
                  }
                />
                {errors['hasta'] && <p style={{ color: 'red' }}>{errors['hasta'].message}</p>}
              </FormControl>
              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <FormGroup
                  errors={setError}
                  required={true}
                  key={keyComponentChk + '_chk'}
                  register={register}
                  ref={register('derivadosRegister', {
                    validate: validateAfiliadosSelected,
                  })}
                >
                  {afiliadosDerivados &&
                    Object.entries(afiliadosDerivados).map((a) => {
                      return (
                        <FormControlLabel
                          fieldName='afiliados'
                          control={
                            <Checkbox
                              onChange={onChangeChecked}
                              checked={a[1].activo}
                              key={a[1].idHistorialDerivacionAfiliado}
                            />
                          }
                          name={a[0]}
                          label={
                            a[1].nombre +
                            ' ' +
                            a[1].apellido +
                            ' (' +
                            a[1].relacionTitularAdherente +
                            ')'
                          }
                        />
                      );
                    })}
                </FormGroup>
                {errors.derivadosRegister != null && (
                  <p style={{ color: 'red' }}>Debe seleccionar al menos un afiliado</p>
                )}
              </FormControl>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2 }}
                justifyContent={{ xs: 'center', sm: 'flex-end' }}
                alignItems={{ xs: 'strech' }}
              >
                <Button
                  variant='contained'
                  size='medium'
                  startIcon={<HighlightOff />}
                  color='neutral'
                  onClick={handlerCancelar}
                >
                  Cancelar
                </Button>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  startIcon={<Save />}
                  type='submit'
                  loading={loading ? 'show' : 'hide'}
                  content={'Guardar'}
                  fullWidth
                />
              </Stack>
            </CardActions>
          </form>
        </Card>
        <CustomizedSnackbars
          open={success}
          autoHideDuration={10000}
          severity='success'
          message={success}
          onDeleteMessage={handleBorrarMensaje}
        />
        <CustomizedSnackbars
          open={error}
          severity='error'
          message={error}
          onDeleteMessage={handleBorrarMensaje}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TransitoriaPreview
          open={openDialogReporte}
          setOpen={setOpenDialogReporte}
          urlReporte={urlReporte}
          datosDerivacion={datosDerivacionTransitoriaGenerada}
          nombreReporte={'Carta_derivacion_iosper_'}
          handlerCerrarPreview={handlerCancelar}
        ></TransitoriaPreview>
      </Grid>
    </Grid>
  );
};

export default AddTransitoria;
