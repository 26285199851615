import Grid from '@mui/material/Grid';
import establecimientoImg from '../../assets/images/menu/establecimiento.svg';
import prestadorImg from '../../assets/images/menu/prestador.svg';
import farmaciaImg from '../../assets/images/menu/farmacia.svg';
import Title from '../../@iosper/components/Page/Title';
import CardButton from '../../@iosper/components/Card/CardButton';

const Prestadores = () => {
  return (
    <>
      <Title>Prestadores</Title>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <CardButton
            title={'Profesionales'}
            description={'Consultá los profesionales prestadores.'}
            to={'/buscar-prestadores'}
            image={prestadorImg}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <CardButton
            title={'Establecimientos'}
            description={'Consultá los establecimientos prestadores.'}
            to={'/buscar-establecimientos'}
            image={establecimientoImg}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <CardButton
            title={'Farmacias'}
            description={'Consultá las farmacias que trabajan con IOSPER.'}
            to={'/buscar-farmacias'}
            image={farmaciaImg}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Prestadores;
