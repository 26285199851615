import { Grid, ListItem, ListItemText, Typography } from '@mui/material';

const DrawerDataField = ({ label, data, smLabel, smData }) => {
  return (
    <ListItem>
      <Grid container>
        <Grid item xs={12} sm={smLabel} sx={{ mt: -1 }}>
          <ListItemText
            secondary={
              <Typography gutterBottom variant='body2' display='block' sx={{ fontWeight: '600' }}>
                {label}
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12} sm={smData} sx={{ mt: -1 }}>
          <ListItemText primary={data} />
        </Grid>
      </Grid>
    </ListItem>
  );
};

DrawerDataField.defaultProps = {
  smLabel: 4,
  smData: 8,
};

export default DrawerDataField;
