import React, { useState, useEffect } from 'react';
import {
  ToggleButton,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Box,
  Card,
  Alert,
  CardContent,
  Grid,
  TextField,
  Typography,
  FormControl,
  Stack,
  CardActions,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { buscarReintegros } from '../../services/Busquedas/Reintegros';
import LoadingButton from '../../@iosper/components/LoadingButton';
import { useForm } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import { KeyboardArrowDown, KeyboardArrowUp, HighlightOff } from '@mui/icons-material';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { useWidth } from '../../@iosper/utils/UseWidth';
import AfiliadoSelect from '../Afiliados/AfiliadoSelect';
import { findTitularGrupo } from '../../services/Busquedas/Afiliados';

const BuscarReintegros = () => {
  const [loading, setLoading] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [alertResultFind, setAlertResultFind] = useState('');
  const [error, setError] = useState('');
  const [reintegros, setReintegros] = useState([]);
  const [visibleFind, setVisibleFind] = useState(true);
  const [keyComponentAfiliado, setKeyComponentAfiliado] = useState(0);
  const [selectedAfiliado, setSelectedAfiliado] = useState(null);
  const [titular, setTitular] = useState(null);
  const screenWidth = useWidth();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    const buscarTitular = async () => {
      const tit = await findTitularGrupo();
      if (tit !== null) {
        setTitular(tit);
      }
    };

    buscarTitular();
  }, []);

  const find = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    setVisibleInfo(false);
    setAlertResultFind('');
    setError('');

    if (selectedAfiliado == null) {
      setError('Debe seleccionar el afiliado');
      setLoading(false);
    } else {
      const result = await buscarReintegros({
        idAfiliado: selectedAfiliado.afiliado.idAfiliado,
        anio: data.anio,
      });
      if (result && result.length > 0) {
        if (result.length >= 50) {
          setVisibleInfo(true);
        }
        setReintegros(result);
        setVisibleFind(false);
      } else {
        setAlertResultFind('No se han encontrado reintegros');
        //clean();
      }
      setLoading(false);
    }
  };

  const limitCaracter = (e) => {
    if (!Number.isNaN(parseInt(e.target.value))) {
      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4);
    } else {
      e.target.value = null;
    }
  };

  const clean = () => {
    setReintegros([]);
    setVisibleFind(true);
    reset();
    setVisibleInfo(false);
    setSelectedAfiliado(null);
  };

  const handlerLimpiar = () => {
    setKeyComponentAfiliado(1 + Math.random() * (1000 - 1));
    reset();
    setSelectedAfiliado(null);
  };

  const onSelectAfiliado = (afiliado) => {
    setSelectedAfiliado(afiliado);
  };

  const validateAfiliado = () => {
    return selectedAfiliado == null ? false : true;
  };

  const DataCollapseDrawer = (props) => {
    const { reintegro } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <Box sx={{ width: '100%' }}>
        <ToggleButton
          aria-label='expand row'
          value='check'
          onChange={() => {
            setOpen(!open);
          }}
          sx={{ border: 0, p: 0 }}
          fullWidth={true}
        >
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={2}
            flexWrap='nowrap'
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            <Typography
              variant='body1'
              align='left'
              gutterBottom={false}
              sx={{ color: '#000', textTransform: 'none' }}
            >
              {`ID: ${reintegro.id || ''}`}
            </Typography>
            <Typography
              variant='body1'
              align='left'
              gutterBottom={false}
              sx={{ color: '#000', textTransform: 'none' }}
            >
              {`${reintegro.descripcion || ''}`}
            </Typography>
          </Grid>
        </ToggleButton>

        <Collapse in={open} timeout='auto' unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <List>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Liquidación: ${reintegro?.fechaLiquidacion || ''}`} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Acreditación: ${reintegro?.fechaAcreditacion || ''}`} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Importe: $ ${reintegro?.importeReintegro || ''}`} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Nro de Cuenta: ${reintegro?.numeroCuenta || ''}`} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Observación: ${reintegro?.observacion || ''}`} />
              </ListItem>
            </List>
          </Box>
        </Collapse>
      </Box>
    );
  };

  /// -------------------- DATA GRID REINTEGROS --------------------------
  const mapReintegroToRow = (p) => ({
    id: p.acreditacionReintegroVO.numeroSolicitud,
    numeroCuenta: p.acreditacionReintegroVO.numeroCuenta,
    descripcion: p.acreditacionReintegroVO.descripcion,
    fechaAcreditacion: p.acreditacionReintegroVO.fechaAcreditacion,
    fechaLiquidacion: p.acreditacionReintegroVO.fechaLiquidacion,
    importeReintegro: p.acreditacionReintegroVO.importeReintegro,
    observacion: p.acreditacionReintegroVO.observacion,
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: 'Reintegros',
      flex: 1,
      renderCell: (a) => <DataCollapseDrawer reintegro={a.row} />,
    },
    { field: 'id', headerName: 'ID', width: 120 },
    { field: 'descripcion', headerName: 'Tipo', flex: 0.1 },
    { field: 'fechaLiquidacion', headerName: 'Liquidación', flex: 0.1 },
    { field: 'fechaAcreditacion', headerName: 'Acreditación', flex: 0.1 },
    { field: 'importeReintegro', headerName: 'Importe', flex: 0.1 },
    { field: 'numeroCuenta', headerName: 'Nro Cuenta', flex: 0.1 },
    { field: 'observacion', headerName: 'Observación', flex: 0.1 },
  ];

  const handleBorrarMensaje = () => {
    setError('');
    setAlertResultFind('');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography
              tabIndex={0}
              aria-label='Buscar Reintegros'
              gutterBottom
              variant='h5'
              component='h2'
            >
              Buscar Reintegros
            </Typography>
            <Typography variant='string' component={'div'}>
              Acá podrás consultar las acreditaciones de tus reintegros seleccionando afiliado y/o
              año de solicitud de los mismos.
            </Typography>
          </CardContent>

          {visibleFind && (
            <form>
              <CardContent>
                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <AfiliadoSelect
                    key={keyComponentAfiliado}
                    ref={register('afiliadoRegister', {
                      validate: validateAfiliado,
                    })}
                    onSelectAfiliado={onSelectAfiliado}
                  />
                  {errors.afiliadoRegister && (
                    <p style={{ color: 'red' }}>Debe seleccionar el afiliado</p>
                  )}
                </FormControl>
                <FormControl fullWidth={true} variant='outlined'>
                  <TextField
                    variant='standard'
                    type='number'
                    fullWidth
                    margin='normal'
                    name='anio'
                    label='Año de solicitud'
                    onInput={limitCaracter}
                    {...register('anio', {
                      maxLength: {
                        value: 4,
                        message: 'El campo Año de solicitud debe tener 4 dígitos.',
                      },
                      minLength: {
                        value: 4,
                        message: 'El campo Año de solicitud debe tener 4 dígitos.',
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'El campo Año de solicitud debe contener sólo números',
                      },
                    })}
                  />
                  {errors['anio'] && <p style={{ color: 'red' }}>{errors['anio'].message}</p>}
                </FormControl>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2 }}
                  justifyContent={{ xs: 'center', sm: 'flex-end' }}
                  alignItems={{ xs: 'strech' }}
                >
                  <Button
                    variant='contained'
                    size='medium'
                    startIcon={<HighlightOff />}
                    color='neutral'
                    onClick={handlerLimpiar}
                  >
                    Limpiar
                  </Button>
                  <LoadingButton
                    fullWidth={true}
                    type='submit'
                    variant='contained'
                    color='primary'
                    loading={loading ? 'show' : 'hide'}
                    content={'Buscar'}
                    startIcon={<SearchIcon />}
                    onClick={handleSubmit(find)}
                  />
                </Stack>
              </CardActions>
            </form>
          )}

          {!visibleFind && (
            <Grid container direction='column' justifyContent='space-between' spacing={1}>
              <Grid item xs={12} sm={8}>
                <Alert icon={false} severity='info'>
                  <b style={{ color: '#1d1e1eb0' }}>
                    {selectedAfiliado?.personaFisica?.apellido},{' '}
                    {selectedAfiliado?.personaFisica?.nombre} (
                    {selectedAfiliado?.personaFisica?.numeroDocumentoString})
                  </b>
                </Alert>
              </Grid>
              <Grid item>
                <div style={{ height: '50vh', width: '100%' }}>
                  <DataGrid
                    sx={{
                      border: 0,
                      '& .MuiDataGrid-cell': {
                        py: '8px',
                      },
                    }}
                    loading={!reintegros.length}
                    rows={reintegros.map((a) => mapReintegroToRow(a))}
                    columns={columns}
                    getRowHeight={() => 'auto'}
                    getEstimatedRowHeight={() => 200}
                    columnVisibilityModel={{
                      desplegable: screenWidth === 'xs',
                      id: screenWidth !== 'xs',
                      descripcion: screenWidth !== 'xs',
                      fechaLiquidacion: screenWidth !== 'xs',
                      fechaAcreditacion: screenWidth !== 'xs',
                      importeReintegro: screenWidth !== 'xs',
                      numeroCuenta: screenWidth !== 'xs',
                      observacion: screenWidth !== 'xs',
                    }}
                    pagination
                    autoPageSize
                    disableColumnMenu
                  />
                </div>
              </Grid>
              <Grid item>
                <Alert severity='info'>
                  El listado corresponde a las acreditaciones realizadas en la cuenta del afiliado
                  titular: {titular?.afiliado?.personaFisica?.apellido},{' '}
                  {titular?.afiliado?.personaFisica?.nombre}
                </Alert>
              </Grid>
              {visibleInfo ? (
                <Grid item>
                  <Alert severity='info'>
                    Sólo se muestran los primeros 50 resultados, agregue más criterios de búsqueda.
                  </Alert>
                </Grid>
              ) : null}
              <Grid item>
                <Grid container justifyContent='flex-end' spacing={1}>
                  <Grid item>
                    {' '}
                    <Button
                      variant='contained'
                      color='neutral'
                      startIcon={<SearchIcon />}
                      onClick={() => clean()}
                    >
                      Buscar otro
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>

      <CustomizedSnackbars
        open={alertResultFind}
        severity='info'
        message={alertResultFind}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default BuscarReintegros;
