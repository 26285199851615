import { Typography } from '@mui/material';
import { useState, useRef, useEffect } from 'react';

const CountDown = (props) => {
  const { minutes, seconds, onCaducado } = props;
  const [cont, setCont] = useState(null);
  const countDownRef = useRef(null);

  const format = (minutes, seconds) => {
    const min = minutes > 9 ? minutes : '0' + minutes;
    const sec = seconds > 9 ? seconds : '0' + seconds;

    return min + ':' + sec;
  };

  const [timer, setTimer] = useState(format(minutes, seconds));

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    setCont(total);
    return {
      total,
      minutes,
      seconds,
    };
  };

  useEffect(() => {
    const startTimer = (e) => {
      let { total, minutes, seconds } = getTimeRemaining(e);
      if (total >= 0) {
        setTimer(format(minutes, seconds));
      }
    };

    const clearTimer = (e) => {
      setTimer(format(minutes, seconds));

      if (countDownRef.current) clearInterval(countDownRef.current);
      const id = setInterval(() => {
        startTimer(e);
      }, 1000);
      countDownRef.current = id;
    };

    const getDeadTime = () => {
      let deadline = new Date();
      deadline.setSeconds(deadline.getSeconds() + parseInt(seconds));
      deadline.setMinutes(deadline.getMinutes() + parseInt(minutes));
      return deadline;
    };

    clearTimer(getDeadTime());

    return function cleanup() {
      setTimer(null);
    };
  }, [minutes, seconds]);

  useEffect(() => {
    if (cont === 0) {
      onCaducado();
    }
  }, [cont, onCaducado]);

  return <Typography variant='caption'>{timer}</Typography>;
};

CountDown.defaultProps = {
  minutes: '0',
  seconds: '0',
};

export default CountDown;
