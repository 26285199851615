import React, { useMemo, useState } from 'react';
import { Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { ContactoField } from './ContactoField';
import { tipoContacto } from '../../../services/Common/Contacto';
import { ContactoVerificado } from './ContactoVerificado';
import { ContactoEditForm } from './ContactoEditForm';

export const Contacto = ({ infoAfiliado }) => {
  const [afiliado, setAfiliado] = useState(infoAfiliado);
  const [editarContacto, setEditarContacto] = useState(false);

  const itemsContacto = useMemo(() => {
    const result = {};
    afiliado?.contactoPersonaFisica?.map((i) => {
      let index = tipoContacto[i.codigoTipoContacto].name;
      if (!result[index]) {
        result[index] = [];
      }
      result[index] = [...result[index], i];
    });

    return result;
  }, [afiliado]);

  const onHandleDataSaved = (e) => {
    if (e) {
      setAfiliado({ ...afiliado, contactoPersonaFisica: e });
    }
  };

  return (
    <Grid item xs={12} lg={9}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          sx={{ paddingTop: 1, paddingBottom: 0 }}
          title={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span>Contactos</span>
              <Tooltip id='button-link' title='Editar Contacto'>
                <Fab
                  size='small'
                  color='default'
                  aria-label='Editar Contacto'
                  sx={{ boxShadow: 'none' }}
                  onClick={(e) => setEditarContacto(!editarContacto)}
                >
                  {!editarContacto && <EditIcon fontSize='small' color='primary' />}
                  {editarContacto && <CloseIcon fontSize='small' color='primary' />}
                </Fab>
              </Tooltip>
            </Box>
          }
        />
        <CardContent
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: 0,
            marginBottom: -2,
          }}
        >
          <List>
            {!editarContacto && (
              <>
                {itemsContacto?.email?.length > 0 && (
                  <ContactoField label='Correo Electrónico'>
                    <List disablePadding>
                      {itemsContacto?.email?.map((i, k) => (
                        <ContactoVerificado
                          key={k}
                          contacto={i.descripcion}
                          status={i.confirmado ? 'success' : 'error'}
                          message={
                            i.confirmado
                              ? `(Verificado el ${i.fechaConfirmado || ''})`
                              : '(No Verificado)'
                          }
                          link={`/verificar-contacto/${i.idHistorialContactoPersonaFisica}/${i.descripcion}`}
                        />
                      ))}
                    </List>
                  </ContactoField>
                )}

                {itemsContacto?.celular?.length > 0 && (
                  <Box sx={{ marginTop: 0, marginBottom: -1 }}>
                    <Divider sx={{ mx: 2 }} />
                    <ContactoField label='Celular'>
                      <List disablePadding>
                        {itemsContacto?.celular?.map((i, k) => (
                          <Typography key={k} fontWeight={'700'}>
                            {i.descripcion}
                          </Typography>
                        ))}
                      </List>
                    </ContactoField>
                  </Box>
                )}

                {itemsContacto?.telefono?.length > 0 && (
                  <Box sx={{ marginTop: 0, marginBottom: -1 }}>
                    <Divider sx={{ mx: 2 }} />
                    <ContactoField label='Teléfono'>
                      <List disablePadding>
                        {itemsContacto?.telefono?.map((i, k) => (
                          <Typography key={k} fontWeight={'700'}>
                            {i.descripcion}
                          </Typography>
                        ))}
                      </List>
                    </ContactoField>
                  </Box>
                )}
              </>
            )}

            {editarContacto && (
              <Box px={2}>
                <ContactoEditForm
                  contactos={afiliado?.contactoPersonaFisica}
                  afiliado={afiliado?.afiliado}
                  onSaveChanges={(e) => onHandleDataSaved(e)}
                />
              </Box>
            )}

            <Box mx={2} mt={3} mb={1}>
              <Alert severity='info'>
                Para recibir notificaciones de IOSPER es necesario que tengas actualizados los datos
                de contacto.
              </Alert>
            </Box>
          </List>
        </CardContent>
      </Card>
    </Grid>
  );
};
