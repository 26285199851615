import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getObrasSociales } from '../../services/Busquedas/ObrasSociales';

const ObraSocialAutocomplete = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [obraSocialLabel, setObraSocialLabel] = React.useState('Seleccioná la Obra Social');
  const [obraSocial, setObraSocial] = React.useState(null);
  const [obraSocialId, setObraSocialId] = React.useState(0);

  React.useEffect(() => {
    const getObraSocial = async () => {
      const obrasSociales = await getObrasSociales();
      setOptions(obrasSociales);
    };

    try {
      if (options && options.length === 0) {
        getObraSocial();
      }
      if (props?.onDefaultObraSocial) {
        setObraSocialId(props?.onDefaultObraSocial);
        if (obraSocialId !== 0) {
          onSelectObraSocialById(obraSocialId);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [options, props.idObraSocial]);

  const onSelectObraSocialById = (idObraSocial) => {
    if (idObraSocial !== 0) {
      let obraSocialSelected = options.find((a) => a.idObraSocial === Number(idObraSocial));
      if (obraSocialSelected) {
        setObraSocial(obraSocialSelected);
      }
      props.onSelectObraSocial(obraSocialSelected);
    }
  };

  const handleChange = (event, newValue) => {
    setObraSocial(newValue);
    props.onSelectObraSocial(newValue);
    setObraSocialLabel('Obra Social');
  };

  return (
    <Autocomplete
      id='obra-social-autocomplete'
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.idObraSocial === value.idObraSocial}
      getOptionLabel={(a) => `${a.sigla} - ${a.descripcion} (${a.provincia})`}
      onChange={handleChange}
      options={options}
      value={obraSocial}
      loading={options && options.length === 0}
      renderInput={(params) => (
        <TextField
          id='textObraSocial'
          {...params}
          label={obraSocialLabel}
          variant='standard'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {options && options.length === 0 ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
});

export default ObraSocialAutocomplete;
