import { axiosPost } from '../ServiceBase.js';

export const getPrestadorSelector = async (data) => {
  const prestadores = await axiosPost('/v1/busquedas/prestadorSelector', data);
  return prestadores;
};

export const findProfesionales = async (data) => {
  const profesionales = await axiosPost('/v1/busquedas/prestadores/buscar-profesionales', data);
  return profesionales;
};

export const findEstablecimientos = async (data) => {
  const establecimientos = await axiosPost(
    '/v1/busquedas/prestadores/buscar-establecimientos',
    data,
  );
  return establecimientos;
};

export const getUltimosPrestadoresOrden = async (dataQuery) => {
  const prestadores = await axiosPost(`/v1/busquedas/prestadores/get-latest-orden${dataQuery}`);
  return prestadores;
};
