import { HighlightOff } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  Stack,
  Typography,
  Alert,
} from '@mui/material';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import AfiliadoSelect from '../Afiliados/AfiliadoSelect';
import DateTextInput from '../Common/DateTextInput';
import { useForm } from 'react-hook-form';
import { findConsumoPrestacional } from '../../services/Busquedas/ConsumoPrestacional';
import LoadingButton from '../../@iosper/components/LoadingButton';
import CustomizedSnackbars from '../Notifications/SnackBar';
import ConsumoPrestacionalResult from './ConsumoPrestacionalResult';

const BuscarConsumoPrestacional = () => {
  const [error, setError] = useState('');
  const [selectedAfiliado, setSelectedAfiliado] = useState(null);
  const [keyComponentAfiliado, setKeyComponentAfiliado] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visibleFind, setVisibleFind] = useState(true);
  const [consumoPrestacional, setConsumoPrestacional] = useState([]);
  const [alertResultFind, setAlertResultFind] = useState('');

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const onSelectAfiliado = (afiliado) => {
    setSelectedAfiliado(afiliado);
  };

  const find = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    setAlertResultFind('');
    setError('');

    if (data.fechaDesde === '' && data.fechaHasta === '' && selectedAfiliado === null) {
      setError('Debes ingresar por lo menos un criterio para realizar la búsqueda');
      setLoading(false);
    } else {
      try {
        const result = await findConsumoPrestacional({
          idAfiliado: selectedAfiliado?.afiliado.idAfiliado,
          fechaDesde: data.fechaDesde,
          fechaHasta: data.fechaHasta,
        });

        if (result) {
          setConsumoPrestacional(result);
          setVisibleFind(false);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const handleVolver = () => {
    setConsumoPrestacional([]);
    setVisibleFind(true);
    handlerLimpiar();
  };

  const handlerLimpiar = () => {
    setKeyComponentAfiliado(1 + Math.random() * (1000 - 1));
    reset();
    setSelectedAfiliado(null);
  };

  const validateAfiliado = () => {
    return selectedAfiliado == null ? false : true;
  };

  const handleBorrarMensaje = () => {
    setError('');
    setAlertResultFind('');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography
              tabIndex={0}
              aria-label='Buscar Consumo Prestacional'
              gutterBottom
              variant='h5'
              component='h2'
            >
              Buscar Consumo Prestacional
            </Typography>
            <Typography variant='string' component={'div'}>
              Acá podrás seleccionando el afiliado y/o ingresando un rango de fechas ver el detalle
              de tus ordenes, medicamentos, internaciones y demás consumos.
            </Typography>
          </CardContent>

          {visibleFind && (
            <form>
              <CardContent>
                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <AfiliadoSelect
                    key={keyComponentAfiliado}
                    ref={register('afiliadoRegister', {
                      validate: validateAfiliado,
                    })}
                    onSelectAfiliado={onSelectAfiliado}
                  />
                  {errors.afiliadoRegister && (
                    <p style={{ color: 'red' }}>Debe seleccionar el afiliado</p>
                  )}
                </FormControl>

                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <DateTextInput
                    register={register}
                    errors={setError}
                    required={true}
                    label='Desde'
                    fieldName='fechaDesde'
                  />
                  {errors['fechaDesde'] && (
                    <p style={{ color: 'red' }}>{errors['fechaDesde'].message}</p>
                  )}
                </FormControl>
                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <DateTextInput
                    register={register}
                    errors={setError}
                    required={true}
                    label='Hasta'
                    fieldName='fechaHasta'
                  />
                  {errors['fechaHasta'] && (
                    <p style={{ color: 'red' }}>{errors['fechaHasta'].message}</p>
                  )}
                </FormControl>
              </CardContent>

              <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2 }}
                  justifyContent={{ xs: 'center', sm: 'flex-end' }}
                  alignItems={{ xs: 'strech' }}
                >
                  <Button
                    variant='contained'
                    size='medium'
                    startIcon={<HighlightOff />}
                    color='neutral'
                    onClick={handlerLimpiar}
                  >
                    Limpiar
                  </Button>
                  <LoadingButton
                    fullWidth={true}
                    type='submit'
                    variant='contained'
                    color='primary'
                    loading={loading ? 'show' : 'hide'}
                    content={'Buscar'}
                    startIcon={<SearchIcon />}
                    onClick={handleSubmit(find)}
                  />
                </Stack>
              </CardActions>
            </form>
          )}
          {!visibleFind && (
            <Grid container direction='column' justifyContent='space-between' spacing={1}>
              <Grid item xs={12} sm={8}>
                <Alert icon={false} severity='info'>
                  <b style={{ color: '#1d1e1eb0' }}>
                    {selectedAfiliado?.personaFisica?.apellido},{' '}
                    {selectedAfiliado?.personaFisica?.nombre} (
                    {selectedAfiliado?.personaFisica?.numeroDocumentoString})
                  </b>
                </Alert>
              </Grid>
              <Grid item>
                <ConsumoPrestacionalResult consumoPrestacional={consumoPrestacional} />
              </Grid>
              <Grid item>
                <Grid container justifyContent='flex-end' spacing={1}>
                  <Grid item>
                    {' '}
                    <Button
                      variant='contained'
                      color='neutral'
                      startIcon={<SearchIcon />}
                      onClick={() => handleVolver()}
                    >
                      Buscar Otros
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>

      <CustomizedSnackbars
        open={alertResultFind}
        severity='info'
        message={alertResultFind}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default BuscarConsumoPrestacional;
