import { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItemText,
  Box,
  Typography,
  Alert,
} from '@mui/material';
import { findRecetaCronica } from '../../services/Busquedas/RecetasCronicas';
import { useParams } from 'react-router-dom';
import ButtonBack from '../Breadcrumb/ButtonBack';

import DrawerDataField from '../Common/DrawerDataField';
import CustomizedSnackbars from '../Notifications/SnackBar';

const VerRecetaCronica = () => {
  let { numeroReceta } = useParams();
  const [receta, setReceta] = useState(null);
  const [enFarmacia, setEnFarmacia] = useState(false);
  const [txtEnFarmacia, setTxtEnFarmacia] = useState(
    'Los medicamentos solicitados ya pueden ser retirados de la Farmacia.',
  );

  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    async function getInfo(numeroReceta) {
      const response = await findRecetaCronica(numeroReceta);
      setReceta(response);

      if (response?.codigoEstado == '305') {
        //Estado: Medicamento enviado por Drogueria y Recibida por la farmacia
        setEnFarmacia(true);
      }

      let text = '';
      if (response?.nombreFarmacia != '') {
        //Tiene farmacia asignada
        text = response?.nombreFarmacia;
      }

      if (response?.direccionFarmacia != '') {
        //Tiene farmacia asignada
        text = text + ', ' + response?.direccionFarmacia;

        if (response?.localidadFarmacia != '') {
          text = text + ' (' + response?.localidadFarmacia + ')';
        }
      } else if (response?.localidadFarmacia != '') {
        text = text + ', ' + response?.localidadFarmacia;
      }

      if (response?.telefonoFarmacia != '') {
        //Tiene farmacia asignada
        text = text + ', Teléfono: ' + response?.telefonoFarmacia;
      }

      if (text != '') {
        text = 'Los medicamentos solicitados ya pueden ser retirados de la farmacia ' + text;
        setTxtEnFarmacia(text);
      }
    }

    getInfo(numeroReceta);

    return () => {
      setReceta(null);
    };
  }, [numeroReceta]);

  const handleBorrarMensaje = () => {
    setError('');
    setSuccess('');
    setWarning('');
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} mt={3} lg={9} sx={{ mt: 0 }}>
        <ButtonBack marginBotton='1' variant='text' size='small'></ButtonBack>
        <Card>
          <CardHeader
            sx={{ paddingTop: 1, paddingBottom: 0 }}
            title={<span>Receta Médica Crónicos/Esp.</span>}
          />
          <CardContent
            sx={{
              paddingBottom: -5,
              marginBottom: -4,
              paddingTop: -5,
              marginTop: -2,
              marginLeft: 1,
            }}
          >
            {enFarmacia > 0 && (
              <Grid item sx={{ marginTop: 2 }}>
                <Alert severity='success'>
                  <b style={{ color: '#1d1e1eb0' }}>
                    Los medicamentos solicitados ya pueden ser retirados de la farmacia{' '}
                    {receta?.nombreFarmacia},{' '}
                    <a
                      href={`https://www.google.com/maps/search/${encodeURIComponent(
                        receta?.direccionFarmacia + ', ' + receta?.localidadFarmacia,
                      )}`}
                    >
                      {receta?.direccionFarmacia} ({receta?.localidadFarmacia})
                    </a>
                    , Tel:{' '}
                    <a href={`tel:${receta?.telefonoFarmacia || ''}`}>{receta?.telefonoFarmacia}</a>
                  </b>
                </Alert>
              </Grid>
            )}
            <List sx={{ marginTop: 2 }}>
              <DrawerDataField label='N° Receta' data={`${receta?.numeroReceta || ''}`} />
              <Divider />
              <DrawerDataField
                label='Afiliado'
                data={`${receta?.afiliado || ''} (${receta?.numeroDocumento || ''})`}
              />
              <Divider />
              <Divider />
              <DrawerDataField
                label='Prescriptor'
                data={`${receta?.prescriptor || ''} (${receta?.matriculaPrescriptor || ''})`}
              />
              <Divider />
              <DrawerDataField label='Período de Provisión' data={`${receta?.periodo || ''}`} />
              <Divider />
              <DrawerDataField
                label='Plan'
                data={`${receta?.plan || ''} (${receta?.observacionPlan || ''})`}
              />
              <Divider />
              <DrawerDataField
                label='Medicamentos'
                data={receta?.medicamentos?.map((i, k) => (
                  <Grid item xs={12} sm={8} md={9}>
                    <ListItemText primary={`${i.descripcion || ''}`} />
                  </Grid>
                ))}
              />
            </List>
          </CardContent>
        </Card>
      </Grid>

      <CustomizedSnackbars
        open={success}
        severity='success'
        message={success}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={warning}
        severity='warning'
        message={warning}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default VerRecetaCronica;
