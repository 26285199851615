import { TextField } from '@mui/material';

const DateTextInput = ({ fieldName, label, register, errors, variant, required, value }) => {
  return (
    <div>
      <TextField
        variant={variant}
        fullWidth
        name={fieldName}
        label={label}
        type='date'
        defaultValue={value}
        id={fieldName}
        InputLabelProps={{
          shrink: true,
        }}
        {...register(fieldName, {
          required: {
            value: required,
            message: 'Debe ingresar ' + label,
          },
          /*pattern: {
                        value:
                          /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/,
                        message: "El formato de "+ label+" no es correcto.",
                      }*/
        })}
      />
      {errors[fieldName] && <p style={{ color: 'red' }}>{errors[fieldName].message}</p>}
    </div>
  );
};
DateTextInput.defaultProps = {
  required: true,
  variant: 'standard',
};
export default DateTextInput;
