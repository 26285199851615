import { useEffect, useState } from 'react';
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const PasswordTextInput = ({
  fieldName,
  label,
  register,
  autoComplete,
  errors,
  required,
  variant,
  maxLength,
  minLength,
  noValidateFormat,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [format, setFormatValidate] = useState({});

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (noValidateFormat) {
      setFormatValidate({
        required: {
          value: required,
          message: 'Debe ingresar ' + label,
        },
        maxLength: {
          value: maxLength,
          message: label + ' debe tener como máximo ' + maxLength + ' caracteres.',
        },
        minLength: {
          value: minLength,
          message: label + ' debe tener como mínimo ' + minLength + ' caracteres.',
        },
      });
    } else {
      setFormatValidate({
        required: {
          value: required,
          message: 'Debe ingresar ' + label,
        },
        maxLength: {
          value: maxLength,
          message: label + ' debe tener como máximo ' + maxLength + ' caracteres.',
        },
        minLength: {
          value: minLength,
          message: label + ' debe tener como mínimo ' + minLength + ' caracteres.',
        },
        pattern: {
          value: /^(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*?&#.()_-]{6,25}$/,
          message: label + ' no cumple con los requerimientos mínimos',
        },
      });
    }
  }, [noValidateFormat]);

  return (
    <div>
      <FormControl variant={variant} fullWidth>
        <InputLabel htmlFor={`${label}`}>{label}</InputLabel>
        <Input
          id={fieldName}
          type={showPassword ? 'text' : 'password'}
          autoComplete={autoComplete}
          name={fieldName}
          {...register(fieldName, format)}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {errors[fieldName] && <p style={{ color: 'red' }}>{errors[fieldName].message}</p>}
    </div>
  );
};

PasswordTextInput.defaultProps = {
  maxLength: 25,
  minLength: 6,
  required: true,
  variant: 'standard',
  autoComplete: 'on',
};

export default PasswordTextInput;
