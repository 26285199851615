import { useState, useEffect } from 'react';
import CustomizedSnackbars from '../../Notifications/SnackBar';
import { useForm } from 'react-hook-form';
import Pass from '../../Common/PasswordTextInput';
import { nuevoPin } from '../../../services/Auth/Autenticacion';
import LoadingButton from '../../../@iosper/components/LoadingButton';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import PinReseteado from './PinReseteado';
import { Redirect } from 'react-router-dom';
import NumberTextInput from '../../Common/NumberTextInput';

const RecuperarPin = () => {
  const [token, setToken] = useState(null);
  const [idContacto, setIdContacto] = useState(null);
  const [error, setError] = useState('');
  const currentURL = window.location.href;

  useEffect(() => {
    const url = decodeURIComponent(currentURL);
    setToken(new URLSearchParams(new URL(url).search).get('t'));
    setIdContacto(new URLSearchParams(new URL(url).search).get('c'));
  }, [currentURL]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [onPinReseteado, setOnPinReseteado] = useState(false);
  const [onLogin, setOnLogin] = useState(false);

  const cancelarHandler = () => {
    setOnLogin(true);
  };
  const handleClose = () => {
    setLoading(false);
    setOnLogin(true);
  };

  const generarPinHandler = async (data, e) => {
    setResponse('');
    setError('');
    setLoading(true);
    try {
      if (data.pin === data.repetirpin) {
        const res = await nuevoPin(data.pin, token, data.codigoverificacion, idContacto);
        if (res) {
          setOnPinReseteado(true);
          setLoading(false);
        } else {
          setError('Credenciales incorrectas. Intente nuevamente.');
          setLoading(false);
        }
      } else {
        setError('Las contraseñas ingresadas no coinciden.');
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const handleBorrarMensaje = () => {
    setError('');
    setResponse('');
  };

  return onLogin ? (
    <Redirect
      to={{
        pathname: '/',
        state: { from: '/signin' },
      }}
    />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Recuperar Contraseña - PIN
            </Typography>
          </CardContent>

          <form onSubmit={handleSubmit(generarPinHandler)}>
            <CardContent>
              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <NumberTextInput
                  register={register}
                  errors={setError}
                  autoComplete='on'
                  label='Código Verificación'
                  fieldName='codigoverificacion'
                />
                {errors['codigoverificacion'] && (
                  <p style={{ color: 'red' }}>{errors['codigoverificacion'].message}</p>
                )}
              </FormControl>
              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <Pass
                  register={register}
                  errors={setError}
                  label='Nueva Contraseña'
                  autoComplete='new-password'
                  fieldName='pin'
                ></Pass>
                {errors['pin'] && <p style={{ color: 'red' }}>{errors['pin'].message}</p>}
              </FormControl>
              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <Pass
                  register={register}
                  errors={setError}
                  label='Repetir Contraseña'
                  autoComplete='new-password'
                  fieldName='repetirpin'
                ></Pass>
                {errors['repetirpin'] && (
                  <p style={{ color: 'red' }}>{errors['repetirpin'].message}</p>
                )}
              </FormControl>
              <Typography variant='body2' color='textSecondary' component='p' align='center'>
                La contraseña debe tener un mínimo de 6 caracteres y al menos uno debe ser un
                número.
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2 }}
                justifyContent={{ xs: 'center', sm: 'flex-end' }}
                alignItems={{ xs: 'strech' }}
              >
                <Button variant='contained' size='medium' color='neutral' onClick={cancelarHandler}>
                  Cancelar
                </Button>
                <LoadingButton
                  variant='contained'
                  size='medium'
                  color='primary'
                  type='submit'
                  loading={loading ? 'show' : 'hide'}
                  content={'Aceptar'}
                />
              </Stack>
            </CardActions>
          </form>
        </Card>
      </Grid>
      <PinReseteado open={onPinReseteado} onClose={handleClose} />

      <CustomizedSnackbars
        open={response}
        severity='success'
        message={response}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default RecuperarPin;
