import Title from '../../@iosper/components/Page/Title';
import VerTransitorias from '../Derivaciones/VerTransitorias';

const Derivaciones = () => {
  return (
    <>
      <VerTransitorias />
    </>
  );
};

export default Derivaciones;
