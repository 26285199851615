import { useEffect, useState } from 'react';
import {
  constantes,
  guardarContactosdAfiliado,
  tipoContacto as id,
} from '../../../../services/Common/Contacto';

const ERROR = 'error';
const SUCCESS = 'success';
const WARNING = 'warning';

const getContactos = (list) => {
  const result = {};
  list?.map((i) => {
    let index = id[i.codigoTipoContacto].name;
    if (!result[index]) {
      result[index] = [];
    }
    result[index] = [...result[index], i];
  });

  return result;
};

export const useContacto = (contactos = null, afiliado = null) => {
  const [message, setMessage] = useState({ status: '', message: '' });
  const [contactosState, setContactosState] = useState([]);
  const [contactosByTipo, setContactosByTipo] = useState();
  const [initDataForm, setInitDataForm] = useState();
  const [validation, setValidation] = useState({});

  useEffect(() => {
    if (contactos) {
      setContactosState(contactos);
      setContactosByTipo(getContactos(contactos));
    }

    setInitDataForm({
      activo: '',
      codigoTipoContacto: null,
      confirmado: null,
      descripcion: '',
      descripcionTipoContacto: '',
      fechaConfirmado: null,
      fechaDesde: null,
      fechaHasta: null,
      idHistorialContactoPersonaFisica: null,
      idPersonaFisica: afiliado?.idPersonaFisica || null,
      idTipoContacto: null,
      idUsuario: null,
      publicable: '',
    });
  }, []);

  const handleClearMessage = () => {
    setMessage({ status: '', message: '' });
  };

  const handleShowMessage = ({ status = '', message = '' }) => {
    setMessage({ status, message });
  };

  const saveContacto = (contacto) => {
    if (!contacto) return;
    const data = getUpdatedContactos(contacto);
    setContactosState(data);
    return save(data);
  };

  const deleteContacto = (contacto) => {
    if (!contacto) return;
    const data = contactosState.filter(
      (item) => item.idHistorialContactoPersonaFisica !== contacto.idHistorialContactoPersonaFisica,
    );
    setContactosState(data);
    return save(data);
  };

  const save = async (values) => {
    try {
      const body = values || contactosState;
      const response = await guardarContactosdAfiliado(afiliado?.idAfiliado, body);

      if (response?.status === 'error') {
        setMessage({ status: WARNING, message: response.message });
        return;
      }

      const result = response;
      setContactosState(result);
      setContactosByTipo(getContactos(result));
      setMessage({ status: SUCCESS, message: 'Los contactos se han guardado con éxito!.' });
      return result;
    } catch (error) {
      if (error?.data?.status === 'error') {
        setMessage({ status: ERROR, message: error.data.message });
      }
    }
  };

  const getUpdatedContactos = (value) => {
    const existe = contactosState.some(
      (contacto) =>
        contacto?.idHistorialContactoPersonaFisica === value?.idHistorialContactoPersonaFisica,
    );
    if (!existe) {
      return [...contactosState, value];
    }

    const contactosActualizados = contactosState.map((contacto) =>
      contacto.idHistorialContactoPersonaFisica === value.idHistorialContactoPersonaFisica
        ? value
        : contacto,
    );
    return contactosActualizados;
  };

  const onChangeTipoContacto = (id) => {
    setValidationContacto(id);
  };

  const setValidationContacto = (id) => {
    if (!id) {
      setValidation({
        required: 'Ingrese primero un tipo de contacto',
      });
      return;
    }

    let validationConfig = {};
    const idStr = String(id);

    switch (idStr) {
      case constantes.TIPO_TELEFONO:
        validationConfig = {
          required: 'Ingrese un teléfono (####-#######)',
          pattern: {
            message: 'El formato no es correcto (####-#######)',
            value: /^0[0-9]{2,4}-4[0-9]{5,7}$/,
          },
          validate: (value) => {
            const exist = contactosByTipo?.telefono?.some(
              (contacto) => contacto?.descripcion === value,
            );
            return !exist || 'El valor coincide con un contacto ya existente';
          },
        };
        break;
      case constantes.TIPO_EMAIL:
        validationConfig = {
          required: 'Ingrese un correo electrónico (nombre@dominio.com)',
          pattern: {
            message: 'El formato no es correcto (nombre@dominio.com)',
            value:
              /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          },
          validate: (value) => {
            const exist = contactosByTipo?.email?.some(
              (contacto) => contacto?.descripcion === value,
            );
            return !exist || 'El valor coincide con un contacto ya existente';
          },
        };
        break;
      case constantes.TIPO_CELULAR:
        validationConfig = {
          required: 'Ingrese un celular (####-########)',
          pattern: {
            message: 'El formato no es correcto (####-########)',
            value: /^0[0-9]{2,4}-15[0-9]{5,8}$/,
          },
          validate: (value) => {
            const exist = contactosByTipo?.celular?.some(
              (contacto) => contacto?.descripcion === value,
            );
            return !exist || 'El valor coincide con un contacto ya existente';
          },
        };
        break;

      default:
        validationConfig = {
          required: 'Ingrese primero un tipo de contacto',
        };
        break;
    }

    if (validationConfig) {
      setValidation(validationConfig);
    }
  };

  return {
    clearMessage: handleClearMessage,
    contactosByTipo,
    deleteContacto,
    initDataForm,
    message,
    onChangeTipoContacto,
    onlyOneContacto: contactosState?.length === 1,
    saveContacto,
    showMessage: handleShowMessage,
    validacionContacto: validation,
  };
};
