import { Button } from '@mui/material';
import { withRouter } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ButtonBack = (props) => {
  return (
    <div>
      <Button
        variant={props.variant || 'text'}
        color='primary'
        size={props.size || 'small'}
        startIcon={<ArrowBackIcon />}
        onClick={props.onClick || props.history.goBack}
        sx={{ mb: Number(props.marginBotton) || 1 }}
        aria-label='Volver a la página anterior'
      >
        Atrás
      </Button>
    </div>
  );
};

export default withRouter(ButtonBack);
