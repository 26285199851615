import { useState, useContext, useEffect, useMemo, createContext } from 'react';
import { useUser } from '../Auth/User';
import { getAfiliado } from '../../services/Busquedas/Afiliados';

export const InfoAfiliadoContext = createContext(null);

export const InfoAfiliadoProvider = (props) => {
  const [infoAfiliado, setInfoAfiliado] = useState(null);
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      const getInfo = async () => {
        const res = await getAfiliado();
        setInfoAfiliado(res);
        sessionStorage.setItem('infoAfiliado', JSON.stringify(res));
      };

      try {
        const info = JSON.parse(sessionStorage.getItem('infoAfiliado'));

        if (!info) {
          getInfo();
        } else {
          setInfoAfiliado(info);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [user]);

  const values = useMemo(() => ({ infoAfiliado }), [infoAfiliado]);

  return (
    <InfoAfiliadoContext.Provider value={values}>{props.children}</InfoAfiliadoContext.Provider>
  );
};

export const useInfoAfiliado = () => {
  const context = useContext(InfoAfiliadoContext);
  if (context === undefined) {
    throw new Error('`useAfiliados` debe estar dentro de `InfoAfiliadoProvider`');
  }
  return context;
};
