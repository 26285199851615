import React, { useEffect, useState } from 'react';
import {
  Grid,
  ListItem,
  ListItemText,
  Box,
  ToggleButton,
  Collapse,
  List,
  Stack,
  Menu,
  MenuItem,
  ListItemButton,
  Alert,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown, OpenInNew, ForwardToInbox } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useWidth } from '../../@iosper/utils/UseWidth';
import { useHistory } from 'react-router-dom';
import StyledDataGridRecetas from './StyledDataGridRecetas';

const DataCollapseDrawer = (props) => {
  const { recetaCronica } = props;
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ width: '100%', marginLeft: -1, paddingLeft: -2, marginRight: -5, paddingRight: -5 }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0, p: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          sx={{
            marginRight: '-30px',
            marginLeft: '-15px',
            marginBottom: '-8px',
            marginTop: '-8px',
          }}
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={4}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <List sx={{ py: -5, px: 0, marginBottom: '0px', marginTop: '-10px' }}>
            <ListItem sx={{ py: 0, px: -2 }}>
              <ListItemText
                primary={`Nro.: ${recetaCronica.numero}`}
                secondary={
                  <>
                    <Typography
                      component='span'
                      variant='caption'
                      fontWeight='bold'
                      sx={{ textTransform: 'none' }}
                    >
                      Periodo
                    </Typography>
                    {' - '}
                    <Typography component='span' variant='caption'>
                      {recetaCronica.periodo || ''}
                    </Typography>
                  </>
                }
                primaryTypographyProps={{
                  textTransform: 'none',
                  fontSize: '16px',
                }}
              />
            </ListItem>
          </List>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box sx={{ margin: 0 }}>
          <List>
            <ListItem
              sx={{
                paddingTop: -2,
                marginTop: -2,
                marginBottom: -1,
                py: 0,
              }}
            >
              <ListItemText
                secondary={
                  <>
                    <Typography component='span' variant='caption' fontWeight='bold'>
                      Plan:
                    </Typography>{' '}
                    <Typography component='span' variant='caption'>
                      {recetaCronica.plan}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <ListItem
              sx={{
                paddingTop: -2,
                marginTop: -2,
                marginBottom: -1,
                py: 0,
              }}
            >
              <ListItemText
                secondary={
                  <>
                    <Typography component='span' variant='caption' fontWeight='bold'>
                      Medicamentos:
                    </Typography>{' '}
                  </>
                }
              />
            </ListItem>
            <ListItem
              sx={{
                paddingTop: -2,
                marginTop: -2,
                marginBottom: -1,
                py: 0,
              }}
            >
              <ListItemText
                secondary={
                  <>
                    <Typography component='span' variant='caption'>
                      {recetaCronica.medicamentos}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <ListItem
              sx={{
                paddingTop: -2,
                marginTop: -2,
                marginBottom: -1,
                py: 0,
              }}
            >
              <ListItemText
                secondary={
                  <>
                    <Typography component='span' variant='caption' fontWeight='bold'>
                      Prescriptor:
                    </Typography>{' '}
                    <Typography component='span' variant='caption'>
                      {recetaCronica.prescriptor}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const RecetaCronicaTableResult = (props) => {
  const history = useHistory();

  const screenWidth = useWidth();

  const [recetaCronica, setRecetaCronica] = useState(null);
  const [loadingResults, setLoadingResults] = useState(null);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (props.recetasCronicas.length > 1) {
      setLoadingResults(false);
    }

    if (props.recetasCronicas.length > 99) {
      setShowMessage(true);
    }
  }, [props.recetasCronicas]);

  const mapListMedicamentos = (a) => {
    return ' ' + a;
  };

  const mapRecetaCronicaToRow = (a) => ({
    id: a.numeroRecetario,
    afiliado: a.afiliado,
    numero: a.numeroRecetario,
    fechaAutorizacion: a.fechaAutorizacion,
    periodo: a.periodo,
    plan: `${a.plan} - ${a.nombrePlan}`,
    medicamentos: a.medicamentos.map((m) => m).join(' - '),
    numeroDocumento: a.documento,
    prescriptor: `${a.prescriptor} (${a.matriculaPrescriptor})`,
  });

  const columns = React.useMemo(() => [
    {
      field: 'desplegable',
      headerName: 'Recetas crónicas:',
      flex: 1,
      renderCell: (a) => <DataCollapseDrawer recetaCronica={a.row} />,
    },
    { field: 'numero', headerName: 'Nro.', width: 100 },
    { field: 'plan', headerName: 'Plan', width: 230 },
    { field: 'medicamentos', headerName: 'Medicamentos', width: 230 },
    { field: 'periodo', headerName: 'Periodo', width: 90 },
    { field: 'prescriptor', headerName: 'Prescriptor', width: 360 },
    { field: 'fechaAutorizacion', headerName: 'Autorización', width: 110 },
  ]);

  const dataGridRecetas = props.recetasCronicas && (
    <Grid
      container
      direction='column'
      justifyContent='space-between'
      spacing={1}
      sx={{ paddingTop: '-10px', marginTop: '-10px' }}
    >
      <Grid item>
        <Alert icon={false} severity='info'>
          <b style={{ color: '#1d1e1eb0' }}>
            {props?.selectedAfiliado?.personaFisica?.apellido},{' '}
            {props?.selectedAfiliado?.personaFisica?.nombre} (
            {props?.selectedAfiliado?.personaFisica?.numeroDocumentoString})
          </b>
        </Alert>
      </Grid>
      <Grid item>
        <div style={{ height: '60vh', width: '100%' }}>
          <StyledDataGridRecetas
            getRowClassName={(params) => `super-app-theme--${params.row.codigoEstado}`}
            sx={{
              border: 0,
              '& .MuiDataGrid-cell': {
                py: '5px',
              },
            }}
            columns={columns}
            loading={loadingResults}
            rows={props.recetasCronicas.map((a, index) => mapRecetaCronicaToRow(a, index))} //
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}
            components={{
              NoRowsOverlay: () => (
                <Stack height='100%' alignItems='center' justifyContent='center'>
                  Sin Recetas Crónicas.
                </Stack>
              ),
            }}
            columnVisibilityModel={{
              desplegable: screenWidth === 'xs',
              id: screenWidth !== 'xs',
              numero: screenWidth !== 'xs',
              fechaAutorizacion: screenWidth !== 'xs',
              prescriptor: screenWidth !== 'xs',
              periodo: screenWidth !== 'xs',
              plan: screenWidth !== 'xs',
              medicamentos: screenWidth !== 'xs',
            }}
            pagination
            autoPageSize
            disableColumnMenu
          />
        </div>
      </Grid>
      {showMessage && (
        <Grid item visibled>
          <Alert severity='info'>Solo se mostraran las 100 recetas mas nuevas.</Alert>
        </Grid>
      )}
    </Grid>
  );

  return (
    <Grid container spacing={2} sx={{ paddingTop: '-10px', marginTop: '-10px' }}>
      <Grid item xs={12} sx={{ paddingTop: '-20px', marginTop: '-10px' }}>
        <Card sx={{ width: '100%', paddingTop: '-20px', marginTop: '-10px' }}>
          <CardContent>{dataGridRecetas}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default RecetaCronicaTableResult;
