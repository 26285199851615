import React, { useEffect, useState } from 'react';
import {
  Grid,
  ListItem,
  ListItemText,
  Box,
  ToggleButton,
  Collapse,
  List,
  Stack,
  Menu,
  MenuItem,
  ListItemButton,
  Alert,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  OpenInNew,
  GetApp,
  ForwardToInbox,
  MoreVert,
} from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useWidth } from '../../@iosper/utils/UseWidth';
import { useHistory } from 'react-router-dom';
import { getReporteRecetaElectronica } from '../../services/RecetaElectronica/RecetaElectronica';
import EnviarRecetaElectronicaPopUp from './EnviarRecetaElectronicaPopUp';
import StyledDataGridRecetas from './StyledDataGridRecetas';
import CustomizedSnackbars from '../Notifications/SnackBar';

const DataCollapseDrawer = (props) => {
  const { recetaElectronica } = props;
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDescargar = async (filename, idRecetaElectronica) => {
    try {
      let idsRecetas = [];
      idsRecetas.push(idRecetaElectronica);
      const urlReporte = await getReporteRecetaElectronica({
        idsRecetas: idsRecetas,
      });
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('href', urlReporte);
      link.setAttribute('download', 'Receta Electrónica ' + filename + '.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };
  const handleEnviar = (recetaElectronica) => {
    props.onEnviar(recetaElectronica);
    handleClose();
  };

  return (
    <Box sx={{ width: '100%', marginLeft: -1, paddingLeft: -2, marginRight: -5, paddingRight: -5 }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0, p: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          sx={{
            marginRight: '-30px',
            marginLeft: '-15px',
            marginBottom: '-8px',
            marginTop: '-8px',
          }}
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={4}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <List sx={{ py: -5, px: 0, marginBottom: '0px', marginTop: '-10px' }}>
            <ListItem sx={{ py: 0, px: -2 }}>
              <ListItemText
                primary={`Nro.: ${recetaElectronica.numero}`}
                secondary={
                  <>
                    <Typography
                      component='span'
                      variant='caption'
                      fontWeight='bold'
                      sx={{ textTransform: 'none' }}
                    >
                      {recetaElectronica.estado}
                    </Typography>
                    {' - '}
                    <Typography component='span' variant='caption'>
                      {recetaElectronica.fechaEstado || ''}
                    </Typography>
                  </>
                }
                primaryTypographyProps={{
                  textTransform: 'none',
                  fontSize: '16px',
                }}
              />
            </ListItem>
          </List>
          <List sx={{ p: 0 }}>
            <ListItem sx={{ justifyContent: 'flex-end', p: 0 }}>
              <ListItemButton
                aria-label='more'
                sx={{ p: 0 }}
                id='long-button'
                aria-controls={openAction ? 'long-menu' : undefined}
                aria-expanded={openAction ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
              >
                <MoreVert />
              </ListItemButton>
              <Menu
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={openAction}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: 50 * 4.5,
                    width: '17ch',
                  },
                }}
              >
                <MenuItem
                  key={'descargar'}
                  onClick={() => {
                    handleDescargar(recetaElectronica.afiliado, recetaElectronica.id);
                  }}
                  disabled={recetaElectronica.codigoEstado !== '1'}
                >
                  <GridActionsCellItem icon={<GetApp />} title='Reporte PDF' label='Descargar' />
                  Descargar
                </MenuItem>
                <MenuItem
                  key={'enviar'}
                  onClick={() => {
                    handleEnviar(recetaElectronica);
                  }}
                  disabled={recetaElectronica.codigoEstado !== '1'}
                >
                  <GridActionsCellItem
                    icon={<ForwardToInbox />}
                    disabled={recetaElectronica.codigoEstado !== '1'}
                    title='Enviar'
                    label='Enviar'
                  />
                  Enviar
                </MenuItem>
              </Menu>
            </ListItem>
          </List>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box sx={{ margin: 0 }}>
          <List>
            {recetaElectronica.codigoEstado === '1' && (
              <ListItem
                sx={{
                  paddingTop: -2,
                  marginTop: -2,
                  marginBottom: -1,
                  py: 0,
                }}
              >
                <ListItemText
                  secondary={
                    <>
                      <Typography component='span' variant='caption' fontWeight='bold'>
                        Vencimiento:
                      </Typography>{' '}
                      <Typography component='span' variant='caption'>
                        {recetaElectronica.vencimiento}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            )}

            {/**ENTREGADA */}
            {recetaElectronica.codigoEstado === '2' && (
              <ListItem
                sx={{
                  paddingTop: -2,
                  marginTop: -2,
                  marginBottom: 1,
                  py: 0,
                }}
              >
                <ListItemText
                  secondary={
                    <>
                      <Typography component='span' variant='caption' fontWeight='bold'>
                        Farmacia:
                      </Typography>{' '}
                      <Typography component='span' variant='caption'>
                        {recetaElectronica.farmacia}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            )}

            <ListItem
              sx={{
                paddingTop: recetaElectronica.codigoEstado !== '1' ? -2 : 0,
                marginTop: recetaElectronica.codigoEstado !== '1' ? -2 : 0,
                py: 0,
              }}
            >
              <ListItemText
                secondary={
                  <>
                    <Typography component='span' variant='caption' fontWeight='bold'>
                      Rp1:
                    </Typography>{' '}
                    <Typography component='span' variant='caption'>
                      {recetaElectronica.rp1}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            {recetaElectronica.rp2 !== '-' && (
              <ListItem sx={{ py: 0, marginTop: -1 }}>
                <ListItemText
                  secondary={
                    <>
                      <Typography component='span' variant='caption' fontWeight='bold'>
                        Rp2:
                      </Typography>{' '}
                      <Typography component='span' variant='caption'>
                        {recetaElectronica.rp2}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            )}
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const RecetaElectronicaTableResult = (props) => {
  const history = useHistory();
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up('sm'));
  //const esMobile = matches ? false : true;
  const [success, setSuccess] = useState('');

  const screenWidth = useWidth();

  const [recetaElectronica, setRecetaElecctronica] = useState(null);
  const [onOpenEnviar, setOpenEnviar] = useState(null);
  const [loadingResults, setLoadingResults] = useState(null);

  useEffect(() => {
    if (props.recetasElectronicas.length > 1) {
      setLoadingResults(false);
    }
  }, [props.recetasElectronicas]);

  const handleEnviar = (recetaElectronica) => {
    setRecetaElecctronica(recetaElectronica);
    setOpenEnviar(true);
  };

  const handleEnviada = async (enviada) => {
    setSuccess('La receta ha sido enviada correctamente');
    setRecetaElecctronica(null);
    setOpenEnviar(false);
  };

  const previewClic = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        handlePreview(id);
      });
    },
    [],
  );

  const enviarClic = React.useCallback(
    (value) => () => {
      handleEnviar(value);
    },
    [],
  );

  const handlePreview = async (idRecetaElectronica) => {
    try {
      let idsRecetas = [];
      idsRecetas.push(idRecetaElectronica);
      const urlReporte = await getReporteRecetaElectronica({
        idsRecetas: idsRecetas,
      });
      history.push({
        pathname: '/previsualizar',
        state: { url: urlReporte } || {}, // your data array of objects
      });
    } catch (e) {
      console.error(e);
    }
  };

  const mapRecetaElectronicaToRow = (a) => ({
    id: a.recetaElectronica.idRecetaElectronica,
    numero: a.recetaElectronica.numero,
    resume: a,
    idrecetaElectronica: a.recetaElectronica.idRecetaElectronica,
    estado: a.estado.descripcion,
    codigoEstado: a.estado.codigo,
    farmacia: !a.farmacia.nombre ? '-' : `${a.farmacia.nombre} (${a.farmacia.localidad})`,
    correo: a.afiliado.correo,
    fechaEstado: a.historialEstado.fechaDesde,
    fechaPrescripcion: a.recetaElectronica.fechaPrescripcion,
    vencimiento: a.recetaElectronica.vencimiento,
    afiliado: `${a.personaFisicaAfiliado.apellido}, ${a.personaFisicaAfiliado.nombre}`,
    dni: a.personaFisicaAfiliado.numeroDocumento,
    prescriptor: `${a.personaFisicaProfesional.apellido}, ${a.personaFisicaProfesional.nombre}`,
    rp1: !a.registro1
      ? '-'
      : `(${a.registro1?.drogas?.join(', ')}) ${a?.registro1?.presentacion}, Cant.:${
          a?.registro1?.cantidad
        } - Cie10: ${a?.registro1?.cie10Descripcion} `,
    rp2: !a.registro2
      ? '-'
      : `(${a.registro2?.drogas?.join(', ')}) ${a?.registro2?.presentacion}, Cant.:${
          a?.registro2?.cantidad
        } - Cie10: ${a?.registro2?.cie10Descripcion} `,
  });

  const columns = React.useMemo(
    () => [
      {
        field: 'desplegable',
        headerName: 'Recetas electrónicas:',
        flex: 1,
        renderCell: (a) => (
          <DataCollapseDrawer
            recetaElectronica={a.row}
            onEnviar={(e) => {
              handleEnviar(e);
            }}
          />
        ),
      },
      { field: 'numero', headerName: 'Nro.', width: 80 },
      { field: 'afiliado', headerName: 'Afiliado', flex: 0.08 },
      { field: 'estado', headerName: 'Estado', width: 85 },
      { field: 'fechaPrescripcion', headerName: 'Prescripción', width: 100 },
      { field: 'vencimiento', headerName: 'Vencimiento', width: 100 },
      { field: 'rp1', headerName: 'Rp1', flex: 0.1 },
      { field: 'rp2', headerName: 'Rp2', flex: 0.1 },
      { field: 'farmacia', headerName: 'Farmacia', width: 80 },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Acciones',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<OpenInNew />}
            label='Ver'
            disabled={params.row.codigoEstado !== '1'}
            onClick={previewClic(params.row.id)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<ForwardToInbox />}
            label='Enviar'
            disabled={params.row.codigoEstado !== '1'}
            onClick={enviarClic(params.row)}
            showInMenu
          />,
        ],
      },
    ],
    [previewClic, enviarClic],
  );
  const handleBorrarMensaje = () => {
    setSuccess('');
  };

  const dataGridRecetas = props.recetasElectronicas && (
    <Grid
      container
      direction='column'
      justifyContent='space-between'
      spacing={1}
      sx={{ paddingTop: '-10px', marginTop: '-10px' }}
    >
      <Grid item>
        <Alert icon={false} severity='info'>
          <b style={{ color: '#1d1e1eb0' }}>
            {props?.selectedAfiliado?.personaFisica?.apellido},{' '}
            {props?.selectedAfiliado?.personaFisica?.nombre} (
            {props?.selectedAfiliado?.personaFisica?.numeroDocumentoString})
          </b>
        </Alert>
      </Grid>
      <Grid item>
        <div style={{ height: '60vh', width: '100%' }}>
          <StyledDataGridRecetas
            getRowClassName={(params) => `super-app-theme--${params.row.codigoEstado}`}
            sx={{
              border: 0,
              '& .MuiDataGrid-cell': {
                py: '5px',
              },
            }}
            columns={columns}
            loading={loadingResults}
            rows={props.recetasElectronicas.map((a, index) => mapRecetaElectronicaToRow(a, index))} //
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}
            components={{
              NoRowsOverlay: () => (
                <Stack height='100%' alignItems='center' justifyContent='center'>
                  Sin Recetas Electrónicas.
                </Stack>
              ),
            }}
            columnVisibilityModel={{
              desplegable: screenWidth === 'xs',
              id: screenWidth !== 'xs',
              numero: screenWidth !== 'xs',
              fechaPrescripcion: screenWidth !== 'xs',
              vencimiento: screenWidth !== 'xs',
              estado: screenWidth !== 'xs',
              afiliado: screenWidth !== 'xs',
              farmacia: screenWidth !== 'xs',
              rp1: screenWidth !== 'xs',
              rp2: screenWidth !== 'xs',
              actions: screenWidth !== 'xs',
            }}
            pagination
            autoPageSize
            disableColumnMenu
          />
        </div>
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={2} sx={{ paddingTop: '-10px', marginTop: '-10px' }}>
      <Grid item xs={12} sx={{ paddingTop: '-20px', marginTop: '-10px' }}>
        <Card sx={{ width: '100%', paddingTop: '-20px', marginTop: '-10px' }}>
          <CardContent>{dataGridRecetas}</CardContent>
        </Card>
      </Grid>
      {onOpenEnviar ? (
        <EnviarRecetaElectronicaPopUp
          open={onOpenEnviar}
          recetaElectronica={recetaElectronica}
          setRecetaEnviada={handleEnviada}
          onCancelar={() => setOpenEnviar(false)}
        />
      ) : null}

      <CustomizedSnackbars
        open={success}
        autoHideDuration={8000}
        severity='success'
        message={success}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default RecetaElectronicaTableResult;
