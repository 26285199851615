// *https://www.registers.service.gov.uk/registers/country/use-the-api*

import React, { useEffect } from 'react';
import { useAfiliados } from './AfiliadosProvider';
import { Select, Box, InputLabel, MenuItem, Typography } from '@mui/material';

const AfiliadoSelect = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const { afiliados } = useAfiliados();
  const [afiliado, setAfiliado] = React.useState(null);
  const [afiliadoId, setAfiliadoId] = React.useState('');

  useEffect(() => {
    if (props.onDefaultAfiliado) {
      setAfiliadoId(props.onDefaultAfiliado);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onDefaultAfiliado]);

  useEffect(() => {
    if (afiliadoId !== 0) {
      onSelectAfiliadoById(afiliadoId);
    }
  }, [afiliadoId]);

  useEffect(() => {
    if (afiliados.length == 1) {
      handleChange(afiliados[0].afiliado.idAfiliado);
    }
  }, [afiliados]);

  const handleChange = (newValue) => {
    if (newValue != '') {
      let afiliadoSelected = afiliados.find((a) => a.afiliado.idAfiliado === Number(newValue));
      setAfiliadoId(newValue);
      setAfiliado(afiliadoSelected);
      props.onSelectAfiliado(afiliadoSelected);
    }
  };

  const onSelectAfiliadoById = (idAfiliado) => {
    if (idAfiliado !== 0) {
      let afiliadoSelected = afiliados.find((a) => a.afiliado.idAfiliado === Number(idAfiliado));
      if (afiliadoSelected) {
        setAfiliado(afiliadoSelected);
      }
      props.onSelectAfiliado(afiliadoSelected);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <InputLabel sx={{ marginLeft: -2 }} id='afiliado-input-label'>
        Seleccioná el Afiliado
      </InputLabel>
      <Select
        id='afiliado-select'
        value={afiliadoId}
        variant='standard'
        onChange={(ev) => handleChange(ev.target.value)}
        defaultValue=''
        label='Seleccioná el Afiliado'
        fullWidth={true}
      >
        {afiliados.map((a) => (
          <MenuItem key={a.afiliado?.idAfiliado} value={a.afiliado?.idAfiliado}>
            <Typography variant='body2'>
              ({a.afiliado?.numeroDocumentoFormateado}) {a.personaFisica?.apellido},{' '}
              {a.personaFisica?.nombre}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
});

export default AfiliadoSelect;
