import {
  Box,
  Button,
  Collapse,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
  ToggleButton,
  Typography,
  FormControl,
  Alert,
  Input,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import { getPrestadorSelector } from '../../services/Busquedas/Prestadores';
import { useWidth } from '../../@iosper/utils/UseWidth';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { DataGrid } from '@mui/x-data-grid';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import LoadingButton from '../../@iosper/components/LoadingButton';
import { useForm } from 'react-hook-form';
import ModalBase from '../Controls/ModalBase';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const DataCollapseDrawer = (props) => {
  const { prestador } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        selected={open}
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <Typography
            variant='body1'
            align='left'
            gutterBottom={false}
            sx={{ color: '#000', textTransform: 'none' }}
          >
            {`${prestador.nombre || ''} - ${prestador?.descripcionTipo || ''}`}
          </Typography>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <List>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary={`Matrícula - ${prestador?.matricula || ''}`} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText
                primary='Dirección'
                secondary={`${prestador?.domicilio || ''}, ${prestador?.localidad || ''}`}
              />
            </ListItem>
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const PrestadorSelector = React.forwardRef((props, ref) => {
  const screenWidth = useWidth();
  const { modoPrescriptor = false, onSelectedPrestador } = props;
  const [prestadores, setPrestadores] = useState([]);
  const [rowSelectedDataGrid, setRowSelectedDataGrid] = useState(null);
  const [disabledSearchPrestador, setDisabledSearchPrestador] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertResultFind, setAlertResultFind] = useState('');
  const [prestador, setPrestador] = useState('');
  const [visibleFind, setVisibleFind] = useState(true);
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [textFieldPrestador, setTextFieldPrestador] = useState('Buscá el Prestador');
  const [error, setError] = useState('');
  const [estadoPrestador, setEstadoPrestador] = useState({
    estado: '',
    inactivo: false,
  });

  const setValuePrestador = (value) => {
    props.onSelectedPrestador(value);
    setPrestador(`(${value.matricula}) ${value.nombre}`);
    setDisabledSearchPrestador(true);
    resetEstadoPrestador(value.codigoEstado);
  };

  useEffect(() => {
    if (props.value != null) {
      setValuePrestador(props.value);
    }
  }, [props.value]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const resetEstadoPrestador = (codigoEstado) => {
    if (codigoEstado !== null && codigoEstado !== '' && codigoEstado !== '1') {
      setEstadoPrestador({
        estado: 'inactivo',
        inactivo: true,
      });
    } else {
      setEstadoPrestador({
        estado: '',
        inactivo: false,
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openCloseModal = () => {
    if (!open) {
      clean();
    }
    setOpen(!open);
  };

  const clean = () => {
    setPrestadores([]);
    onSelectedPrestador(null);
    resetEstadoPrestador(null);
    setVisibleFind(true);
    setRowSelectedDataGrid(null);
    setPrestador('');
    reset();
    setVisibleInfo(false);
  };

  const find = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    setVisibleInfo(false);
    setAlertResultFind('');
    setError('');

    if (data.matricula === '' && data.nombre === '') {
      setError('Debes ingresar nombre y/o matrícula para realizar tu búsqueda');
      setLoading(false);
    } else {
      const result = await getPrestadorSelector({
        matricula: data.matricula,
        nombre: data.nombre,
        modoPrescriptor: modoPrescriptor,
        soloActivos: false,
      });
      if (result && result.length > 0) {
        if (result.length >= 50) {
          setVisibleInfo(true);
        }
        setPrestadores(result);
        setVisibleFind(false);
      } else {
        setAlertResultFind('No se han encontrado prestadores');
        clean();
      }
      setLoading(false);
    }
  };

  const selectHandler = (e) => {
    e.preventDefault();
    onSelectedPrestador(rowSelectedDataGrid);
    setPrestador(`(${rowSelectedDataGrid.matricula}) ${rowSelectedDataGrid.nombre}`);
    setDisabledSearchPrestador(true);
    resetEstadoPrestador(rowSelectedDataGrid.codigoEstado);
    openCloseModal();
    reset();
    setTextFieldPrestador('Prestador');
    setVisibleInfo(false);
  };

  const handleIconClose = (e) => {
    e.preventDefault();
    setPrestador('');
    setDisabledSearchPrestador(false);
    setTextFieldPrestador('Buscá el Prestador');
    onSelectedPrestador(null);
  };

  const handleOnClickIconSearch = (e) => {
    e.preventDefault();
    openCloseModal();
  };

  const handleClickPrestador = (e) => {
    e.preventDefault();
    if (!disabledSearchPrestador) {
      openCloseModal();
    }
  };

  const handleOnRowDoubleClick = (data, e) => {
    e.preventDefault();
    selectHandler(e);
    setRowSelectedDataGrid(data);
  };

  /// -------------------- DATA GRID PRESTADORES --------------------------
  const mapPrestadorToRow = (p) => ({
    id: p.idPrestador,
    idPrestador: p.idPrestador,
    idProfesional: p.idprofesional,
    idEstablecimiento: p.idEstablecimiento,
    codigoEstado: p.codigoEstado,
    colorFondo: p.colorFondo,
    colorLetra: p.colorLetra,
    matricula: p.matricula,
    tipo: p.tipo,
    descripcionTipo: p.descripcionTipo,
    nombre: p.nombre,
    domicilio: p.domicilio,
    localidad: p.localidad,
    // estado: (a.estado.descripcion) ? "Activo" : "Inactivo",
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: 'Prestador',
      flex: 1,
      renderCell: (a) => <DataCollapseDrawer prestador={a.row} />,
    },
    { field: 'matricula', headerName: 'Matrícula', width: 120 },
    { field: 'descripcionTipo', headerName: 'Especialidad', flex: 0.1 },
    { field: 'nombre', headerName: 'Nombre', flex: 0.1 },
    { field: 'domicilio', headerName: 'Domicilio', flex: 0.1 },
    { field: 'localidad', headerName: 'Localidad', flex: 0.1 },
  ];

  const handleBorrarMensaje = () => {
    setAlertResultFind('');
    setError('');
  };

  const dataGridPrestadores = (
    <Grid container direction='column' justifyContent='space-between' spacing={1}>
      <Grid item>
        <div style={{ height: '60vh', width: '100%' }}>
          <DataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-cell': {
                py: '8px',
              },
            }}
            loading={!prestadores.length}
            rows={prestadores.map((a) => mapPrestadorToRow(a))}
            columns={columns}
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}
            columnVisibilityModel={{
              desplegable: screenWidth === 'xs',
              matricula: screenWidth !== 'xs',
              descripcionTipo: screenWidth !== 'xs',
              nombre: screenWidth !== 'xs',
              domicilio: screenWidth !== 'xs',
              localidad: screenWidth !== 'xs',
            }}
            pagination
            autoPageSize
            disableColumnMenu
            onRowClick={(selected) => {
              setRowSelectedDataGrid(selected.row);
            }}
            onRowDoubleClick={handleOnRowDoubleClick}
          />
        </div>
      </Grid>
      {visibleInfo ? (
        <Grid item>
          <Alert severity='info'>
            Sólo se muestran los primeros 50 resultados, agregue más criterios de búsqueda.
          </Alert>
        </Grid>
      ) : null}
      <Grid item>
        <Grid container justifyContent='flex-end' spacing={1}>
          <Grid item>
            {' '}
            <Button
              variant='contained'
              color='neutral'
              startIcon={<SearchIcon />}
              onClick={() => clean()}
            >
              Buscar otro
            </Button>
          </Grid>
          <Grid item>
            {' '}
            <Button
              variant='contained'
              color='primary'
              disabled={!rowSelectedDataGrid}
              startIcon={<CheckCircleIcon />}
              onClick={(e) => selectHandler(e)}
            >
              Seleccionar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const formFind = (
    <form>
      <FormControl fullWidth={true} variant='outlined'>
        <FormLabel component='legend'>
          Ingresá nombre y/o matrícula para realizar tu búsqueda
        </FormLabel>
      </FormControl>

      <FormControl fullWidth={true} variant='outlined'>
        <TextField
          variant='standard'
          type='number'
          {...register('matricula', {
            maxLength: {
              value: 5,
              message: 'El campo matrícula es demasiado largo',
            },
            pattern: {
              value: /^[0-9]+$/,
              message: 'El campo nmatrícula debe contener sólo números',
            },
          })}
          fullWidth
          margin='normal'
          name='matricula'
          label='Matrícula'
        />
        {errors['matricula'] && <p style={{ color: 'red' }}>{errors['matricula'].message}</p>}
      </FormControl>
      <FormControl fullWidth={true} variant='outlined'>
        <TextField
          variant='standard'
          type='text'
          fullWidth
          margin='normal'
          name='nombre'
          label='Nombre del profesional o establecimiento'
          {...register('nombre', {
            maxLength: {
              value: 200,
              message: 'El campo nombre es demasiado largo',
            },
            pattern: {
              value: /^(([a-zñA-ZÑ. ]{1,60}))$/,
              message: 'El campo nombre debe contener solo letras',
            },
          })}
        />
        {errors['nombre'] && <p style={{ color: 'red' }}>{errors['nombre'].message}</p>}
      </FormControl>
      <Grid container justifyContent='flex-end'>
        <Grid item>
          <LoadingButton
            fullWidth={true}
            type='submit'
            variant='contained'
            color='primary'
            loading={loading ? 'show' : 'hide'}
            content={'Buscar'}
            startIcon={<SearchIcon />}
            onClick={handleSubmit(find)}
          />
        </Grid>
      </Grid>
    </form>
  );

  const iconAdornment = !disabledSearchPrestador ? (
    <IconButton
      variant='outlined'
      aria-label='Buscar Prestador'
      onClick={handleOnClickIconSearch}
      size='large'
      edge='end'
    >
      <SearchIcon />
    </IconButton>
  ) : (
    <IconButton
      variant='outlined'
      aria-label='Buscar Prestador'
      onClick={handleIconClose}
      size='large'
      edge='end'
    >
      <CloseSharpIcon />
    </IconButton>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl sx={{ width: '100%' }} variant='standard'>
        <InputLabel htmlFor='outlined-adornment-prestador'>{textFieldPrestador}</InputLabel>
        <Input
          id='outlined-adornment-prestador'
          name='prestador'
          label='Buscá el prestador'
          aria-describedby='outlined-prestador-helper-text'
          value={prestador}
          onClick={handleClickPrestador}
          onBlur={handleClickPrestador}
          disabled
          endAdornment={<InputAdornment position='end'>{iconAdornment}</InputAdornment>}
        />
      </FormControl>
      {estadoPrestador.inactivo ? (
        <FormHelperText id='outlined-weight-helper-text' error={estadoPrestador.inactivo}>
          {'Prestador dado de baja'}
        </FormHelperText>
      ) : null}

      <ModalBase
        fullWidth={true}
        open={open}
        content={visibleFind ? formFind : dataGridPrestadores}
        title='Buscar Prestador'
        onClose={handleClose}
      />
      <CustomizedSnackbars
        open={alertResultFind}
        severity='info'
        message={alertResultFind}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Box>
  );
});

export default PrestadorSelector;
