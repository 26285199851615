import { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from '@mui/material';
import { findAfiliado } from '../../services/Busquedas/Afiliados';
import { useParams } from 'react-router-dom';
import ButtonBack from '../Breadcrumb/ButtonBack';
import { dateBirthFormatter } from '../../@iosper/utils/Format';
import DomicilioForm from './Domicilio/DomicilioForm';
import DrawerDataField from '../Common/DrawerDataField';
import ContactoForm from './Contacto/ContactoForm';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { Domicilio } from './Domicilio/Domicilio';
import { Contacto } from './Contacto/Contacto';

const VerAfiliado = () => {
  let { idAfiliado } = useParams();
  const [infoAfiliado, setInfoAfiliado] = useState(null);
  const [fondoVoluntario, setFondoVoluntario] = useState('');
  const [restriccionServicio, setRestriccionServicio] = useState('');
  const [quiebra, setQuiebra] = useState('');

  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    async function getInfo(idAfiliado) {
      const response = await findAfiliado(idAfiliado);
      setInfoAfiliado(response);

      if (response?.restriccionServicios !== null) {
        let restriccion = response?.restriccionServicios?.descripcion;
        restriccion =
          restriccion +
          ' - ' +
          response.restriccionServicios.codigo +
          '/' +
          response.restriccionServicios.servIfx;
        setRestriccionServicio(restriccion);
      } else {
        setRestriccionServicio('Ninguna - (0)');
      }

      if (response?.inhibicion !== null) {
        setQuiebra('El titular presenta ' + response?.inhibicion?.descripcionTipoInhibicion);
      } else {
        setQuiebra('No registra');
      }

      if (response?.afiliado?.tieneFondoVoluntario === true) {
        if (response?.afiliado?.carenciaFondoVoluntario === true) {
          setFondoVoluntario(
            'En carencia desde ' +
              response.afiliado.fechaAltaFondoVoluntario +
              ' (' +
              response.afiliado.fechaAltaFondoVoluntario +
              '/120 días)',
          );
        } else {
          setFondoVoluntario('Adherido desde ' + response.afiliado.fechaAltaFondoVoluntario);
        }
      } else {
        setFondoVoluntario('No adherido');
      }
    }

    getInfo(idAfiliado);

    return () => {
      setInfoAfiliado(null);
    };
  }, [idAfiliado]);

  const handleBorrarMensaje = () => {
    setError('');
    setSuccess('');
    setWarning('');
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} mt={3} lg={9} sx={{ mt: 0 }}>
        <ButtonBack marginBotton='1' variant='text' size='small'></ButtonBack>
        <Card>
          <CardHeader
            sx={{ paddingTop: 1, paddingBottom: 0 }}
            title={<span>Información afiliatoria</span>}
          />
          <CardContent
            sx={{
              paddingBottom: -5,
              marginBottom: -4,
              paddingTop: -5,
              marginTop: -2,
              marginLeft: 1,
            }}
          >
            <List>
              <DrawerDataField
                label='N° afiliado'
                data={`${infoAfiliado?.afiliado?.numeroAfiliadoFormateado || ''}`}
              />
              <Divider />
              <DrawerDataField
                label='Nombre Completo'
                data={`${infoAfiliado?.afiliado?.apellidoNombreFormateado || ''}`}
              />
              <Divider />
              <DrawerDataField
                label='Documento'
                data={`${infoAfiliado?.afiliado?.numeroDocumentoFormateado || ''} (${
                  infoAfiliado?.personaFisica?.descripcionTipoDocumento || ''
                })`}
              />
              <Divider />
              <DrawerDataField
                label='Fecha de Nacimiento'
                data={dateBirthFormatter(
                  infoAfiliado?.personaFisica?.fechaNacimiento,
                  'dd/mm/yyyy',
                )}
              />
              <Divider />
              <DrawerDataField label='Género' data={infoAfiliado?.personaFisica?.descripcionSexo} />
              <Divider />
              <DrawerDataField
                label='Fecha de Afiliación'
                data={`${infoAfiliado?.afiliacionAfiliado?.fechaAlta || ''}`}
              />
              <Divider />
              <DrawerDataField label='Fondo Voluntario' data={`${fondoVoluntario}`} />
              <Divider />

              <DrawerDataField label='Restriccion de Servicio' data={`${restriccionServicio}`} />
              <Divider />
              <DrawerDataField label='Quiebra' data={`${quiebra}`} />
            </List>
          </CardContent>
        </Card>
      </Grid>

      {infoAfiliado?.domicilioPersonaFisica && <Domicilio infoAfiliado={infoAfiliado} />}
      {infoAfiliado?.contactoPersonaFisica && <Contacto infoAfiliado={infoAfiliado} />}

      <CustomizedSnackbars
        open={success}
        severity='success'
        message={success}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={warning}
        severity='warning'
        message={warning}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default VerAfiliado;
