import React from 'react';
import BuscarFarmacias from '../Farmacias/BuscarFarmacias';

const Farmacias = () => {
  return (
    <>
      <BuscarFarmacias />
    </>
  );
};

export default Farmacias;
