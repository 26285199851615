import Grid from '@mui/material/Grid';
import ordenImg from '../../assets/images/menu/orden.svg';
import ordenBusquedaImg from '../../assets/images/menu/orden-busqueda.svg';
import reimprimirImg from '../../assets/images/menu/reimprimir.svg';
import OrdenDigitalImg from '../../assets/images/svg/mobile-qrScan.svg';
import cupoImg from '../../assets/images/menu/cupo.svg';
import { useTheme } from '@emotion/react';
import CardButton from '../../@iosper/components/Card/CardButton';
import { useMediaQuery } from '@mui/material';
import Title from '../../@iosper/components/Page/Title';
import { isMobile } from '../../@iosper/utils/DeviceDetector';

const Orden = () => {
  // Para ver si entró desde mobile o web, si es mobile no muestra la opción de Orden Electrónica
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const showOrdenDigital = isMobile() || !matches;

  return (
    <>
      <Title>Órdenes</Title>

      <Grid container spacing={2}>
        {showOrdenDigital && (
          <Grid item xs={12} sm={6} lg={4}>
            <CardButton
              title={'Orden Electrónica'}
              description={'Escaneá el código Qr para generar tu Orden Electrónica.'}
              to={'/read-orden'}
              image={OrdenDigitalImg}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} lg={4}>
          <CardButton
            title={'Orden Impresa'}
            description={'Generá ordenes de consulta.'}
            to={'/emitir-orden'}
            image={ordenImg}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <CardButton
            title={'Reimpresión'}
            description={'Reimprimí la última Orden Impresa.'}
            to={'/reimprimir-orden'}
            image={reimprimirImg}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <CardButton
            title={'Consultar cupo'}
            description={'Consultá cupo de consultas y prácticas.'}
            to={'/consultar-cupo'}
            image={cupoImg}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <CardButton
            title={'Consultar ordenes'}
            description={'Consultá las ordenes emitidas.'}
            to={'/consultar-ordenes'}
            image={ordenBusquedaImg}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Orden;
