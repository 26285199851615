import { forwardRef, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { findByLocalidad } from '../../services/Busquedas/Calles';

const useCalle = ({ localidadId }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const getCallesByLocalidad = async (id) => {
    setLoading(true);
    const calles = await findByLocalidad(id);
    setOptions(calles);
    setLoading(false);
  };

  useEffect(() => {
    setOptions([]);
    if (localidadId) {
      getCallesByLocalidad(localidadId);
    }
  }, [localidadId]);

  return {
    options,
    loading,
  };
};

const CalleAutocomplete = forwardRef(({ value, localidadId, onChangeOption, error }, ref) => {
  const { loading, options } = useCalle({ localidadId });

  const handleChange = (newValue) => {
    if (onChangeOption) {
      onChangeOption(newValue);
    }
  };

  return (
    <Autocomplete
      id='calle-autocomplete'
      value={value || null}
      isOptionEqualToValue={(option, value) => option?.idCalle === value?.idCalle}
      getOptionLabel={(option) => `${option.descripcion}`}
      onChange={(_, newValue) => handleChange(newValue)}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Calle'
          variant='standard'
          error={!!error}
          helperText={error ? error.message : ''}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color='inherit' size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
});

export default CalleAutocomplete;
