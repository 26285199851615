import { axiosGet, axiosPost } from '../ServiceBase.js';

export const constantes = {
  TIPO_TELEFONO: '1',
  TIPO_EMAIL: '2',
  TIPO_CELULAR: '5',
};

export const tipoContacto = {
  [constantes.TIPO_TELEFONO]: { code: constantes.TIPO_TELEFONO, name: 'telefono' },
  [constantes.TIPO_EMAIL]: { code: constantes.TIPO_EMAIL, name: 'email' },
  [constantes.TIPO_CELULAR]: { code: constantes.TIPO_CELULAR, name: 'celular' },
};

export const getContactoConfirmado = async () => {
  return axiosGet('/v1/contacto/validar-correo-confirmado');
};

export const getContactos = async () => {
  return axiosGet('/v1/contacto/correo');
};

export const generarCodigoConfirmacion = async (idHistorialContactoPersonaFisica) => {
  return axiosPost('/v1/codigo-confirmacion/generar', { id: idHistorialContactoPersonaFisica });
};

export const confirmarContacto = async (idHistorialContactoPersonaFisica, codigoConfirmacion) => {
  return axiosPost('/v1/codigo-confirmacion/confirmar', {
    id: idHistorialContactoPersonaFisica,
    code: codigoConfirmacion,
  });
};

export const agregarCorreo = async (correo) => {
  return axiosPost('/v1/contacto/agregar-correo', { contacto: correo });
};

export const guardarContactosdAfiliado = async (idAfiliado, contactos) => {
  return axiosPost('/v1/contacto/guardar-contactos', {
    idAfiliado: idAfiliado,
    contactos: contactos,
  });
};

export const getContactosByUser = async (username) => {
  return axiosPost('/v1/contacto/miiosper/correo-usuario', { nombreUsuario: username });
};
