import { forwardRef, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getProvincias } from '../../services/Busquedas/Provincias';

const useProvincia = () => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchGetProvincias = async () => {
    setLoading(true);
    const provincias = await getProvincias();
    setOptions(provincias);
    setLoading(false);
  };

  useEffect(() => {
    setOptions([]);
    fetchGetProvincias();
  }, []);

  return {
    options,
    loading,
  };
};

const ProvinciaAutocomplete = forwardRef(({ value, onChangeOption, error }, ref) => {
  const { loading, options } = useProvincia();

  const handleChange = (newValue) => {
    if (onChangeOption) {
      onChangeOption(newValue);
    }
  };

  return (
    <Autocomplete
      value={value || null}
      isOptionEqualToValue={(option, val) => option.idProvincia === val.idProvincia}
      getOptionLabel={(option) => `${option.descripcion}`}
      loading={loading}
      options={options}
      onChange={(_, newValue) => handleChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Provincia'
          variant='standard'
          error={!!error}
          helperText={error ? error.message : ''}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color='inherit' size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
});

export default ProvinciaAutocomplete;
