import HighlightOff from '@mui/icons-material/HighlightOff';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import QrPreview from '../Common/QrPreview';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const TransitoriaPreview = (props) => {
  const { handlerCerrarPreview } = props;

  const handleClose = () => {
    props.setOpen(false);
    handlerCerrarPreview();
  };

  return (
    props.open && (
      <div>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              <Grid
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='felx-end'
                spacing={1}
              >
                <Grid item>
                  {' '}
                  <Typography variant='h6'>
                    Derivación Transitoria Generada{' '}
                    <CheckCircleIcon fontSize='small' color='primary'></CheckCircleIcon>
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
            <Grid item xs={12}>
              {props.datosDerivacion && (
                <QrPreview
                  showDownload={props.showDownload}
                  showPrint={props.showPrint}
                  urlReporte={props.urlReporte}
                  nombreReporte={'Carta_derivacion_' + props.datosDerivacion.provinciaDescripcion}
                  qrValue={JSON.stringify({
                    ids: props.datosDerivacion.afiliados.map(
                      (d) => d.idHistorialDerivacionAfiliado,
                    ),
                  })}
                />
              )}
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                {props.datosDerivacion && (
                  <FormControl fullWidth={true} variant='standard' margin='normal'>
                    <TextField
                      id='provincia'
                      variant='standard'
                      label='Provincia'
                      defaultValue={props.datosDerivacion.provinciaDescripcion}
                      size='small'
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        style: { fontWeight: 'bold' },
                      }}
                    />
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                {props.datosDerivacion && (
                  <FormControl fullWidth={true} variant='standard' margin='normal'>
                    <TextField
                      id='fdesde'
                      variant='standard'
                      label='Fecha Desde'
                      defaultValue={props.datosDerivacion.fechaDesde}
                      size='small'
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        style: { fontWeight: 'bold' },
                      }}
                    />
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                {props.datosDerivacion && (
                  <FormControl fullWidth={true} variant='standard' margin='normal'>
                    <TextField
                      id='fhasta'
                      variant='standard'
                      label='Fecha Hasta'
                      defaultValue={props.datosDerivacion.fechaHasta}
                      size='small'
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        style: { fontWeight: 'bold' },
                      }}
                    />
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                {props.datosDerivacion && (
                  <FormControl fullWidth={true} variant='standard' margin='normal'>
                    <List>
                      <Typography variant='caption' color='primary'>
                        Afiliados
                      </Typography>
                      {props.datosDerivacion.afiliados?.map((d) => {
                        return (
                          <ListItem key={d.idAfiliado}>
                            <TextField
                              id='idafiliado'
                              variant='standard'
                              defaultValue={
                                d.nombre +
                                ', ' +
                                d.apellido +
                                ' (' +
                                d.relacionTitularAdherente +
                                ') '
                              }
                              size='small'
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                style: { fontWeight: 'bold' },
                              }}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
            <Button
              size='small'
              startIcon={<HighlightOff />}
              variant='contained'
              color='primary'
              onClick={(e) => handleClose(e)}
            >
              Cerrar
            </Button>
          </CardActions>
        </Card>
      </div>
    )
  );
};

export default TransitoriaPreview;
