// *https://www.registers.service.gov.uk/registers/country/use-the-api*

import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { useAfiliados } from './AfiliadosProvider';
import { Autocomplete, CircularProgress } from '@mui/material';

const AfiliadoAutocomplete = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const { afiliados } = useAfiliados();
  const [afiliado, setAfiliado] = React.useState(null);
  const [afiliadoId, setAfiliadoId] = React.useState(0);

  useEffect(() => {
    if (props.onDefaultAfiliado) {
      setAfiliadoId(props.onDefaultAfiliado);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onDefaultAfiliado]);

  useEffect(() => {
    if (afiliadoId !== 0) {
      onSelectAfiliadoById(afiliadoId);
    }
  }, [afiliadoId]);

  const handleChange = (event, newValue) => {
    setAfiliado(newValue);
    props.onSelectAfiliado(newValue);
  };

  const onSelectAfiliadoById = (idAfiliado) => {
    if (idAfiliado !== 0) {
      let afiliadoSelected = afiliados.find((a) => a.afiliado.idAfiliado === Number(idAfiliado));
      if (afiliadoSelected) {
        setAfiliado(afiliadoSelected);
      }
      props.onSelectAfiliado(afiliadoSelected);
    }
  };

  return (
    <Autocomplete
      id='afiliado-autocomplete'
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) =>
        option.afiliado?.idAfiliado === value.afiliado?.idAfiliado
      }
      getOptionLabel={(a) =>
        `(${a.afiliado?.numeroDocumentoFormateado}) ${a.personaFisica?.apellido}, ${a.personaFisica?.nombre}`
      }
      onChange={handleChange}
      options={afiliados}
      value={afiliado}
      loading={afiliados && afiliados.length === 0}
      renderInput={(params) => (
        <TextField
          {...params}
          label={afiliado ? 'Afiliado' : 'Seleccioná el afiliado'}
          variant='standard'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {afiliados && afiliados.length === 0 ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
});

export default AfiliadoAutocomplete;
