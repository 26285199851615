import { TextField } from '@mui/material';

const CuilTextInput = ({
  fieldName,
  label,
  register,
  errors,
  required,
  variant,
  maxLength,
  minLength,
  change,
}) => {
  const limitCaracter = (e) => {
    if (!Number.isNaN(parseInt(e.target.value))) {
      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, maxLength);
    } else {
      e.target.value = null;
    }
  };

  return (
    <div>
      <TextField
        variant={variant}
        fullWidth
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        onInput={limitCaracter}
        name={fieldName}
        label={label}
        id={fieldName}
        {...register(fieldName, {
          required: {
            value: required,
            message: 'Debe ingresar su cuil',
          },
          maxLength: {
            value: maxLength,
            message: label + ' debe tener ' + maxLength + ' dígitos.',
          },
          minLength: {
            value: minLength,
            message: label + ' debe tener ' + minLength + ' dígitos.',
          },
          pattern: {
            value: /^(([0-9]{2,}))$/,
            message: 'El formato no es correcto.',
          },
          onChange: (e) => change(e.target.value),
        })}
      />
      {errors[fieldName] && <p style={{ color: 'red' }}>{errors[fieldName].message}</p>}
    </div>
  );
};
CuilTextInput.defaultProps = {
  maxLength: 11,
  minLength: 11,
  variant: 'standard',
};

export default CuilTextInput;
