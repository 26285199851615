import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
//import AfiliadoCard from '../Afiliados/AfiliadoCard';
import AfiliadoSelect from '../Afiliados/AfiliadoSelect';
import { Grid, Button, Stack } from '@mui/material';
import PracticaAutocomplete from '../Practicas/PracticaAutocomplete';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Assignment from '@mui/icons-material/Assignment';
import HighlightOff from '@mui/icons-material/HighlightOff';
import AttachMoney from '@mui/icons-material/AttachMoney';
import { useForm } from 'react-hook-form';
import { getValidarOrden, getVerificarCostoOrden } from '../../services/Seo/Orden';
import LoadingButton from '../../@iosper/components/LoadingButton';
import VerificarCostoOrdenResumidaView from './VerificarCostoOrdenResumidaView';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { useUser } from '../Auth/User';
import { useParams } from 'react-router-dom';
import ButtonBack from '../Breadcrumb/ButtonBack';
import OrdenValidadaResumidaForm from './OrdenValidadaResumidaForm';
import OrdenPreview from './OrdenPreview';
import PrestadorSelector from '../Prestador/PrestadorSelector';
import { UltimosPrestadoresOrden } from '../Prestador/UltimosPrestadoresOrden';

const EmitirOrden = () => {
  let { idAfiliado } = useParams();
  const [success, setSuccess] = useState('');
  const [selectedAfiliado, setSelectedAfiliado] = useState(null);
  const [selectedPrestador, setSelectedPrestador] = useState(null);
  const [selectedPractica, setSelectedPractica] = useState(null);
  const [keyComponentAfiliado, setKeyComponentAfiliado] = useState(0);
  // Dialog Orden
  const [open, setOpen] = useState(false);
  const [datosOrdenReceived, setDatosOrdenReceived] = useState({});
  const [loadingOrden, setLoadingOrden] = useState(false);
  // Dialog Verificar Costo Orden
  const [openVerificarCostoOrden, setOpenVerificarCostoOrden] = useState(false);
  const [datosVerificarCostoOrdenReceived, setDatosVerificarCostoOrdenReceived] = useState({});
  const [loadingVerificarCostoOrden, setLoadingVerificarCostoOrden] = useState(false);

  // Dialog Orden Preview
  const [openDialogReporte, setOpenDialogReporte] = useState(false);
  const [urlReporte, setUrlReporte] = useState('');
  const [datosOrdenGenerada, setDatosOrdenGenerada] = useState('');

  const onSelectAfiliado = (afiliado) => {
    setSelectedAfiliado(afiliado);
  };

  const handlerSelectPrestador = (prestador) => {
    setSelectedPrestador(prestador);
  };

  const handlerUltimoPrestador = (prestador) => {
    handlerSelectPrestador(prestador);
  };

  const handlerSelectPractica = (practica) => {
    setSelectedPractica(practica);
  };

  const handlerOrdenConfirmada = (datosOrden, urlPdf) => {
    setSuccess('Orden generada correctamente');
    setUrlReporte(urlPdf);
    setDatosOrdenGenerada(datosOrden);
    setOpenDialogReporte(true);
  };

  const validateAfiliado = () => {
    return selectedAfiliado == null ? false : true;
  };

  const validatePrestador = () => {
    // Si no esta activo seguimos mostrando que debe seleccionar un prestador
    return selectedPrestador !== null && selectedPrestador.codigoEstado === '1' ? true : false;
  };

  const validatePractica = () => {
    return selectedPractica == null ? false : true;
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const { user } = useUser();

  const handlerLimpiar = () => {
    setKeyComponentAfiliado(1 + Math.random() * (1000 - 1));
    reset();
    setSelectedAfiliado(null);
    setSelectedPrestador(null);
    setSelectedPractica(null);
  };

  const handlerCerrarPreview = () => {
    handlerLimpiar();
  };

  const handlerVerificarCosto = async () => {
    setLoadingVerificarCostoOrden(true);
    try {
      const res = await getVerificarCostoOrden(getDataOrden());
      setDatosVerificarCostoOrdenReceived(res);
      setOpenVerificarCostoOrden(!openVerificarCostoOrden);
      setLoadingVerificarCostoOrden(false);
    } catch (e) {
      setLoadingVerificarCostoOrden(false);
    }
  };

  const onSubmit = async (data, event) => {
    setLoadingOrden(true);
    event.preventDefault();

    try {
      const res = await getValidarOrden(getDataOrden());
      setDatosOrdenReceived(res);
      setOpen(!open);
      setLoadingOrden(false);
    } catch (e) {
      setLoadingOrden(false);
    }
  };

  const getDataOrden = () => {
    let data = {
      numeroTarjeta: selectedAfiliado.tarjeta.numeroTarjeta,
      codigoPrestador: selectedPrestador.matricula,
      tipoPrestador: selectedPrestador.tipo,
      codigoPrescriptor: 0,
      practicas: [
        {
          codigo: selectedPractica.codigo,
          cantidad: 1,
          validarEspecialidad: selectedPractica.validarEspecialidad,
        },
      ],
    };
    return data;
  };

  const handleBorrarMensaje = () => {
    setSuccess('');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ButtonBack marginBotton='1' variant='text' size='small'></ButtonBack>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Generar Orden
            </Typography>
            <Typography variant='string' component={'div'}>
              Acá podrás emitir órdenes de consulta, para luego descargarlas, imprimirlas y/o
              enviarlas por correo electrónico.
            </Typography>
          </CardContent>

          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <AfiliadoSelect
                  key={keyComponentAfiliado}
                  ref={register('afiliadoRegister', {
                    validate: validateAfiliado,
                  })}
                  onSelectAfiliado={onSelectAfiliado}
                  onDefaultAfiliado={idAfiliado}
                />
                {errors.afiliadoRegister && (
                  <p style={{ color: 'red' }}>Debe seleccionar el afiliado</p>
                )}
              </FormControl>

              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item xs={11}>
                    <PrestadorSelector
                      variant='standard'
                      key={keyComponentAfiliado + '_prestador'}
                      value={selectedPrestador}
                      ref={register('prestadorRegister', {
                        validate: validatePrestador,
                      })}
                      onSelectedPrestador={handlerSelectPrestador}
                    ></PrestadorSelector>
                  </Grid>
                  <Grid item xs={1}>
                    <UltimosPrestadoresOrden
                      idAfiliado={
                        selectedAfiliado?.afiliado.idAfiliado
                          ? selectedAfiliado?.afiliado.idAfiliado
                          : idAfiliado
                      }
                      onSelectEvent={handlerUltimoPrestador}
                    />
                  </Grid>
                </Grid>
                {errors.prestadorRegister && errors.prestadorRegister.type === 'validate' && (
                  <p style={{ color: 'red' }}>Debe seleccionar el prestador</p>
                )}
              </FormControl>
              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <PracticaAutocomplete
                  key={keyComponentAfiliado + '_practica'}
                  size='small'
                  ref={register('practicaRegister', {
                    validate: validatePractica,
                  })}
                  onSelectPractica={handlerSelectPractica}
                />
                {errors.practicaRegister != null && (
                  <p style={{ color: 'red' }}>Debe seleccionar la práctica</p>
                )}
              </FormControl>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2 }}
                justifyContent={{ xs: 'center', sm: 'flex-end' }}
                alignItems={{ xs: 'strech' }}
              >
                <Button
                  variant='contained'
                  color='neutral'
                  startIcon={<HighlightOff />}
                  onClick={handlerLimpiar}
                >
                  Limpiar
                </Button>
                <LoadingButton
                  variant='contained'
                  color='neutral'
                  startIcon={<AttachMoney />}
                  loading={loadingVerificarCostoOrden ? 'show' : 'hide'}
                  content={'Verificar Costo'}
                  onClick={handleSubmit(handlerVerificarCosto)}
                />
                <LoadingButton
                  fullWidth={true}
                  variant='contained'
                  size='medium'
                  color='primary'
                  startIcon={<Assignment />}
                  type='submit'
                  loading={loadingOrden ? 'show' : 'hide'}
                  content={'Generar'}
                />
              </Stack>
            </CardActions>
          </form>
        </Card>

        <OrdenValidadaResumidaForm
          open={open}
          setOpen={setOpen}
          datosOrden={datosOrdenReceived}
          handlerOrdenConfirmada={handlerOrdenConfirmada}
        ></OrdenValidadaResumidaForm>

        <VerificarCostoOrdenResumidaView
          open={openVerificarCostoOrden}
          setOpen={setOpenVerificarCostoOrden}
          datosOrden={datosVerificarCostoOrdenReceived}
        ></VerificarCostoOrdenResumidaView>

        <OrdenPreview
          open={openDialogReporte}
          setOpen={setOpenDialogReporte}
          urlReporte={urlReporte}
          datosOrden={datosOrdenGenerada}
          showMail={true}
          nombreReporte={'Orden_iosper_' + datosOrdenGenerada.numeroOrden}
          handlerCerrarPreview={handlerCerrarPreview}
        ></OrdenPreview>

        <CustomizedSnackbars
          open={success}
          autoHideDuration={10000}
          onDeleteMessage={handleBorrarMensaje}
          severity='success'
          message={success}
        />
      </Grid>
    </Grid>
  );
};

export default EmitirOrden;
