import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useFileCompression } from './hooks/useFileCompression';
import { LoadingButton } from '@mui/lab';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FileUpload = ({
  accept = null,
  children,
  component = null,
  compressFile = false,
  file = null,
  onChangeUpload = null,
  ...props
}) => {
  const { outputFile, onHandleFileUpload, isLoading } = useFileCompression({
    compressFile: compressFile,
  }); // Hook con lógica de compresión de archivos

  const ComponentUpload = ({ children }) => {
    switch (component) {
      case 'Button' || 'IconButton': {
        const Tag = component;
        return (
          <Tag {...props} component='label'>
            {children}
          </Tag>
        );
      }
      default:
        return (
          <LoadingButton {...props} loading={isLoading} loadingPosition='center' component='label'>
            {children}
          </LoadingButton>
        );
    }
  };

  useEffect(() => {
    onHandleReturnOutputFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputFile]);

  /**
   * Devuelve el archivo procesado a traves de un objeto
   * Event.target.files[]
   */
  const onHandleReturnOutputFile = () => {
    if (outputFile) {
      const evento = new Event('change');
      Object.defineProperty(evento, 'target', {
        writable: false,
        value: {
          files: [outputFile],
        },
      });

      onChangeUpload(evento);
    }
  };

  return (
    <>
      <ComponentUpload>
        {children}
        <VisuallyHiddenInput accept={accept} capture type='file' onChange={onHandleFileUpload} />
      </ComponentUpload>
    </>
  );
};

export default FileUpload;
