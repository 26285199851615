import {
  LocationCity,
  PersonSearch,
  Assignment,
  ExitToApp,
  Group,
  Home,
  Logout,
  Paid,
  Password,
  Person,
  HelpOutline,
  AttachMoney,
  CardTravel,
  Repeat,
  Help,
  FactCheck,
} from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

export const SideMenuList = [
  {
    to: '/',
    key: 'Inicio',
    icon: () => <Home />,
    label: 'Inicio',
  },
  {
    to: '/orden',
    key: 'Ordenes',
    icon: () => <Assignment />,
    label: 'Ordenes',
  },
  {
    to: '/autorizaciones',
    key: 'Solicitudes',
    icon: () => <FactCheck />,
    label: 'Solicitudes de Autorización',
  },
  {
    to: '/receta',
    key: 'Recetas Electrónicas',
    icon: () => <ManageSearchIcon />,
    label: 'Recetas Electrónicas',
  },
  {
    to: '/grupo-familiar',
    key: 'Grupo Familiar',
    icon: () => <Group />,
    label: 'Grupo Familiar',
  },
  {
    to: '/prestadores',
    key: 'Prestadores',
    icon: () => <PersonSearch />,
    label: 'Prestadores',
  },
  {
    to: '/reintegros',
    key: 'Reintegros',
    icon: () => <Repeat />,
    label: 'Reintegros',
  },
  {
    to: '/aportes',
    key: 'Aportes',
    icon: () => <AttachMoney />,
    label: 'Aportes',
  },
  {
    to: '/delegaciones',
    key: 'Delegaciones',
    icon: () => <LocationCity />,
    label: 'Delegaciones',
  },
  {
    to: '/consumo-prestacional',
    key: 'Consumo Prestacional',
    icon: () => <ListIcon />,
    label: 'Consumo Prestacional',
  },
];

export const SideMenuListCapitado = [
  {
    to: '/cupon-pago-capita',
    key: 'Cápita',
    icon: () => <Paid />,
    label: 'Cápita',
  },
];

export const SideMenuListNoCapitado = [
  {
    to: '/derivaciones',
    key: 'Derivaciones',
    icon: () => <CardTravel />,
    label: 'Derivaciones',
  },
];

export const BottomMenuListAyuda = [
  {
    to: '/ayuda',
    key: 'Ayuda',
    icon: () => <Help />,
    label: 'Ayuda',
  },
];

export const BottomMenuList = [
  {
    to: '/signout',
    key: 'Salir',
    icon: () => <ExitToApp />,
    label: 'Salir',
  },
];

export const TopMenuList = [
  {
    to: '/perfil',
    key: 'Perfil',
    icon: () => <Person fontSize='small' />,
    label: 'Perfil',
  },
  {
    to: '/cambiar-pin',
    key: 'Cambiar mi Contraseña',
    icon: () => <Password fontSize='small' />,
    label: 'Cambiar mi Contraseña',
  },
  {
    to: '/terminos-condiciones',
    key: 'Términos y condiciones',
    icon: () => <HelpOutline fontSize='small' />,
    label: 'Términos y condiciones',
  },
  {
    to: '/signout',
    key: 'Salir',
    icon: () => <Logout fontSize='small' />,
    label: 'Salir',
  },
];
