import { useState, useContext, useEffect, useMemo, createContext } from 'react';
import { useUser } from '../Auth/User';
import { getGrupoFamiliar } from '../../services/Busquedas/Afiliados';

export const AfiliadosContext = createContext(null);

export const AfiliadosProvider = (props) => {
  const [afiliados, setAfiliados] = useState([]);
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      const getGrupo = async () => {
        const res = await getGrupoFamiliar();
        setAfiliados(res);
        sessionStorage.setItem('grupoAfiliado', JSON.stringify(res));
      };

      try {
        const grupoAfiliado = sessionStorage.getItem('grupoAfiliado');
        if (!grupoAfiliado) {
          getGrupo();
        } else {
          setAfiliados(JSON.parse(grupoAfiliado));
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [user]);

  const values = useMemo(() => ({ afiliados }), [afiliados]);

  return <AfiliadosContext.Provider value={values}>{props.children}</AfiliadosContext.Provider>;
};

export const useAfiliados = () => {
  const context = useContext(AfiliadosContext);
  if (context === undefined) {
    throw new Error('`useAfiliados` debe estar dentro de `AfiliadosProvider`');
  }
  return context;
};
