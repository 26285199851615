import { axiosPost, axiosPostFile } from '../ServiceBase.js';

/**
 * save derivaciones transitorias
 * @returns
 */
export const saveAutorizacion = async (autorizacion) => {
  const salida = await axiosPost('/v1/autorizaciones/save-autorizacion', autorizacion);
  return salida;
};

/**
 * save derivaciones transitorias
 * @returns
 */
export const saveArchivoAutorizacion = async (archivo) => {
  const salida = await axiosPost('/v1/autorizaciones/save-archivo-autorizacion', archivo);
  return salida;
};

/**
 * Imprimir el comprobante de la autorizacion
 * @returns
 */
export const getComprobanteAutorizacion = async (data) => {
  return axiosPostFile('/v1/autorizaciones/imprimir-comprobante-solicitud', data);
};

/**
 * Imprimir el comprobante de la autorizacion
 * @returns
 */
export const getProvidenciaAutorizacion = async (data) => {
  return axiosPostFile('/v1/autorizaciones/imprimir-providencia-solicitud', data);
};

/**
 *
 * @param {id, observacion} data
 * @returns
 */
export const anularAutorizacion = async (data) => {
  const recetas = await axiosPost('/v1/autorizaciones/anular', data);
  return recetas;
};

/**
 * Enviar por correo la enviarAutorizacion
 * @returns
 */
export const enviarAutorizacion = async (data) => {
  return axiosPostFile('/v1/autorizaciones/enviar', {
    numeroAutorizacion: data.idAutorizacion,
    correo: data.correo,
  });
};

/**
 * Enviar por correo la enviarAutorizacion
 * @returns
 */
export const enviarProvidencia = async (data) => {
  return axiosPostFile('/v1/autorizaciones/providencia', {
    numeroAutorizacion: data.idAutorizacion,
    correo: data.correo,
  });
};
