import { axiosGet } from '../ServiceBase.js';

export const getObrasSociales = async () => {
  try {
    const obrassociales = await axiosGet('/v1/busquedas/obras-sociales');
    return obrassociales;
  } catch (err) {
    return Promise.reject(err);
  }
};
