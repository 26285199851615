import { Code as CodeIcon } from '@mui/icons-material';
import { Chip, Tooltip } from '@mui/material';
import React from 'react';

export const EtiquetaAmbiente = () => {
  return (
    <>
      {process.env.REACT_APP_STAGE === 'staging' && (
        <Tooltip title='Ambiente de prueba (server staging)' enterTouchDelay={0}>
          <>
            <Chip
              size='small'
              color='warning'
              icon={<CodeIcon />}
              label={'Ambiente de prueba'}
              sx={{
                display: { xs: 'none', sm: 'inline-flex' },
              }}
            />
            <Chip
              size='small'
              color='warning'
              label={'Test'}
              sx={{
                display: { xs: 'inline-flex', sm: 'none' },
              }}
            />
          </>
        </Tooltip>
      )}
      {process.env.REACT_APP_STAGE === 'development' && (
        <Tooltip title='Ambiente de prueba (server local)' enterTouchDelay={0}>
          <React.Fragment>
            <Chip
              size='small'
              color='info'
              icon={<CodeIcon />}
              label={'Ambiente de prueba'}
              sx={{
                display: { xs: 'none', sm: 'inline-flex' },
              }}
            />
            <Chip
              size='small'
              color='info'
              label={'Test'}
              sx={{
                display: { xs: 'inline-flex', sm: 'none' },
              }}
            />
          </React.Fragment>
        </Tooltip>
      )}
    </>
  );
};
