import React, { useState } from 'react';
import { TextField, Typography, Button, Grid, Stack } from '@mui/material';
import LoadingButton from '../../@iosper/components/LoadingButton';
import { agregarCorreo } from '../../services/Common/Contacto';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { useForm } from 'react-hook-form';
import ModalBase from '../Controls/ModalBase';

const AgregarContacto = React.forwardRef((props, ref) => {
  const { open, onContactoAgregado, onClose } = props;
  const [correo, setCorreo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState('');

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await agregarCorreo(correo);
      setResponse('Se ha agregado correctamente el contacto.');
      reset();
      setLoading(false);
      onContactoAgregado(res.contacto);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleBorrarMensaje = () => {
    setResponse('');
  };

  const form = (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction='column' justifyContent='left' alignItems='left'>
          <Grid item>
            <Typography variant='body1'>Ingresá un nuevo correo electrónico</Typography>
          </Grid>
          <Grid item>
            <TextField
              placeholder='nombre@dominio.com'
              variant='standard'
              {...register('email', {
                required: {
                  value: true,
                  message: 'Debe ingresar un correo',
                },
                pattern: {
                  value:
                    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'El formato no es correcto',
                },
              })}
              type='email'
              margin='normal'
              name='email'
              label='nombre@dominio.com'
              onChange={(e) => setCorreo(e.target.value)}
            />
            {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>}
          </Grid>
        </Grid>
        <br />
        <Grid item justifyContent='flex-end' xs={12}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            justifyContent={{ xs: 'center', sm: 'flex-end' }}
            alignItems='center'
          >
            <Button
              size='small'
              variant='contained'
              color='neutral'
              onClick={(e) => handleClose(e)}
            >
              Cancelar
            </Button>
            <LoadingButton
              fullWidth={true}
              variant='contained'
              color='primary'
              disabled={false}
              loading={loading ? 'show' : 'hide'}
              content={'Aceptar'}
              size='small'
              type='submit'
            />
          </Stack>
        </Grid>
      </form>
    </div>
  );

  return (
    <div>
      <ModalBase
        open={open}
        content={form}
        title='Agregar correo electrónico'
        onClose={handleClose}
      />
      <CustomizedSnackbars
        open={response}
        severity='success'
        message={response}
        onDeleteMessage={handleBorrarMensaje}
      />
    </div>
  );
});

export default AgregarContacto;
