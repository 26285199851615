import React, { useEffect, useState } from 'react';
import {
  TextField,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Stack,
} from '@mui/material';
import { generarCodigoConfirmacion, confirmarContacto } from '../../services/Common/Contacto';
import { useForm } from 'react-hook-form';
import LoadingButton from '../../@iosper/components/LoadingButton';
import CountDown from '../Common/CountDown';
import { Redirect, useParams } from 'react-router-dom';
import ButtonBack from '../Breadcrumb/ButtonBack';

const ConfirmarContactoView = React.forwardRef((props, ref) => {
  let { id, contacto } = useParams();
  const [contactoConfirmado, onContactoConfirmado] = useState(false);
  const [contentView, setContent] = useState(false);

  useEffect(() => {
    const generarCode = async (id) => {
      try {
        generarCodigoConfirmacion(id);
        setContent(true);
      } catch (e) {
        console.error(e);
      }
    };
    generarCode(id);
  }, [id, contacto]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const [caducado, setCaducado] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingReenviar, setLoadingReenviar] = useState(false);
  const [codigoIngresado, setCodigoIngresado] = useState(false);

  const onCaducado = () => {
    setCaducado(true);
  };

  const clean = async () => {
    setCaducado(false);
    reset();
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await confirmarContacto(id, codigoIngresado);
      setLoading(false);
      onContactoConfirmado(true);
    } catch (e) {
      setLoading(false);
    }
  };

  const reenviarCodigoHandler = async (e) => {
    e.preventDefault();
    setLoadingReenviar(true);

    try {
      await generarCodigoConfirmacion(id);
      setLoadingReenviar(false);
      clean();
    } catch (e) {
      console.error(e);
    }
  };

  return contentView ? (
    contactoConfirmado ? (
      <Redirect
        to={{
          pathname: '/perfil',
          state: { from: '/verificar-contacto' },
        }}
      />
    ) : (
      <Box>
        <Grid container spacing={1}>
          <ButtonBack />
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} direction='column' justifyContent='left' alignItems='left'>
            <Grid item xs={12} zeroMinWidth>
              <Typography align='left' variant='h6'>
                Ingresá el código de confirmación que enviamos a:
              </Typography>
              {contacto && (
                <List>
                  <ListItem key={`email-account-${id}`}>
                    <ListItemIcon>&bull;</ListItemIcon>
                    <ListItemText primary={contacto} />
                  </ListItem>
                </List>
              )}
            </Grid>
            <Grid item xs={12} zeroMinWidth>
              <Typography align='left' variant='body2'>
                ¿No recibiste ningún correo?
                <br />
                Acordate de revisar en el correo no deseado.
              </Typography>
            </Grid>
            <Grid item xs={12} zeroMinWidth>
              <TextField
                label='¿Cúal es el código?'
                variant='standard'
                fullWidth
                name='codigo'
                focused
                error={caducado}
                helperText={caducado && 'El código ha caducado'}
                {...register('codigo', {
                  required: {
                    value: true,
                    message: 'Debe ingresar un código',
                  },
                })}
                onChange={(e) => setCodigoIngresado(e.target.value)}
              />
              {errors.codigo && <p style={{ color: 'red' }}>{errors.codigo.message}</p>}
              <Grid item xs={12}>
                <Stack direction='row' spacing={1} justifyContent='left' alignItems='center'>
                  {!caducado && <Typography variant='caption'> El código caducará en</Typography>}
                  {!caducado && <CountDown id='count' minutes={5} onCaducado={onCaducado} />}
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2 }}
                justifyContent={{ xs: 'center', sm: 'flex-end' }}
                alignItems='center'
              >
                <LoadingButton
                  variant='text'
                  color='primary'
                  loading={loadingReenviar ? 'show' : 'hide'}
                  content={'Reenviar código'}
                  disabled={!caducado}
                  onClick={(e) => reenviarCodigoHandler(e)}
                />
                <LoadingButton
                  fullWidth={true}
                  variant='contained'
                  color='primary'
                  loading={loading ? 'show' : 'hide'}
                  content={'Confirmar'}
                  type='submit'
                  disabled={caducado}
                />
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>
    )
  ) : (
    <Box></Box>
  );
});

export default ConfirmarContactoView;
