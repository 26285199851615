import BuscarReintegros from '../Reintegros/BuscarReintegros';

const Reintegros = () => {
  return (
    <>
      <BuscarReintegros />
    </>
  );
};

export default Reintegros;
